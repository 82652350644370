import styled from 'styled-components';
import { Colors, TextBodyCopyLarge, Header4 } from '@easyknockorg/ek-ui-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-top: 40px;
  gap: 2px;
  width: 100%;
  border: 2px solid ${Colors.BGW.greyWhite};
  border-radius: 5px;
  color: ${Colors.BGW.darkGrey};
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px;
  gap: 8px;
  border-bottom: 2px solid ${Colors.BGW.greyWhite};
`;

export const ValuesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px;
`;

export const MainCategory = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
`;

export const Category = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 20px;
  gap: 8px;
`;

export const HeaderTitle = styled(Header4)`
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  color: ${Colors.BGW.darkGrey};
`;

export const Deductions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 33%;
`;
