import React from 'react';
import { Colors, Header5, IconButton } from '@easyknockorg/ek-ui-components';
import {
  Card,
  CardContainer,
  CardHeader,
  CardSubheader,
  DividerContainer,
  NoOfferDivider,
} from './styles';

const NoOffersCard = ({ header, subheader, noLender = false }) => (
  <CardContainer className="notalone" disabled>
    <Card disabled noLender={noLender}>
      <div>
        <CardHeader style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <Header5 style={{ margin: 0 }} color={Colors.BGW.darkGrey2}>
            {header ?? 'No Options Available'}
          </Header5>
          <IconButton icon="triangleError" color={Colors.BGW.darkGrey2} size={24} />
        </CardHeader>
        <CardSubheader>
          {subheader ?? 'There are no pre-approved options available. Please request an exception.'}
        </CardSubheader>
      </div>
    </Card>
    <DividerContainer>
      <NoOfferDivider />
    </DividerContainer>
  </CardContainer>
);
export default NoOffersCard;
