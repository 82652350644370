import { alertAction } from '../../components/Alert/reducer';
import { LOGOUT } from '../../pages/DealCalculator/constants';

export const axiosErrorInterceptor = (getStore) => (error) => {
  const { dispatch } = getStore() || {};
  if (!dispatch) {
    return;
  }
  if (error && error.response?.status === 401) {
    if (error.url && error.url.endsWith('/refetch')) {
      dispatch(
        alertAction({
          open: true,
          message: 'Refetch button: Unauthorized. Please log in again.',
          severity: 'error',
        }),
      );
    }
    localStorage.removeItem('token');
    window.location.replace(window.location.pathname);
    console.log('err', JSON.stringify(err));
    dispatch({
      type: LOGOUT,
    });
  }
  return Promise.reject(error);
};
