import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import { Colors } from '@easyknockorg/ek-ui-components';

export const ActionContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

export const Card = styled.div`
  width: 100%;
  letter-spacing: 0.5px;
  background-color: ${({ active, disabled }) =>
    active && disabled ? '#F5F6F7' : active ? '#F3FDFB' : ''};
  color: black;
  font-size: 18px;
  border: ${({ active, disabled }) =>
    active && !disabled ? '1px solid #00DBB1' : '1px solid #E7E7E7'};
  box-shadow: ${({ active, disabled }) =>
    active && !disabled ? '1px 1px 20px rgba(26, 48, 54, 0.2)' : ''};
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: 20px;
  margin: 0 auto;
  justify-content: center;
  color: ${Colors.BGW.outerSpace};

  :hover {
    border-color: #00dbb1;
  }
`;

export const CardContainer = styled.div`
  margin: 16px auto 0 auto;
  &:last-of-type {
    margin: ${({ onlyCard }) => (!onlyCard ? '0 auto' : '')};
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Cards = styled.div`
  margin: 0 auto;
  align-items: center;
  font-family: 'Kumbh Sans', sans-serif;
`;

export const CardSelected = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  color: ${({ uw }) => (uw ? Colors.BGW.gray7 : Colors.PineGreen.secondaryGreen)};
  font-weight: bold;
  margin-bottom: 4px;
`;

export const CardSubheader = styled.div`
  color: ${Colors.BGW.darkGrey2};
  font-size: 13px;
  margin-top: 4px;
`;

export const ChevronButton = styled.button`
  margin-right: 16px;
  font-size: 16px;
  background: none;
  border: none;
  font-family: 'Kumbh Sans', sans-serif;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: ${Colors.PineGreen.default};
`;

export const ChevronIconContainer = styled.div`
  text-align: center;
`;

export const CustomDivider = styled(Divider)`
  width: 42%;
`;

export const CustomRadio = styled(Radio)`
  margin: 0;
  padding: 0;
  align-self: flex-start;

  &.MuiRadio-colorSecondary.Mui-checked {
    color: ${Colors.PineGreen.default};
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: ${({ activeCard }) => (activeCard ? '24px' : '0')};
`;

export const DividerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const DividerText = styled.div`
  font-size: 13px;
  color: ${Colors.BGW.darkGrey2};
  padding: 16px;
`;

export const Solution = styled.div`
  width: 100%;
  font-size: ${({ uw }) => (uw ? '18px' : '13px')};
  margin: 0 auto;
  color: ${({ uw }) => (uw ? Colors.BGW.darkGrey : Colors.BGW.darkGrey2)};
  font-weight: bold;
`;
