import React from 'react';
import { ToggleContainer, StyledToggleButtonGroup, StyledToggleButton } from './styles';

const OptionsTab = ({ alignment, handleAlignment }) => {
  return (
    <ToggleContainer>
      <StyledToggleButtonGroup
        size="small"
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
      >
        <StyledToggleButton
          value="estimate"
          aria-label="estimate aligned"
          datatestid="estimate-option"
        >
          Estimate
        </StyledToggleButton>
        <StyledToggleButton
          value="futureOpts"
          aria-label="future options aligned"
          datatestid="future-options"
        >
          Future Options
        </StyledToggleButton>
      </StyledToggleButtonGroup>
    </ToggleContainer>
  );
};

export default OptionsTab;
