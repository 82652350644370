import styled from 'styled-components';
import { Colors } from '@easyknockorg/ek-ui-components';

export const ActionContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  button:hover {
    font-size: ${({ small }) => (small ? '16px' : '17px')};
  }
`;

export const ChevronButton = styled.button`
  font-size: ${({ small }) => (small ? '15px' : '16px')};
  font-weight: bold;
  background: none;
  border: none;
  font-family: 'Kumbh Sans', sans-serif;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${Colors.PineGreen.default};
  padding: 0;
  text-decoration-line: ${({ small }) => (small ? 'underline' : 'none')};
`;

export const ChevronIconContainer = styled.div`
  text-align: center;
  margin-top: 2px;
`;
