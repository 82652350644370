import { Header5 } from '@easyknockorg/ek-ui-components';
import { FlagContainer, Flag } from '../../../../../components/Organisms/Header/styles';
import { Detail } from '../../../../../components/Atoms/Detail';
import { dealFormVRevamp } from '../../../sectionFieldsMap';

const UwSnapshotView = ({ dealData, userData }) => {
  const sectionsRevamp = dealFormVRevamp(dealData, userData);
  const dpOutcome = dealData?.dealPortalOutcome;
  const classValue = typeof dpOutcome === 'string' ? dpOutcome.toLowerCase() : '';
  return (
    <>
      {sectionsRevamp?.map(({ title, fields }, idx) => (
        <div key={idx} style={{ marginTop: '40px' }}>
          <div style={{ display: 'flex' }}>
            <Header5 style={{ margin: '0 0 24px 0' }}>{title}</Header5>
            {idx === 0 && dpOutcome && (
              <FlagContainer>
                <Flag className={classValue} margin="0 0 0 8px">
                  {dpOutcome}
                </Flag>
              </FlagContainer>
            )}
          </div>
          {fields.map((field, idx) => {
            return (
              <div key={idx} style={{ marginBottom: '16px' }}>
                <Detail title={field.label} value={dealData[field.name]} />
              </div>
            );
          })}
        </div>
      ))}
    </>
  );
};

export default UwSnapshotView;
