export var Stages = {
    Discovery: 1,
    Proposal: 2,
    'Application Review': 3,
    'LOI Sent': 4,
    Screening: 5,
    Processing: 6,
    'Processing Post Review': 7,
    'PA Sent': 8,
    'Pre-Close': 9,
    'Clear to Close': 10,
    'Transaction Closed': 11,
    'On Hold': 12,
    Declined: 13,
};
