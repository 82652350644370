import accounting from 'accounting';
import styled from 'styled-components';
import { Colors } from '@easyknockorg/ek-ui-components';

export const TooltipHeader = styled.p`
  color: ${Colors.BGW.outerSpace};
  font-family: 'Kumbh Sans';
  font-size: 14px;
  font-weight: bold;
`;

export const TooltipProperty = styled.p`
  color: #a4a9ad;
  font-family: 'Kumbh Sans';
  font-size: 10px;
  font-weight: normal;
  margin-bottom: 5px;
`;

export const TooltipValue = styled.p`
  color: ${Colors.BGW.outerSpace};
  font-family: 'Kumbh Sans';
  font-size: 13px;
  font-weight: normal;
  margin-top: 5px;
`;

export const formatMoney = (amount) => {
  if (!amount) {
    return 'N/A';
  }
  return accounting.formatMoney(amount, '$ ', 0);
};
