import React, { useState, useEffect } from 'react';
import SkeletonLoadingOverlay from '../../Atoms/LoadingOverlay/Skeleton';
import { useDealContext } from '../../../contexts/DealContext';
import { SolutionName } from '../../../utils';
import { OfferCard } from '../../Molecules/OfferCard';
import { Cards, Solution } from './styles';
import { UserRole } from '../../../formulas/dto/userDto';
import NoOffersCard from '../../Molecules/OfferCard/NoOffersCard';
import UwSection from '../../../pages/DealCalculator/components/DealVerticalForm/UwSection';
import PreviousChoice from '../../../pages/DealCalculator/components/OfferCards/PreviousChoice';
import ActionButton from '../../Atoms/ActionButton';
import HcPopover from '../../Molecules/HcPopover';
import { ALTERNATE_OPTION_ESTIMATE_LABEL, ALTERNATE_OPTION_LABEL } from '../../../constants';
import { NewEstimateSalesTeamBetaList } from '../../../utils/constansts';

const OfferCardsGroup = () => {
  const {
    dealData,
    setDealData,
    offers,
    offersLoading,
    populateOptionSections,
    userData,
    setSnackbarState,
    defaultWithPrimary,
  } = useDealContext();
  const INITIAL = 0;
  const [clickedCard, setClickedCard] = useState(INITIAL);
  const [clickedRadio, setClickedRadio] = useState(INITIAL);
  const [offersParsed, setOffersParsed] = useState([]);

  const isSnapshot = !!dealData.snapshotName;
  const isDefaultDeal = !dealData.snapshotName;

  // Deal Owner -> The person who created the snapshot (NULL for default deal)
  const isDealOwnerUW = dealData?.dealOwnerRole === UserRole.Underwriting;
  const isCurrentUserUW = userData?.role === UserRole.Underwriting;
  const showUwStyle = isDealOwnerUW || (isDefaultDeal && isCurrentUserUW);

  const solution =
    isCurrentUserUW && isDefaultDeal
      ? SolutionName[defaultWithPrimary?.DealTypeId]
      : SolutionName[dealData?.DealTypeId];
  const solutionString = showUwStyle ? `${solution} Offer` : `${solution} offers`.toUpperCase();

  const getOptionName = (isSnapshot, optionSelected, offerName, isDealOwnerUW) => {
    const formattedOptionName = optionSelected?.replace(' Estimate', '');
    const snapshotOptionName = isDealOwnerUW ? 'Underwriting' : formattedOptionName;
    return isSnapshot ? snapshotOptionName : offerName;
  };

  useEffect(() => {
    if (isSnapshot) {
      setOffersParsed([
        {
          cashFunding: dealData.cashFunding,
          rent: dealData.monthlyRent,
          closingCosts: dealData.closingCosts,
        },
      ]);
      setClickedCard(INITIAL);
    } else if (isDefaultDeal && isCurrentUserUW) {
      setOffersParsed([]);
    } else {
      offers.exception = {
        cashFunding: dealData?.desiredCashout,
        rent: dealData?.desiredRent,
      };
      const offersArray = [
        offers.highCFOffer,
        offers.medianOffer,
        offers.lowCFOffer,
        offers.exception,
      ].filter((offer) => !!offer);

      setOffersParsed(offersArray);
    }
  }, [offers, dealData]);

  useEffect(() => {
    if (clickedRadio !== clickedCard) {
      setClickedRadio(clickedCard);
    }
    if (
      isDefaultDeal &&
      !isCurrentUserUW &&
      (dealData?.cashFunding !== offersParsed[clickedCard]?.cashFunding ||
        dealData?.monthlyRent !== offersParsed[clickedCard]?.rent)
    ) {
      const name = offersParsed.length === 1 ? ALTERNATE_OPTION_LABEL : 'Option 1';
      populateOptionSections(
        offersParsed[clickedCard]?.cashFunding,
        offersParsed[clickedCard]?.rent,
        offersParsed[clickedCard]?.closingCosts,
        name,
      );
    }

    if (isSnapshot && offersParsed.length === 1) {
      // Offers only contain one object, it should be considered the clicked option
      setClickedCard(INITIAL);
    }
  }, [offersParsed]);

  const handleClick = (id, cashFunding, rent, closingCosts, optionName) => {
    if (!isSnapshot) {
      setClickedCard(id);
      setClickedRadio(id);
      populateOptionSections(cashFunding, rent, closingCosts, optionName);
    }
  };

  const handleRadioChange = (index) => {
    setClickedRadio(index);
  };

  const handlePreview = () => {
    window.open(`${process.env.REACT_APP_W2EK_ROOT_URL}/estimate/${dealData.uuid}`);
  };

  const hcData = {
    lowerMonthlyRent: dealData?.lowerMonthlyRent,
    medianMonthlyRent: dealData?.medianMonthlyRent,
    upperMonthlyRent: dealData?.upperMonthlyRent,
    lowerPropertyValue: dealData?.lowerPropertyValue,
    medianPropertyValue: dealData?.medianPropertyValue,
    upperPropertyValue: dealData?.upperPropertyValue,
  };

  const ccData = {
    ccLowerPropertyValue: dealData?.clearCapitalLowerPropertyValue,
    ccMarketPropertyValue: dealData?.clearCapitalMarketPropertyValue,
    ccUpperPropertyValue: dealData?.clearCapitalUpperPropertyValue,
  };

  // --- Popover elements ---
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <SkeletonLoadingOverlay loading={offersLoading} />
      <Cards>
        <Solution uw={showUwStyle}>
          {solutionString}
          <div style={{ marginBottom: showUwStyle ? '0' : '3px' }}>
            <ActionButton
              small
              icon="info"
              iconSize="20"
              value="Est. Values"
              handleClick={handleClickPopover}
              disabled={false}
            />
            <HcPopover
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              hcData={hcData}
              ccData={ccData}
            />
          </div>
        </Solution>
        {showUwStyle && (isDefaultDeal || dealData?.markedPrimaryBy === UserRole.Default) && (
          <PreviousChoice
            dealData={dealData}
            isCurrentUserUW={isCurrentUserUW}
            isSnapshot={isSnapshot}
            showUwStyle={showUwStyle}
          />
        )}
        {!isCurrentUserUW && offersParsed?.length === 1 && !isSnapshot ? (
          dealData.noLender ? (
            <NoOffersCard
              header="No Lender Available"
              subheader="Please request an alternate option"
              noLender
            />
          ) : (
            <NoOffersCard />
          )
        ) : null}

        {offersParsed &&
          offersParsed.map((offer, index) => {
            const activeCard = index === clickedCard;
            const optionException = !isSnapshot
              ? index === offersParsed.length - 1
              : dealData.optionSelected === ALTERNATE_OPTION_ESTIMATE_LABEL;
            const offerName = !optionException ? `Option ${index + 1}` : ALTERNATE_OPTION_LABEL;
            const optionName = getOptionName(
              isSnapshot,
              dealData?.optionSelected,
              offerName,
              isDealOwnerUW,
            );

            return (
              <OfferCard
                key={index}
                activeCard={activeCard}
                cashFunding={offer?.cashFunding}
                clickedCard={clickedCard}
                clickedRadio={clickedRadio}
                closingCosts={offer?.closingCosts}
                dealData={dealData}
                handleClick={handleClick}
                handlePreview={handlePreview}
                handleRadioChange={handleRadioChange}
                index={index}
                isSnapshot={isSnapshot}
                offersLength={offersParsed?.length}
                offerName={optionName}
                optionException={optionException}
                rent={offer?.rent}
                hcData={hcData}
              />
            );
          })}
      </Cards>
      <UwSection
        dealData={dealData}
        setDealData={setDealData}
        userData={userData}
        setSnackbarState={setSnackbarState}
      />
    </>
  );
};

export default OfferCardsGroup;
