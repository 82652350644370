import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import styled from 'styled-components';
import { Colors } from '@easyknockorg/ek-ui-components';

export const StyledToggleButton = styled(ToggleButton).attrs(props => ({
    'data-test-id': props.datatestid // Pass the testId prop as the value for data-test-id
}))`
  &.MuiToggleButton-root.Mui-selected {
    color: ${Colors.BGW.outerSpace};
    background-color: ${Colors.BGW.white};
  }
  &.Mui-selected,
  &.Mui-selected:hover {
    color: ${Colors.BGW.outerSpace};
    background-color: ${Colors.BGW.white};
  }
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  .MuiToggleButtonGroup-grouped {
    margin: 8px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    text-transform: none;
    border-radius: 56px !important;
    padding: 8px 16px;
  }
`;

export const ToggleContainer = styled.div`
  border: 1px solid #e7e7e7;
  display: flex;
  background: #e7e7e7;
  border-radius: 100px;
  width: 279px;
  height: 56px;
  justify-content: center;
`;
