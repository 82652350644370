import React from 'react';
import accounting from 'accounting';
import Divider from '@material-ui/core/Divider';
import { MainContainer, Title, ValuesContainer, Label, Value } from '../styles';
import ClearCapitalBlock from './ClearCapitalBlock';

const HcPopoverContent = ({ hcData, ccData }) => {
  const formatMoney = (amount) => accounting.formatMoney(amount, '$ ', 0);
  const {
    lowerMonthlyRent,
    medianMonthlyRent,
    upperMonthlyRent,
    lowerPropertyValue,
    medianPropertyValue,
    upperPropertyValue,
  } = hcData;
  return (
    <MainContainer>
      <div>
        <Title>HC Home Value</Title>
        <ValuesContainer>
          <Label>High</Label> <Value>{formatMoney(upperPropertyValue)}</Value>
        </ValuesContainer>
        <ValuesContainer>
          <Label>Median</Label> <Value>{formatMoney(medianPropertyValue)}</Value>
        </ValuesContainer>
        <ValuesContainer>
          <Label>Low</Label> <Value>{formatMoney(lowerPropertyValue)}</Value>
        </ValuesContainer>
      </div>
      <ClearCapitalBlock ccData={ccData}></ClearCapitalBlock>
      <Divider orientation="vertical" flexItem style={{ margin: '0 16px' }} />
      <div>
        <Title>HC Rent Value</Title>
        <ValuesContainer>
          <Label>High</Label> <Value>{formatMoney(upperMonthlyRent)}</Value>
        </ValuesContainer>
        <ValuesContainer>
          <Label>Median</Label> <Value>{formatMoney(medianMonthlyRent)}</Value>
        </ValuesContainer>
        <ValuesContainer>
          <Label>Low</Label> <Value>{formatMoney(lowerMonthlyRent)}</Value>
        </ValuesContainer>
      </div>
    </MainContainer>
  );
};

export default HcPopoverContent;
