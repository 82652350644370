import React from 'react';

const MessageTooltip = () => {
  return (
    <div
      style={{
        display: 'flex',
        margin: '12px',
        width: '130px',
        fontSize: '13px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Property Insurance excluding flood insurance premiums.
    </div>
  );
};

export default MessageTooltip;
