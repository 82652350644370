import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import apiClient from '../../utils/apiClient';

const FullViewObject = styled.object`
  height: 100vh;
  width: 100%;
`;

const EquifaxPdf = () => {
  const { search } = useLocation();
  const id = new URLSearchParams(search).get('id');
  const [pdf, setPdf] = useState([]);

  useEffect(async () => {
    const pdfUrl = await apiClient.enrichmentEngine.getPdf(id).then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      return URL.createObjectURL(blob);
    });

    setPdf(
      <FullViewObject data={pdfUrl}>Failed to load Equifax PDF with id: #{id}</FullViewObject>,
    );
  }, [search]);

  return pdf;
};

export default connect(() => ({}), {})(EquifaxPdf);
