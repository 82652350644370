import React from 'react';
import { TooltipHeader, TooltipProperty, TooltipValue } from '..';

const HoldbackMonthsTooltip = () => (
  <div style={{ margin: '0px 12px', width: '125px' }}>
    <TooltipHeader>Number of Months</TooltipHeader>
    <TooltipProperty>Max</TooltipProperty>
    <TooltipValue>11</TooltipValue>
    <TooltipProperty>Min</TooltipProperty>
    <TooltipValue>0</TooltipValue>
  </div>
);

export default HoldbackMonthsTooltip;
