import React from 'react';
import accounting from 'accounting';
import { connect } from 'react-redux';
import Percent from '../Percent';
import { formSelector } from '../../constants';
import greenDotIcon from '../../../../images/green_dot.svg';
import { Colors } from '@easyknockorg/ek-ui-components';

const formatMoney = (amount) => accounting.formatMoney(amount, '$ ', 0);

const TextDisplay = ({ label, hasGreenDot, percent, style, text, formatAsMoney = false }) => (
  <div style={style}>
    <div
      style={{
        fontSize: '12px',
        lineHeight: '26px',
        letterSpacing: '0.01em',
        color: Colors.BGW.grey3,
      }}
    >
      {label}
    </div>
    <div
      style={{
        fontSize: '15px',
        lineHeight: '26px',
        color: '#454B4E',
      }}
    >
      {hasGreenDot && (
        <img
          alt="phone"
          src={greenDotIcon}
          style={{
            maxHeight: '11px',
            marginRight: '3px',
          }}
        />
      )}
      <span style={{ float: 'left', minWidth: '85%' }}>
        {formatAsMoney ? formatMoney(text) : text}
      </span>
      {percent && (
        <Percent
          style={{
            display: 'inline-block',
            textAlign: 'right',
            width: '15%',
          }}
          {...percent}
        />
      )}
    </div>
  </div>
);

export default connect((state = {}, { k, format, text }) => {
  const deal = formSelector(state) || {};
  return {
    text: k ? (format ? format(deal[k]) : deal[k]) : text,
  };
})(TextDisplay);
