import { getConfig } from '../config';
import versionIdLatest from '../../config/version-id-latest';
import { getStateCode } from '../../util/format';
import { UserRole } from '../../dto/userDto';
import { Stages } from '../../util/salesforce/stages';
import { deltaDays } from '../../util/functions';
import { getDefaultFundingSource } from './helperFormulas';
import { ALTERNATE_OPTION_LABEL } from '../../constants';
var dealTypes = ['', 'SellAndStay', 'MoveAbility', 'ReLease', 'ReLease'];
var FormulaMapLatest = /** @class */ (function () {
    function FormulaMapLatest(deal, user, runInit) {
        var _this = this;
        if (runInit === void 0) { runInit = true; }
        this.deal = null;
        this.user = null;
        this.date = new Date();
        this.init = function (deal, user) {
            (deal.staticFields || []).forEach(function (field) {
                if (_this[field]) {
                    _this[field].calc = function () { return deal[field] || 0; };
                }
            });
            var UNOVERRIDABLE_FIELDS = ['rentHoldbackMonths'];
            Object.getOwnPropertyNames(_this).forEach(function (key) {
                if ((user === null || user === void 0 ? void 0 : user.role) !== UserRole.Underwriting || UNOVERRIDABLE_FIELDS.includes(key)) {
                    if (_this[key] && _this[key].max) {
                        var tmpCalc_1 = _this[key].calc;
                        _this[key].calc = function () { return Math.min(tmpCalc_1(), _this[key].max()); };
                    }
                    if (_this[key] && _this[key].min) {
                        var tmpCalc_2 = _this[key].calc;
                        _this[key].calc = function () { return Math.max(tmpCalc_2(), _this[key].min()); };
                    }
                }
            });
            _this.deal = deal;
            _this.user = user;
        };
        this.calculateCashUponSale = function (repurchasePrice) {
            return _this.purchasePrice.calc() - _this.realtorCosts.calc() - repurchasePrice.calc();
        };
        this.calculateOptFee = function (optIncrease) {
            return (optIncrease.calc() * _this.purchasePrice.calc()) / 100;
        };
        this.config = function () {
            var dealType = _this.dealType.calc();
            var config = getConfig(_this.VersionId.calc());
            if (!dealType || !(dealType in config)) {
                return config.default;
            }
            return config[dealType];
        };
        this.pricingConfig = function (lender) {
            var dealType = _this.dealType.calc();
            var config = getConfig(_this.VersionId.calc());
            lender || (lender = getDefaultFundingSource(_this.deal));
            if (!lender)
                return null;
            return config[dealType].pricing[lender];
        };
        this.annualRent = {
            calc: function () { return _this.monthlyRent.calc() * 12; },
        };
        this.backendFee1Percent = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                return dealTypeId === 2 /* MoveAbility */ ? _this.config().BACKEND_FEE_1 : 0;
            },
        };
        this.backendFee2Percent = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                return dealTypeId === 2 /* MoveAbility */ ? _this.config().BACKEND_FEE_2 : 0;
            },
        };
        this.backendFee1Value = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                if (dealTypeId === 2 /* MoveAbility */) {
                    return (_this.config().BACKEND_FEE_1 / 100) * _this.purchasePrice.calc();
                }
                else
                    return 0;
            },
        };
        this.backendFee2Value = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                if (dealTypeId === 2 /* MoveAbility */) {
                    return (_this.config().BACKEND_FEE_2 / 100) * _this.purchasePrice.calc();
                }
                else
                    return 0;
            },
        };
        this.cashFunding = {
            calc: function () { return _this.deal.cashFunding || 0; },
            max: function () { return _this.deal.cashFunding || Infinity; },
            min: function () { return _this.deal.cashFunding || 0; },
        };
        this.cashProceedRatio = {
            calc: function () {
                var easyKnockProcessingFee = _this.easyKnockProcessingFee.calc();
                if (!easyKnockProcessingFee) {
                    return 0;
                }
                return _this.cashRelease.calc() / easyKnockProcessingFee;
            },
        };
        this.cashRelease = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                var sellAndStayRelease = _this.cashFunding.calc() -
                    _this.easyKnockProcessingFee.calc() -
                    _this.liens.calc() -
                    _this.rentPrepayment.calc() -
                    _this.proratedRent.calc() -
                    _this.partialRentPrepaymentAmount.calc() -
                    _this.repairFundAmount.calc() -
                    _this.warranty.calc() -
                    _this.mortgageBalance.calc() -
                    _this.closingCosts.calc() -
                    _this.transferTax.calc() -
                    _this.remainingPropertyTax.calc();
                if (dealTypeId === 1 /* SellAndStay */)
                    return sellAndStayRelease;
                if (dealTypeId === 2 /* MoveAbility */) {
                    return sellAndStayRelease - _this.halfOptionFee.calc();
                }
                return sellAndStayRelease - _this.halfOptionFee.calc();
            },
        };
        this.cashUponSaleYear1 = {
            calc: function () { return _this.calculateCashUponSale(_this.repurchasePriceYear1); },
        };
        this.cashUponSaleYear2 = {
            calc: function () { return _this.calculateCashUponSale(_this.repurchasePriceYear2); },
        };
        this.cashUponSaleYear3 = {
            calc: function () { return _this.calculateCashUponSale(_this.repurchasePriceYear3); },
        };
        this.cashUponSaleYear4 = {
            calc: function () { return _this.calculateCashUponSale(_this.repurchasePriceYear4); },
        };
        this.cashUponSaleYear5 = {
            calc: function () { return _this.calculateCashUponSale(_this.repurchasePriceYear5); },
        };
        this.cashUponSaleUnder6Months = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                if (dealTypeId === 2 /* MoveAbility */) {
                    var initialCashUponSale = _this.purchasePrice.calc() -
                        _this.repurchasePriceYear1.calc() -
                        _this.backendFee1Value.calc();
                    return initialCashUponSale;
                }
                else
                    return 0;
            },
        };
        this.cashUponSaleOver6Months = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                if (dealTypeId === 2 /* MoveAbility */) {
                    var initialCashUponSale = _this.purchasePrice.calc() -
                        _this.repurchasePriceYear1.calc() -
                        _this.backendFee1Value.calc() -
                        _this.backendFee2Value.calc();
                    return initialCashUponSale;
                }
                else
                    return 0;
            },
        };
        this.costOfCapital = {
            calc: function (lender) {
                var pricingConfig = _this.pricingConfig(lender);
                return pricingConfig ? pricingConfig.costOfCapital : 0;
            },
        };
        this.closingCosts = {
            calc: function () {
                var _a;
                return (_a = _this.deal.closingCosts) !== null && _a !== void 0 ? _a : ((_this.config().DEFAULT_CLOSING_COST / 100) * _this.purchasePrice.calc() || 0);
            },
            min: function () { return 0; },
        };
        this.remainingPropertyTax = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                if (dealTypeId === 1 /* SellAndStay */ || dealTypeId === 2 /* MoveAbility */) {
                    // Date.now needed for unit tests to work.
                    return (_this.realEstateTaxes.calc() * (12 - new Date(Date.now()).getMonth() - 1)) / 12;
                }
                return 0;
            },
        };
        this.dealType = {
            calc: function () { return dealTypes[_this.deal.DealTypeId]; },
        };
        this.DealTypeId = {
            calc: function () {
                return _this.deal.DealTypeId ? parseInt(_this.deal.DealTypeId.toString(), 10) : 0;
            },
        };
        this.desiredCashout = {
            calc: function () { return _this.deal.desiredCashout || 0; },
        };
        this.desiredRent = {
            calc: function () { return _this.deal.desiredRent || 0; },
        };
        this.dscr = {
            calc: function () {
                var numerator = _this.monthlyRent.calc() * 12;
                var denominator = _this.totalPayment.calc() * 12 +
                    _this.realEstateTaxes.calc() +
                    _this.hoa.calc() +
                    _this.landlordInsurance.calc() +
                    _this.floodInsurance.calc();
                if (denominator === 0) {
                    return 0;
                }
                return numerator / denominator;
            },
        };
        this.easyKnockProcessingFee = {
            calc: function () {
                var calculatedFee = (_this.config().DEFAULT_EK_PROCESSING_FEE * _this.purchasePrice.calc()) / 100;
                return calculatedFee;
            },
        };
        this.floodInsurance = {
            calc: function () { var _a; return (_a = _this.deal.floodInsurance) !== null && _a !== void 0 ? _a : 0; },
        };
        this.isVersionSupported = {
            calc: function () { return deltaDays(new Date(), _this.date) < _this.config().LEGACY_SUPPORT_DAYS; },
            warning: function () {
                var daysOldPolicy = deltaDays(new Date(), _this.date);
                var daysExpiredPolicy = daysOldPolicy - _this.config().LEGACY_SUPPORT_DAYS;
                if (daysExpiredPolicy > 0) {
                    return {
                        title: 'Deal Exception',
                        message: "This deal is flagged because its version expired ".concat(Math.ceil(daysExpiredPolicy), " days ago. Please create a new deal."),
                        roleVisibility: ['Underwriting', 'Default'],
                    };
                }
                else {
                    return null;
                }
            },
        };
        this.halfOptionFee = {
            calc: function () { return (_this.optionFeeYear1.calc() || 0) * 0.5; },
        };
        this.hoa = {
            calc: function () { var _a; return (_a = _this.deal.hoa) !== null && _a !== void 0 ? _a : 0; },
            min: function () { return 0; },
        };
        this.incomeRatio = {
            calc: function () {
                var monthlyIncome = _this.monthlyIncome.calc();
                var monthlyRent = _this.monthlyRent.calc();
                if (!monthlyRent)
                    return 0;
                return monthlyIncome / monthlyRent;
            },
        };
        this.isPostProcessingPostReview = {
            calc: function () {
                var stageName = _this.deal.stageName;
                return (Stages.hasOwnProperty(stageName) && Stages[stageName] >= Stages['Processing Post Review']);
            },
        };
        this.landlordExpenses = {
            calc: function () {
                var landlordExpensesPercent = _this.config().DEFAULT_LANDLORD_EXPENSE_RATE / 100;
                return _this.monthlyRent.calc() * 12 * landlordExpensesPercent;
            },
            min: function () { return 0; },
        };
        this.landlordInsurance = {
            calc: function () {
                if (_this.isPostProcessingPostReview.calc()) {
                    return _this.deal.landlordInsurance || 0;
                }
                var stateCode = getStateCode(_this.deal.state);
                var landlordInsurancePercent = _this.config().DEFAULT_LANDLORD_INSURANCE_RATE[stateCode] ||
                    _this.config().DEFAULT_LANDLORD_INSURANCE_RATE.OTHER;
                return _this.purchasePrice.calc() * (landlordInsurancePercent / 100);
            },
            max: function () { return _this.purchasePrice.calc(); },
            min: function () { return 0; },
        };
        this.liens = {
            calc: function () { return _this.deal.liens || 0; },
            min: function () { return 0; },
        };
        this.loanToValue = {
            calc: function () {
                var purchasePrice = _this.purchasePrice.calc();
                if (!purchasePrice) {
                    return 0;
                }
                return _this.cashFunding.calc() / purchasePrice;
            },
        };
        this.lowerMonthlyRent = {
            calc: function () { return _this.deal.lowerMonthlyRent || 0; },
        };
        this.maxLoanToValue = {
            calc: function () {
                var purchasePrice = _this.purchasePrice.calc();
                var lender = getDefaultFundingSource(_this.deal);
                var pricingConfig = _this.pricingConfig();
                if (!lender || !pricingConfig)
                    return 0;
                var cashFunding = pricingConfig.cashFunding, cashFundingMax = pricingConfig.cashFundingMax;
                if (purchasePrice < 600000) {
                    return cashFundingMax;
                }
                else {
                    return cashFunding;
                }
            },
        };
        this.medianMonthlyRent = {
            calc: function () { return _this.deal.medianMonthlyRent || 0; },
        };
        this.monthlyIncome = {
            calc: function () { return _this.deal.monthlyIncome || 0; },
        };
        this.monthlyNetRent = {
            calc: function () {
                var _a, _b;
                if (_this.isSnapshot.calc()) {
                    return _this.deal.monthlyNetRent;
                }
                if (((_a = _this.user) === null || _a === void 0 ? void 0 : _a.role) === UserRole.Underwriting &&
                    ((_b = _this.deal.optionSelected) === null || _b === void 0 ? void 0 : _b.includes(ALTERNATE_OPTION_LABEL))) {
                    return _this.desiredRent.calc();
                }
                return _this.monthlyRent.calc() - _this.rentHoldbackAmount.calc();
            },
        };
        this.monthlyRent = {
            calc: function () {
                var _a, _b;
                if (_this.isSnapshot.calc()) {
                    return _this.deal.monthlyRent;
                }
                if (((_a = _this.user) === null || _a === void 0 ? void 0 : _a.role) === UserRole.Underwriting &&
                    ((_b = _this.deal.optionSelected) === null || _b === void 0 ? void 0 : _b.includes(ALTERNATE_OPTION_LABEL))) {
                    return _this.desiredRent.calc();
                }
                return _this.deal.monthlyRent || 0;
            },
            min: function () { return _this.deal.monthlyRent || 0; },
            max: function () { return _this.deal.monthlyRent || Infinity; },
        };
        this.mortgageBalance = {
            calc: function () { return _this.deal.mortgageBalance || 0; },
            min: function () { return 0; },
        };
        this.netRentYieldPercent = {
            calc: function () {
                var cashFunding = _this.cashFunding.calc();
                if (cashFunding <= 0) {
                    return 0;
                }
                return ((100 *
                    (_this.annualRent.calc() -
                        _this.landlordExpenses.calc() -
                        _this.realEstateTaxes.calc() -
                        _this.hoa.calc() -
                        _this.landlordInsurance.calc() -
                        _this.floodInsurance.calc())) /
                    _this.cashFunding.calc());
            },
        };
        this.optionFeeYear1 = {
            calc: function () { return _this.calculateOptFee(_this.optionPeriodIncreaseYear1); },
        };
        this.optionFeeYear2 = {
            calc: function () { return _this.calculateOptFee(_this.optionPeriodIncreaseYear2); },
        };
        this.optionFeeYear3 = {
            calc: function () { return _this.calculateOptFee(_this.optionPeriodIncreaseYear3); },
        };
        this.optionFeeYear4 = {
            calc: function () { return _this.calculateOptFee(_this.optionPeriodIncreaseYear4); },
        };
        this.optionFeeYear5 = {
            calc: function () { return _this.calculateOptFee(_this.optionPeriodIncreaseYear5); },
        };
        this.optionPeriodIncreaseYear1 = {
            calc: function () { return _this.config().OPTION_PERIOD_INCREASE_YEAR_1 || 0; },
        };
        this.optionPeriodIncreaseYear2 = {
            calc: function () { return _this.config().OPTION_PERIOD_INCREASE_YEAR_2 || 0; },
        };
        this.optionPeriodIncreaseYear3 = {
            calc: function () { return _this.config().OPTION_PERIOD_INCREASE_YEAR_3 || 0; },
        };
        this.optionPeriodIncreaseYear4 = {
            calc: function () { return _this.config().OPTION_PERIOD_INCREASE_YEAR_4 || 0; },
        };
        this.optionPeriodIncreaseYear5 = {
            calc: function () { return _this.config().OPTION_PERIOD_INCREASE_YEAR_5 || 0; },
        };
        this.optionValue = {
            calc: function () { return _this.purchasePrice.calc() - _this.cashFunding.calc(); },
        };
        this.propertyInsurance = {
            // TODO: Remove property insurance from code. Leaving for compatibility with sub v18
            calc: this.landlordInsurance.calc,
        };
        this.proratedRent = {
            calc: function () { return _this.monthlyRent.calc() * 0.5; },
        };
        // The PandaDoc Token for this will include the proprated rent, but the formula will not.
        // TBD move '2' to a config variable
        this.rentPrepayment = {
            calc: function () {
                return _this.monthlyRent.calc() * _this.rentHoldbackMonths.calc();
            },
        };
        this.partialRentPrepaymentAmount = {
            calc: function () {
                return _this.rentHoldbackAmount.calc() * (12 - _this.rentHoldbackMonths.calc());
            },
        };
        this.partialRentPrepaymentStartMonth = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                if (dealTypeId === 1 /* SellAndStay */ || dealTypeId === 2 /* MoveAbility */) {
                    return _this.rentHoldbackAmount.calc() * _this.rentHoldbackMonths.calc() > 0
                        ? _this.rentHoldbackMonths.calc() + 1
                        : 0;
                }
                else {
                    // Movability and all other deal types on record right now
                    return 1;
                }
            },
        };
        this.isSnapshot = {
            calc: function () { return _this.deal.snapshotName != undefined && _this.deal.snapshotName != ''; },
        };
        this.purchasePrice = {
            calc: function () { return _this.deal.purchasePrice || 0; },
            min: function () { return 0; },
            max: function () {
                // If is snapshot - take purchase price - otherwise...
                if ((_this.isPostProcessingPostReview.calc() && _this.deal.pddCompletedDate) ||
                    _this.isSnapshot.calc()) {
                    return _this.deal.purchasePrice;
                }
                else {
                    return _this.deal.medianPropertyValue || Infinity;
                }
            },
        };
        this.realEstateTaxes = {
            calc: function () {
                if (_this.isPostProcessingPostReview.calc()) {
                    return _this.deal.realEstateTaxes || 0;
                }
                var stateCode = getStateCode(_this.deal.state);
                var percent = _this.config().DEFAULT_RE_TAXES[stateCode] || _this.config().DEFAULT_RE_TAXES.OTHER;
                return _this.purchasePrice.calc() * (percent / 100);
            },
            max: function () { return _this.purchasePrice.calc(); },
            min: function () { return 0; },
        };
        this.realtorCosts = {
            calc: function () { return (_this.config().DEFAULT_REALTOR_COST / 100) * _this.purchasePrice.calc() || 0; },
            min: function () { return 0; },
        };
        this.rentHoldbackAmount = {
            calc: function () {
                var _a, _b, _c;
                if (_this.isSnapshot.calc()) {
                    return _this.deal.rentHoldbackAmount;
                }
                if (((_a = _this.user) === null || _a === void 0 ? void 0 : _a.role) === UserRole.Underwriting &&
                    (((_b = _this.deal.optionSelected) === null || _b === void 0 ? void 0 : _b.includes(ALTERNATE_OPTION_LABEL)) ||
                        ((_c = _this.deal.staticFields) === null || _c === void 0 ? void 0 : _c.includes('monthlyNetRent')))) {
                    return _this.monthlyRent.calc() - _this.monthlyNetRent.calc();
                }
                var monthlyIncome = _this.monthlyIncome.calc();
                var monthlyRent = _this.monthlyRent.calc();
                var monthlyHoldback = monthlyIncome / monthlyRent < 2 ? monthlyRent - monthlyIncome / 2 : 0;
                var holdbackMax = (_this.config().MAX_RENT_HOLDBACK / 100) * monthlyRent;
                return Math.min(monthlyHoldback, holdbackMax);
            },
        };
        this.rentHoldbackMonths = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                // This will be also tied to a lender soon
                // Both products now are rent hold back months 1
                if (dealTypeId === 1 /* SellAndStay */ || dealTypeId === 2 /* MoveAbility */) {
                    return 1;
                }
                else {
                    return 0;
                }
            },
            min: function () { return 0; },
            max: function () { return 11; },
        };
        this.rentHoldbackPays = {
            calc: function () { return Math.max(_this.monthlyRent.calc() - _this.desiredRent.calc(), 0); },
        };
        this.repairFundAmount = {
            calc: function () { var _a; return (_a = _this.deal.repairFundAmount) !== null && _a !== void 0 ? _a : 0; },
        };
        this.repurchasePriceYear1 = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                if (dealTypeId === 1 /* SellAndStay */) {
                    return _this.cashFunding.calc() + _this.optionFeeYear1.calc();
                }
                return _this.cashFunding.calc();
            },
        };
        this.repurchasePriceYear2 = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                if (dealTypeId === 1 /* SellAndStay */) {
                    return _this.repurchasePriceYear1.calc() + _this.optionFeeYear2.calc();
                }
                return _this.cashFunding.calc();
            },
        };
        this.repurchasePriceYear3 = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                if (dealTypeId === 1 /* SellAndStay */) {
                    return _this.repurchasePriceYear2.calc() + _this.optionFeeYear3.calc();
                }
                return _this.cashFunding.calc();
            },
        };
        this.repurchasePriceYear4 = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                if (dealTypeId === 1 /* SellAndStay */) {
                    return _this.repurchasePriceYear3.calc() + _this.optionFeeYear4.calc();
                }
                return _this.cashFunding.calc();
            },
        };
        this.repurchasePriceYear5 = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                if (dealTypeId === 1 /* SellAndStay */) {
                    return _this.repurchasePriceYear4.calc() + _this.optionFeeYear5.calc();
                }
                return _this.cashFunding.calc();
            },
        };
        this.securityDeposit = {
            calc: function () {
                var securityDepositFactor = _this.config().DEFAULT_SECURITY_DEPOSIT_FACTOR;
                return securityDepositFactor * _this.monthlyRent.calc();
            },
        };
        this.titleInsurance = {
            calc: function () { return _this.deal.titleInsurance || 0; },
            min: function () { return 0; },
        };
        this.totalPayment = {
            calc: function () { return (_this.costOfCapital.calc() / 100 / 12) * _this.cashFunding.calc(); },
        };
        this.totalAssetYieldPercent = {
            calc: function () { return _this.netRentYieldPercent.calc() + _this.optionPeriodIncreaseYear1.calc(); },
        };
        this.transferTax = {
            calc: function () {
                var _a;
                var percentage = (_a = _this.config().DEFAULT_TRANSFER_TAX[getStateCode(_this.deal.state)]) !== null && _a !== void 0 ? _a : _this.config().DEFAULT_TRANSFER_TAX.OTHER;
                return (percentage * _this.purchasePrice.calc()) / 100;
            },
            min: function () { return 0; },
        };
        this.upperMonthlyRent = {
            calc: function () { return _this.deal.upperMonthlyRent || 0; },
        };
        this.VersionId = {
            calc: function () { return _this.deal.VersionId || versionIdLatest; },
        };
        this.warranty = {
            calc: function () {
                var multiplier = _this.DealTypeId.calc() === 1 /* SellAndStay */ ? 2 : 1;
                return _this.config().WARRANTY * multiplier;
            },
        };
        if (runInit) {
            this.init(deal, user);
        }
    }
    return FormulaMapLatest;
}());
export { FormulaMapLatest };
