import React from 'react';
import { connect } from 'react-redux';
import { formSelector } from '../../../constants';
import { formatMoney, TooltipHeader, TooltipProperty, TooltipValue } from '..';

const HomeValueTooltip = ({ deal, passedDealData = null }) => {
  if (passedDealData) {
    deal = passedDealData;
  }
  return (
    <div style={{ margin: '15px 12px', width: '150px' }}>
      <TooltipHeader>Home Value</TooltipHeader>
      <TooltipProperty>High</TooltipProperty>
      <TooltipValue>{formatMoney(deal.upperPropertyValue)}</TooltipValue>
      <TooltipProperty>Median</TooltipProperty>
      <TooltipValue>{formatMoney(deal.medianPropertyValue)}</TooltipValue>
      <TooltipProperty>Low</TooltipProperty>
      <TooltipValue>{formatMoney(deal.lowerPropertyValue)}</TooltipValue>
    </div>
  );
};

const mapStateToProps = (state) => ({
  deal: formSelector(state),
});

export default connect(mapStateToProps)(HomeValueTooltip);
