import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Header6 } from '@easyknockorg/ek-ui-components';
import greenDotIcon from '../../../images/green_dot.svg';
import { isVRevamp } from '../../../utils';
import apiClient from '../../../utils/apiClient';
import SimpleDataViewerModal from '../SimpleDataViewerModal';
import ActionButton from '../../Atoms/ActionButton';

const InfoBlock = ({ name, data, style }) => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      background: 'white',
      zIndex: '1000',
      ...style,
    }}
  >
    <h5
      style={{
        fontFamily: 'Kumbh Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '20px',
        letterSpacing: '0.01em',
        color: '#6E767C',
        padding: '5px',
      }}
    >
      {name}
    </h5>
    {/* This image is hidden beacause it is meant as a color indicator.
    At the moment the functionallity is not ready yet. The ranges for
    the red, yellow, and green colors need to be defined first, and
    will be implemented at a later stage. */}
    <img
      alt="phone"
      src={greenDotIcon}
      style={{
        maxHeight: '11px',
        marginRight: '3px',
        display: 'none',
      }}
    />
    <p
      style={{
        fontFamily: 'Kumbh Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '26px',
        color: '#454B4E',
        padding: '5px',
      }}
    >
      {data}
    </p>
  </div>
);

const Footer = ({ data = [], dealData }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [rateSheetContent, setRateSheetContent] = useState('');

  const viewOfferSheet = async (dealData) => {};
  const getDPEvents = async (dealData) => {
    if (dealData) {
      const rateSheet = await apiClient.deal.getDPRateSheetById(dealData.lenderRateSheetID);
      console.log('RateSheet Details:');
      console.log(rateSheet);
      setRateSheetContent(JSON.stringify(rateSheet, null, 2));
      setShowDetails(true);

      const events = await apiClient.deal.getDPEventsByOpportunityId(dealData.opportunityId);
      console.log('Events Details:');
      console.log(events);
    }
  };

  return (
    <Paper variant="outlined" square>
      {dealData && isVRevamp(dealData) ? (
        <Container maxWidth="xl">
          <Grid item xs={12}>
            <Header6
              onClick={() => getDPEvents(dealData)}
              style={{ color: '#6e767c', textAlign: 'right', padding: '0 45px' }}
            >
              <span>Deal Portal V{dealData.VersionId}</span>
              <span style={{ padding: '0 10px' }}>RateSheet {dealData.lenderRateSheetID}</span>
              <Link
                style={{ textDecoration: 'none' }}
                to={`/deal-calculator-revamp/${dealData.id}/offers`}
              >
                Offer Sheet
              </Link>
            </Header6>
          </Grid>
        </Container>
      ) : (
        <div
          style={{
            zindex: '999999999',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {data.map((p) => (
            <InfoBlock key={p.name} {...p} />
          ))}
        </div>
      )}
      <SimpleDataViewerModal
        title="Loan Rate Sheet Details"
        subtitle={rateSheetContent}
        open={showDetails}
        setOpen={setShowDetails}
        submitLabel="OK"
      />
    </Paper>
  );
};

export default Footer;
