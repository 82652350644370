import { debounce } from 'lodash';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Header5 } from '@easyknockorg/ek-ui-components';
import { TextField } from '@material-ui/core';

import InfoAdornment from '../../../../../components/ReduxFormWrapers/TextField/components/InfoAdornment';
import Percent from '../../Percent';
import { Form, MaxMark, RentReport, SliderMarks, StyledSlider, TextFieldContainer } from './styles';
import { Detail } from '../../../../../components/Atoms/Detail';
import ActionButton from '../../../../../components/Atoms/ActionButton';
import MultifieldModal from '../../../../../components/Organisms/MultifieldModal';
import { runFormulas } from '../../../../../formulas/services/formulas';
import {
  formatNumberToString,
  numberWithCommas,
  validFloatingPointNumber,
} from '../../../../../utils';
import { dealFormVRevamp } from '../../../sectionFieldsMap';
import { onSuccess } from '../../../../../utils/snackbar';

const editFields = [
  {
    label: 'Higher Rent',
    name: 'upperMonthlyRent',
  },
  {
    label: 'Lower Rent',
    name: 'lowerMonthlyRent',
  },
];

const TIME_DELAY_FORM_UPDATE = 2000;

const UwDefaultView = ({ dealData, setDealData, userData, setSnackbarState }) => {
  const [formFields, setFormFields] = useState({});
  const [openEdit, setOpenEdit] = useState(false);
  const [editRentError, setEditRentError] = useState('');
  const [limitError, setLimitError] = useState({});
  const sectionsRevamp = dealFormVRevamp(dealData, userData);

  useEffect(() => {
    let newFields = {};
    sectionsRevamp?.forEach((section) => {
      section.fields?.forEach((field) => {
        // Setting the default values of the form fields and the slider
        const valueToTwoDecimals = Math.round((dealData[field.name] + Number.EPSILON) * 100) / 100;
        const sliderValue = field.slider ? valueToTwoDecimals : 0;
        newFields[field.name] = { value: valueToTwoDecimals, sliderValue: sliderValue };
      });
    });

    setFormFields(newFields);
  }, [dealData]);

  const updateStateDebounced = useCallback(
    debounce((updatedForm) => updateDealDataState(updatedForm), TIME_DELAY_FORM_UPDATE),
    [dealData],
  );

  const updateFormFields = (field, newValue) => {
    const updatedForm = { ...formFields };
    const floatValueAsString = formatNumberToString(newValue);

    // If anything other than a floating point number is entered (with a max of 2 decimal places),
    // skip setting the input fields. This also ensures proper display of the number to the user.
    if (validFloatingPointNumber.test(floatValueAsString)) {
      updatedForm[field.name].value = floatValueAsString;
      updatedForm[field.name].sliderValue = floatValueAsString;

      let errorNote = { ...limitError };

      // Make sure the dependent fields are never greater than their limits
      if (field.name === 'monthlyRent') {
        if (formFields.monthlyNetRent.value > floatValueAsString) {
          updatedForm.monthlyNetRent.value = floatValueAsString;
        }
        errorNote = { ...errorNote, monthlyNetRent: '' };
      }
      if (field.name === 'purchasePrice') {
        if (formFields.cashFunding.value > floatValueAsString) {
          updatedForm.cashFunding.value = floatValueAsString;
        } else {
          errorNote = { ...errorNote, cashFunding: '' };
        }
      }

      // Let's make sure that if the user types a value outside of the
      // accepted range, we don't update the sections and we notify them.

      if (
        floatValueAsString > Math.round(field.ranges?.max * 100) / 100 ||
        floatValueAsString < Math.round(field.ranges?.min * 100) / 100
      ) {
        let errMsg = 'This value must be inside the minimum and maximum ranges.';

        if (field.name === 'monthlyNetRent') {
          errMsg = "This value can't be greater than Gross Rent";
        }

        errorNote = {
          ...errorNote,
          [field.name]: errMsg,
        };
        setLimitError(errorNote);
        return;
      } else {
        errorNote = { ...errorNote, [field.name]: '' };
        setLimitError(errorNote);
      }

      setFormFields(updatedForm);
      updateStateDebounced(updatedForm);
    }
  };

  // This function mimics the current Redux implementation of `onFieldChange`
  const updateDealDataState = (updatedForm) => {
    let dealToUpdate = { ...dealData };
    for (let field in updatedForm) {
      dealToUpdate = { ...dealToUpdate, [field]: Number(updatedForm[field].value) };

      if (
        !dealData.staticFields.includes(field) &&
        fieldIsOverridable(sectionsRevamp, field) &&
        dealToUpdate[field] !== dealData[field]
      ) {
        dealToUpdate = { ...dealToUpdate, staticFields: [...dealToUpdate.staticFields, field] };
      }
    }
    const calculatedValues = runFormulas(dealToUpdate, userData);
    setDealData(calculatedValues);
  };

  // This function mimics the current redux implementation of `fieldIsOverridable`
  const fieldIsOverridable = (sections, changedField) => {
    let found;
    // Find the changedField within the available fields
    // and verify if it's overridable or not
    for (let i in sections) {
      found = sections[i].fields.find((field) => {
        return field.name === changedField;
      });
      if (found) {
        break;
      }
    }
    return found?.overridable ?? false;
  };

  const onEdit = async (inputFields) => {
    let { upperMonthlyRent, lowerMonthlyRent } = inputFields;
    upperMonthlyRent = Number(upperMonthlyRent);
    lowerMonthlyRent = Number(lowerMonthlyRent);

    if (lowerMonthlyRent > upperMonthlyRent) {
      setEditRentError("Lower Rent can't be greater than Higher Rent");
      return;
    }

    if (dealData.monthlyRent > upperMonthlyRent) {
      dealData.monthlyRent = upperMonthlyRent;
    } else if (dealData.monthlyRent < lowerMonthlyRent) {
      dealData.monthlyRent = lowerMonthlyRent;
    }

    let dealToUpdate = {
      ...dealData,
      lowerMonthlyRent,
      upperMonthlyRent,
    };

    const calculatedValues = runFormulas(dealToUpdate, userData);
    setDealData(calculatedValues);
    setOpenEdit(false);
    onSuccess(setSnackbarState, 'The rent ranges have been updated.');
  };

  return (
    <>
      <Form>
        {sectionsRevamp?.map(({ title, fields }, idx) => (
          <div key={idx} style={{ marginBottom: '40px' }}>
            <Header5 style={{ margin: '0 0 8px 0' }}>{title}</Header5>
            {fields.map((field, idx) => {
              const { name, percent, label, tooltip, slider } = field;
              const value = formFields[name]?.value;
              const error = limitError[name];
              const displayValueAsCurrency = numberWithCommas(value);

              return (
                <div key={idx}>
                  <TextFieldContainer>
                    <TextField
                      style={{
                        marginTop: '16px',
                        width: percent ? '80%' : '100%',
                      }}
                      key={label}
                      fullWidth={!percent}
                      label={label}
                      margin="dense"
                      onChange={(event) => updateFormFields(field, event.target.value)}
                      error={!!error}
                      helperText={error}
                      type="text"
                      value={displayValueAsCurrency}
                      variant="outlined"
                      InputProps={{
                        endAdornment: tooltip && (
                          <InfoAdornment Tooltip={tooltip} dealData={dealData} />
                        ),
                        startAdornment: <span>$&nbsp;</span>,
                      }}
                    />
                    {percent && (
                      <Percent
                        style={{
                          margin: '8px 4px 0px 4px',
                          width: '20%',
                        }}
                        percent={percent}
                      />
                    )}
                  </TextFieldContainer>
                  {slider !== undefined && (
                    <>
                      <StyledSlider
                        value={Number(formFields[name]?.sliderValue) ?? 100}
                        onChange={(event, value) => updateFormFields(field, value)}
                        aria-labelledby="input-slider"
                        min={slider.min ?? 0}
                        max={slider.max ?? 15000}
                      />
                      <SliderMarks>
                        <div>{slider.minLabel}</div>
                        <MaxMark>
                          <div>{slider.maxLabel}</div>
                        </MaxMark>
                      </SliderMarks>
                    </>
                  )}
                  {field.name === 'monthlyNetRent' && (
                    <>
                      <RentReport>
                        <div>
                          <Detail
                            title="Rent Total Holdback"
                            value={dealData.rentHoldbackAmount * dealData.rentHoldbackMonths}
                          />
                        </div>
                        <div>
                          <Detail title="Prorated Rent" value={dealData.proratedRent} />
                        </div>
                      </RentReport>
                      <ActionButton
                        value="Edit Rent Ranges"
                        handleClick={() => setOpenEdit(true)}
                      />
                    </>
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </Form>

      <MultifieldModal
        dealData={dealData}
        error={editRentError}
        setError={setEditRentError}
        fields={editFields}
        open={openEdit}
        setOpen={setOpenEdit}
        title="Edit Rental Ranges"
        submitLabel="Save"
        onSubmit={onEdit}
      />
    </>
  );
};

export default UwDefaultView;
