import React from 'react';
import { Container } from './styles';
import flag from '../../../images/flag.svg';

const PrimaryFlag = () => {
  return (
    <Container>
      <div style={{ marginRight: '4px' }}>
        <img src={flag} alt="flag icon" />
      </div>
      Primary
    </Container>
  );
};

export default PrimaryFlag;
