import React from 'react';
import './round-button.css';

const EkRoundButton = ({ href, title, icon, secondary }) => (
  <div className={secondary ? 'containerSecondary' : 'container'}>
    <a href={href} className="round-button">
      <div className="text">{title}</div>
      <img className="icon" alt={title} src={icon} />
    </a>
  </div>
);

export default EkRoundButton;
