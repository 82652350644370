import { Container, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  CustomHeader4,
  GridSnapshotDropdown,
  FlagContainer,
  Flag,
  StyledSpan,
  RoundButton,
  SnapshotButtonContainer,
  StyledTooltip,
} from './styles';
import { IconButton } from '@easyknockorg/ek-ui-components';
import CustomersDetails from '../../Molecules/CustomerDetails';
import AlertBanner from '../../Atoms/AlertBanner';
import CreateButton from '../../Atoms/CreateButton';
import Actions from '../../Header/components/Actions';
import { useHistory } from 'react-router-dom';
import { Icon } from '@easyknockorg/ek-ui-components';
import Dropdown from '../Dropdown/index';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Breakpoint } from '@easyknockorg/ek-ui-components';
import ReloadIconComponent from '../../ReloadIcon';
import { ALTERNATE_OPTION_ESTIMATE_LABEL, UNDERWRITING_ESTIMATE_LABEL } from '../../../constants';

const Header = ({
  dealData,
  defaultDeal,
  noOffersAvailable,
  optionName,
  setDealData,
  userData,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [sendEmail, setSendEmail] = useState(false);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const [snapshotValue, setSnapshotValue] = useState('');

  const { dealId: parentDealId } = dealData?.snapshots?.slice(-1)[0] || {};
  const isSnapshot = !!dealData?.snapshotName;
  const isException =
    dealData.optionSelected === ALTERNATE_OPTION_ESTIMATE_LABEL ||
    dealData.optionSelected === UNDERWRITING_ESTIMATE_LABEL;
  const isVersionSupported = !!dealData.isVersionSupported;

  const history = useHistory();
  const mqueryRes = useMediaQuery(Breakpoint.lg);
  const [isDesktop, setIsDesktop] = useState(true);

  const fullAddress = `${defaultDeal?.address || ''}, ${defaultDeal?.city || ''}, ${
    defaultDeal?.state || ''
  } ${defaultDeal?.zip || ''} ${defaultDeal?.country || ''}`;
  const fullName = `${defaultDeal?.firstName} ${defaultDeal?.lastName}`;
  const email = defaultDeal?.email;
  const phone = defaultDeal?.phone;
  const stageName = dealData?.stageName;

  useEffect(() => {
    setIsDesktop(mqueryRes);
  }, [mqueryRes]);

  useEffect(() => {
    // This is to make sure we update the dropdown text when creating new snapshots
    const found = (dealData.snapshots || []).find((snapshot) => snapshot.active);
    if (found) {
      setSnapshotValue(found);
    }
  }, [dealData]);

  const handleClickDetails = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ open: false });
  };

  const handleRefresh = () => {
    history.push(`/salesforce/${dealData?.opportunityId}`);
  };

  return (
    <Container maxWidth="xl" style={{ borderBottom: '1.5px solid #f3f3f3', padding: '16px 40px' }}>
      <Grid container spacing={1} style={{ display: 'flex', alignItems: 'center' }}>
        <Grid item xs={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div data-test-id="customer-profile-data">
                <FlagContainer display={isDesktop ? 'none' : 'initial'}>
                  <Flag>{stageName}</Flag>
                </FlagContainer>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <CustomHeader4 onClick={handleClickDetails}>{fullName}</CustomHeader4>
                  <IconButton
                    icon="chevronDown"
                    color="#068A82"
                    size="14"
                    onClick={handleClickDetails}
                  />
                  <FlagContainer display={isDesktop ? 'initial' : 'none'}>
                    <Flag>{stageName}</Flag>
                  </FlagContainer>
                  <CustomersDetails
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    dealData={dealData}
                    fullAddress={fullAddress}
                    name={fullName}
                    email={email}
                    phone={phone}
                  />
                </div>
                <div>
                  <StyledSpan>{fullAddress}</StyledSpan>
                </div>
                <div>
                  <StyledSpan>{email}</StyledSpan>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid style={{ display: 'flex' }} container spacing={1}>
            <GridSnapshotDropdown item xs={12}>
              {isSnapshot && (
                <StyledTooltip title="Edit Default" arrow>
                  <RoundButton
                    size="40"
                    background="none"
                    border="2px solid #068A82"
                    onClick={() => {
                      setSnapshotValue(dealData?.snapshots?.slice(-1)[0]);
                      history.push(`/deal-calculator-revamp/${parentDealId}`);
                    }}
                  >
                    <Icon icon="pen" color="#068A82" size="18" weight="bold" />
                  </RoundButton>
                </StyledTooltip>
              )}

              <Dropdown dealData={dealData} value={snapshotValue} setValue={setSnapshotValue} />
              <ReloadIconComponent
                onClick={handleRefresh}
                style={{
                  marginLeft: '16px',
                  height: '20px',
                  width: '20px',
                }}
              />
            </GridSnapshotDropdown>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <SnapshotButtonContainer data-test-id="snapshot-button">
            {isSnapshot &&
              isVersionSupported &&
              (!isException || (isException && dealData.underwritingStatus === 'Approved')) && (
                <StyledTooltip title="Email Estimate" arrow>
                  <RoundButton shadow={true} onClick={() => setSendEmail(true)}>
                    <Icon icon="mail" color="#454B4E" size="18" />
                  </RoundButton>
                </StyledTooltip>
              )}
            <CreateButton
              dealData={dealData}
              optionName={optionName}
              sendEmail={sendEmail}
              setDealData={setDealData}
              setSnackbarState={setSnackbarState}
              setSendEmail={setSendEmail}
              userData={userData}
              noOffersAvailable={noOffersAvailable}
            />
            <Actions
              passedDealData={dealData}
              setDealData={setDealData}
              userData={userData}
              setSnackbarState={setSnackbarState}
            />
          </SnapshotButtonContainer>
        </Grid>
      </Grid>
      <AlertBanner
        handleClose={handleCloseSnackbar}
        title={snackbarState.title}
        message={snackbarState.message}
        open={snackbarState.open}
        severity={snackbarState.severity}
      />
    </Container>
  );
};

export default Header;
