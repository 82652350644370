import _ from 'lodash';
import { Header5 } from '@easyknockorg/ek-ui-components';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import React, { useState, useEffect } from 'react';
import { DocumentType } from '../../../utils';
import { onError, onSuccess, onWarning } from '../../../utils/snackbar';
import {
  DealStages,
  SalesExceptionRequestReasons,
  NoOptionsReason,
  UnderwritingStatusEnum,
} from '../../../utils/constansts';
import { SnapshotButton } from '../../Organisms/Header/styles';
import { updateDealOnDB, getDealCreationMsg } from '../../../utils';
import { UserRole } from '../../../formulas/dto/userDto';
import apiClient from '../../../utils/apiClient';
import FormRadio from '../../Molecules/FormRadio';
import NewDocumentModal from '../../Organisms/NewDocumentModal';
import TextFieldModal from '../../../pages/DealCalculator/components/TextFieldModal';
import { documentModalInfo } from '../../../utils';
import { LoadingSpinner } from '../LoadingSpinner';
import { useDealContext } from '../../../contexts/DealContext';
import { StyledTooltip } from './styles';
import { ALTERNATE_OPTION_ESTIMATE_LABEL, UNDERWRITING_ESTIMATE_LABEL } from '../../../constants';

const CreateButton = ({
  dealData,
  optionName,
  setDealData,
  setSnackbarState,
  sendEmail,
  setSendEmail,
  userData,
  noOffersAvailable,
}) => {
  /**
   * These are the stage names in order:
   * Discovery, Proposal, Application Review, LOI Sent, Processing, Processing Post Review, PA Sent ..."
   */
  const [createDealOnSF, setCreateDealOnSF] = useState(false);
  const [emailAddr, setEmailAddr] = useState('');
  const [buttonLabel, setButtonLabel] = useState('');
  const [nsName, setNsName] = useState('');
  const [nsOpen, setNsOpen] = useState(false);
  const [createLOI, setCreateLOI] = useState(false);
  const [createPA, setCreatePA] = useState(false);
  const [createLA, setCreateLA] = useState(false);
  const [exceptionModalOpen, setExceptionModalOpen] = useState(false);
  const [isRequestApproval, setRequestApproval] = useState(false);
  const [underwritingStatus, setUnderwritingStatus] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [defaultDeal, setDefaultDeal] = useState({});
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const { primaryDeal, defaultWithPrimary } = useDealContext();
  const [toolTipTitle, setToolTipTitle] = useState('');
  const { setShouldRefresh, setFinishedUwRecheck } = useDealContext();

  const history = useHistory();
  const isSnapshot = !!dealData?.snapshotName;
  const isException =
    dealData.optionSelected === ALTERNATE_OPTION_ESTIMATE_LABEL ||
    dealData.optionSelected === UNDERWRITING_ESTIMATE_LABEL;

  // Main useEffect for Controlling Button Label / Checking on Clear to Close Status. TO DO: Seperate and Clean up useEffects.
  useEffect(() => {
    if (userData?.role === UserRole.Underwriting) {
      if (!isSnapshot) {
        setButtonLabel('Snapshot');
        if (!primaryDeal) setDisabled(true);
        else setDisabled(false);
      } else {
        setToolTipTitle('');
        setButtonLabel('Create Deal');
      }
    } else if (Object.keys(dealData).length > 0) {
      if (!isSnapshot) {
        //Ability To Create Snapshot
        setButtonLabel('Snapshot');
        setRequestApproval(false);
      } else if (isSnapshot && !dealData.salesforceDealId && isException) {
        //Ability To Request an Exception
        setButtonLabel('Request Review');
      } else if (
        isSnapshot &&
        !dealData.salesforceDealId &&
        (DealStages[defaultDeal.stageName] != DealStages['Processing Post Review'] ||
          DealStages[dealData.stageName] != DealStages['Processing Post Review'])
      ) {
        setToolTipTitle('');
        setButtonLabel('Create Deal');
      } else if (
        isSnapshot &&
        dealData.salesforceDealId &&
        (DealStages[defaultDeal.stageName] <= DealStages.Processing ||
          DealStages[dealData.stageName] <= DealStages.Processing)
      ) {
        //Ability To Send Loi
        setToolTipTitle('');
        setButtonLabel('Create LOI');
        if (dealData.underwritingStatus !== UnderwritingStatusEnum.Approved) {
          setDisabled(true);
        }
        if (!dealData.estimateEmailed) {
          setDisabled(true);
          setToolTipTitle('Please send the estimate to the client.');
        }
        if (dealData.loiSent) {
          setDisabled(true);
        }
      } else if (
        dealData.underwritingStatus !== UnderwritingStatusEnum.Approved &&
        dealData.pddCompletedDate !== null &&
        defaultDeal.stageName === 'Processing Post Review'
      ) {
        // Request Approval of PA
        setButtonLabel('Request PA');
      } else if (
        dealData.pddCompletedDate !== null &&
        dealData.underwritingStatus === UnderwritingStatusEnum.Approved &&
        (defaultDeal.stageName === 'Processing Post Review' || defaultDeal.stageName === 'PA Sent')
      ) {
        // Ability to Create PA
        setButtonLabel('Create PA');
        if (defaultDeal.stageName == 'PA Sent') {
          setDisabled(true);
        }
        if (dealData.paSent) {
          setDisabled(true);
        }
      } else if (DealStages[dealData?.stageName] >= DealStages['Pre-Close']) {
        setButtonLabel('Create Lease');
        if (dealData.laSent) {
          setDisabled(true);
        }
      } else if (defaultDeal?.stageName) {
        //To Do Remvoe After MVP
        console.log('defaultDeal: ', defaultDeal);
        console.log('dealData: ', dealData);
        setButtonLabel('Error');
        setDisabled(true);
      } else {
        setButtonLabel(<LoadingSpinner />);
        setDisabled(true);
      }

      // User will need to Request PA Approval to continue
      if (
        !isException &&
        dealData.isPrimaryDeal &&
        dealData.pddCompletedDate !== null &&
        (dealData.stageName === 'Processing Post Review' || dealData.stageName == 'PA Sent')
      ) {
        setRequestApproval(true);
      }
    }
    // Check to see if default deal is Pre-Close or greater, if so update snapshot stage
    if (
      dealData?.salesforceDealId &&
      DealStages[defaultDeal?.stageName] >= DealStages['Pre-Close'] &&
      DealStages[dealData?.stageName] < DealStages[defaultDeal?.stageName] &&
      dealData?.isPrimaryDeal
    ) {
      let tempDealData = { ...dealData };
      tempDealData.stageName = defaultDeal.stageName;

      setDealData(tempDealData);
      updateDealOnDB(tempDealData);
    }
  }, [dealData, defaultDeal, sendEmail]);

  //getDefault Deal
  //TO DO: Move this into Context
  useEffect(() => {
    if (dealData?.opportunityId) {
      const getDefaultDeal = async (opportunityId) => {
        const allDeals = await apiClient.deal.getDealsByOpportunityId(opportunityId);
        if (allDeals) {
          const defaultDeal = allDeals?.filter((deal) => !deal?.snapshotName).shift();
          setDefaultDeal(defaultDeal);
        }
      };
      getDefaultDeal(dealData?.opportunityId);
    }
  }, [dealData]);

  //Snackbar Alert
  useEffect(async () => {
    if (isRequestApproval && underwritingStatus === UnderwritingStatusEnum.Approved) {
      onSuccess(
        setSnackbarState,
        'Your approval request has been approved. You can now create a Purchase Agreement.',
      );
    }
  }, [underwritingStatus, isRequestApproval]);

  //Sets Disabled Status / Rechecks uw status in sf
  //To Do: Seperate Logic (recheck uw status in context?)
  useEffect(async () => {
    if (createDealOnSF && dealData?.estimateEmailed) {
      // Create deal on SF and update the date of the last email sent
      await handleCreateDeal();
      if (dealData.salesforceDealId) {
        await apiClient.salesforce.patch(dealData.salesforceDealId, {
          Last_Emailed__c: new Date(),
          Deal_Status__c: 'Proposal',
        });
        await apiClient.deal.update(dealData.id, {
          estimateEmailed: true,
        });
      }
    }
    if (
      isException &&
      dealData.salesforceDealId &&
      dealData.underwritingStatus !== UnderwritingStatusEnum.Approved
    ) {
      setDisabled(true);
    } else if (
      dealData.underwritingStatus &&
      dealData.underwritingStatus !== UnderwritingStatusEnum.Approved &&
      isRequestApproval
    ) {
      setDisabled(true);
    } else if (!dealData.isVersionSupported) {
      setDisabled(true);
    } else if (
      dealData.requestApprovalSent &&
      dealData.underwritingStatus !== UnderwritingStatusEnum.Approved &&
      dealData.pddCompletedDate !== null &&
      dealData.stageName === 'Processing Post Review'
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    // Force a recheck of underwriting status and ek entity on page load if we are in a snapshot
    if (dealData.salesforceDealId) {
      const fetchedDeal = await apiClient.salesforce.getDeal(dealData.salesforceDealId);
      if (
        (dealData.underwritingStatus !== fetchedDeal.underwritingStatus &&
          fetchedDeal.underwritingStatus != null) ||
        (dealData.ekEntity !== fetchedDeal.ekEntity && fetchedDeal.ekEntity != null)
      ) {
        let tempDealData = { ...dealData };
        tempDealData.salesforceDealId = fetchedDeal.salesforceDealId;
        tempDealData.dealPortalOutcome = fetchedDeal.dealPortalOutcome;
        tempDealData.underwritingStatus = fetchedDeal.underwritingStatus;
        tempDealData.underwritingStatusType = fetchedDeal.underwritingStatusType;
        tempDealData.ekEntity = fetchedDeal.ekEntity;

        setDealData(tempDealData);
        updateDealOnDB(tempDealData);
        setUnderwritingStatus(fetchedDeal.underwritingStatus);
      } else if (dealData.markedPrimaryBy && fetchedDeal.markedPrimaryBy == null) {
        // If this happened is because we hit a race condition, let's update the deal with the latest correct info
        updateDealOnDB(dealData);
      }
      setFinishedUwRecheck(true);
    }
  }, [dealData, createDealOnSF, isRequestApproval]);

  //If post pdd in default checks if post pdd snapshot has been made
  //To do:
  useEffect(() => {
    if (
      Object.keys(dealData).length !== 0 &&
      dealData?.pddCompletedDate !== null &&
      DealStages[defaultDeal?.stageName] >= DealStages['Processing Post Review']
    ) {
      const getProccessingSnapshots = async (opportunityId) => {
        const allDeals = await apiClient.deal.getDealsByOpportunityId(opportunityId);

        var hasPddSnapshot = allDeals.some(function (deal) {
          return (
            DealStages[deal.stageName] >= DealStages['Processing Post Review'] &&
            (deal.salesforceDealId || deal?.snapshotName)
          );
        });

        if (!hasPddSnapshot && userData?.role !== UserRole.Underwriting) {
          onWarning(
            setSnackbarState,
            'Due diligence is complete. Please create a new snapshot to request PA approval.',
          );
        }
      };
      getProccessingSnapshots(dealData?.opportunityId);
    }
  }, [dealData, defaultDeal]);

  const handleCreateDeal = async () => {
    if (userData == undefined || userData.role == undefined) {
      const msg =
        'Cannot create deal because User Data is undefined. Please logout and login to Deal Portal again.';
      onError(setSnackbarState, msg);
      console.error(`${msg} Received user data = `, userData);
      return;
    }

    if (
      userData?.role === UserRole.Underwriting &&
      (dealData?.dealOwnerRole === UserRole.Default || dealData?.salesforceDealId)
    ) {
      onWarning(
        setSnackbarState,
        "Can't create deal. A deal already exists, or this is a snapshot owned by Sales.",
      );
      return;
    }
    setLoadingSpinner(true);
    setDisabled(true);
    try {
      const createdDeal = await apiClient.salesforce.put(dealData.id);
      let dealToUpdate = {
        ...dealData,
        salesforceDealId: createdDeal.salesforceDealId,
        dealPortalOutcome: createdDeal.dealPortalOutcome,
        markedPrimaryBy: userData?.role,
        underwritingStatus: UnderwritingStatusEnum.New,
      };

      setDealData(dealToUpdate);
      updateDealOnDB(dealToUpdate);
      setLoadingSpinner(false);
      setDisabled(false);

      const msgObj = getDealCreationMsg(dealData, userData);
      onSuccess(setSnackbarState, msgObj.title, msgObj.message);
      setCreateDealOnSF(false);
      setShouldRefresh(true);
    } catch (err) {
      onError(setSnackbarState, `Error creating deal: ${err}`);
      console.error(`Error creating deal: ${err}`);
      setLoadingSpinner(false);
    }
  };

  const handleCreateSnapshot = () => {
    setNsOpen(true);
  };

  const handleRequestApproval = async () => {
    try {
      setLoadingSpinner(true);
      setDisabled(true);

      const createdDeal = await apiClient.salesforce.put(dealData.id);
      let tempDealData = { ...dealData };
      tempDealData.salesforceDealId = createdDeal.salesforceDealId;
      tempDealData.dealPortalOutcome = createdDeal.dealPortalOutcome;
      tempDealData.underwritingStatus = UnderwritingStatusEnum.Unassigned;
      tempDealData.requestApprovalSent = true;

      setDealData(tempDealData);
      updateDealOnDB(tempDealData);

      setLoadingSpinner(false);
      setDisabled(false);
      setCreateDealOnSF(true);
      onSuccess(setSnackbarState, 'Your approval request has been submitted.');
      setShouldRefresh(true);
    } catch (err) {
      console.error(`Error creating new snapshot: ${err}`);
    }
  };

  const handleCreateDocument = (docType, setCreateDoc) => {
    if (dealData[docType.docSentField]) {
      onWarning(setSnackbarState, `A ${docType.type} has already been created for this deal.`);
    } else {
      setCreateDoc(true);
    }
  };

  const onNewSnapshotSubmit = async () => {
    if (userData == undefined || userData.role == undefined || userData.email == undefined) {
      const msg =
        'Cannot create snapshot because User Data is undefined. Please logout and login to Deal Portal again.';
      onError(setSnackbarState, msg);
      console.error(`${msg} Received user data = `, userData);
      return;
    }

    if (!nsName) {
      const msg = 'Cannot create snapshot because no snapshot name was found';
      onError(setSnackbarState, msg);
      console.error(`${msg} Received user data = `, userData);
      return;
    }
    const dealToCreate =
      userData?.role !== UserRole.Underwriting ? { ...dealData } : { ...defaultWithPrimary };
    const snapshot = await apiClient.deal
      .post({
        ...dealToCreate,
        snapshotName: nsName,
        salesforceDealId: null,
        optionSelected:
          userData?.role !== UserRole.Underwriting ? optionName : UNDERWRITING_ESTIMATE_LABEL,
        dealOwnerRole: userData?.role,
        createdBy: userData?.email,
      })
      .catch((e) => console.error(`Error creating new snapshot: ${e}`));
    if (snapshot && snapshot.id) {
      if (optionName === ALTERNATE_OPTION_ESTIMATE_LABEL) {
        onSuccess(
          setSnackbarState,
          'Your snapshot has been created. You can now request an alternate option.',
          'Before creating the request, please confirm your customer’s desired cash and rent are correct.',
        );
      } else if (userData?.role === UserRole.Underwriting) {
        onSuccess(
          setSnackbarState,
          'You can now email the estimate to your client or create a deal.',
        );
      } else {
        onSuccess(
          setSnackbarState,
          'Your snapshot has been created',
          'You can now email the estimate to your client or create a deal.',
        );
      }
      history.push(`/deal-calculator-revamp/${dealData.id}/snapshot/${snapshot.id}`);
    }
    setRequestApproval(false);
  };

  const openExceptionModal = () => {
    setExceptionModalOpen(true);
  };

  const onExceptionRequest = async (requestReason, setLoading) => {
    let modifiableDealData = {
      ...dealData,
      salesExceptionRequestReason: requestReason,
      underwritingStatus: UnderwritingStatusEnum.New,
    };
    try {
      await apiClient.deal.updateDealFields(modifiableDealData.id, modifiableDealData);
    } catch (error) {
      console.log('Failed to Update Deal on Exception Request: ', error);
    }

    setLoading(true);
    const createdDeal = await apiClient.salesforce.put(dealData.id);
    createdDeal.underwritingStatus = UnderwritingStatusEnum.New;
    createdDeal.snapshots = dealData.snapshots;

    setDealData(createdDeal);
    setLoading(false);
    setExceptionModalOpen(false);
    setSnackbarState({
      open: true,
      title:
        'Your exception has been sent to Underwriting. You will be notified when there has been a decision.',
      severity: 'success',
    });
    setShouldRefresh(true);
  };

  const onSendEmail = async () => {
    const payload = {
      toAddresses: emailAddr.replace(' ', '').split(','),
    };
    try {
      await apiClient.estimate.post(dealData.id, payload);
      const dealToUpdate = { ...dealData, estimateEmailed: true };
      setDealData(dealToUpdate);
      updateDealOnDB(dealToUpdate);

      if (dealData.salesforceDealId) {
        // Salesforce Deal already created, just display alert
        onSuccess(setSnackbarState, 'The estimate has been sent.');
        await apiClient.salesforce.patch(dealData.salesforceDealId, {
          Last_Emailed__c: new Date(),
          Deal_Status__c: 'Proposal',
        });
        await apiClient.deal.patch(dealData.id, {
          estimateEmailed: true,
        });
      } else {
        // Create deal on Salesforce and then display alert
        setCreateDealOnSF(true);
      }

      if (dealData.qeId) {
        try {
          await apiClient.estimate.sendEstimateToW2EK(dealData.qeId, {
            dealId: dealData.id,
            estimateLink: `${process.env.REACT_APP_ESTIMATE_URL}?dealId=${dealData.uuid}`,
          });
        } catch (err) {
          console.error('Could not send estimate to W2EK: ', err);
        }
      }
    } catch (e) {
      onError(setSnackbarState, `Error sending estimate email: ${e}`);
    }
  };
  // Filter exception reasons in case of no offers are available
  const exceptionReasons = noOffersAvailable ? NoOptionsReason : SalesExceptionRequestReasons;

  const documentModals = documentModalInfo(
    createLA,
    setCreateLA,
    createLOI,
    setCreateLOI,
    createPA,
    setCreatePA,
  );

  return (
    <>
      <StyledTooltip title={toolTipTitle} arrow>
        <span>
          <SnapshotButton
            startIcon={buttonLabel === 'Snapshot' && <AddIcon />}
            color="primary"
            onClick={
              buttonLabel === 'Snapshot'
                ? handleCreateSnapshot
                : buttonLabel === 'Request Review'
                ? openExceptionModal
                : buttonLabel === 'Create Deal'
                ? handleCreateDeal
                : buttonLabel === 'Create LOI'
                ? () => handleCreateDocument(DocumentType.LOI, setCreateLOI)
                : buttonLabel === 'Request PA'
                ? handleRequestApproval
                : buttonLabel === 'Create PA'
                ? () => handleCreateDocument(DocumentType.Purchase, setCreatePA)
                : buttonLabel === 'Create Lease'
                ? () => handleCreateDocument(DocumentType.Lease, setCreateLA)
                : null
            }
            size="large"
            variant="contained"
            disabled={disabled}
          >
            <Header5 style={{ margin: 0 }}>
              {loadingSpinner ? <LoadingSpinner /> : buttonLabel}
            </Header5>
          </SnapshotButton>
        </span>
      </StyledTooltip>
      <FormRadio
        dealData={dealData}
        items={exceptionReasons}
        open={exceptionModalOpen}
        onSubmit={onExceptionRequest}
        setOpen={setExceptionModalOpen}
        onClose={() => setExceptionModalOpen(false)}
        submitLabel="Request Review"
        subtitle="Select the review reason from the list."
        title="Request Review"
      />
      <TextFieldModal
        nsName={nsName}
        setNsName={setNsName}
        onSubmit={onNewSnapshotSubmit}
        nsOpen={nsOpen}
        setNsOpen={setNsOpen}
        dealData={dealData}
        submitLabel="Create Snapshot"
        subtitle="Create a snapshot to save the values at this time."
      />
      <TextFieldModal
        title={'Email Estimate'}
        subtitle="Emailing an estimate for the first time, will create a deal in SalesForce."
        label="Email"
        nsName={emailAddr}
        setNsName={setEmailAddr}
        onSubmit={onSendEmail}
        nsOpen={sendEmail}
        setNsOpen={setSendEmail}
        dealData={dealData}
        helperText="Use commas (,) to separate recipients."
      />

      {documentModals.map((document, index) => (
        <NewDocumentModal
          dealData={dealData}
          setDealData={setDealData}
          docType={document.docType}
          title={document.title}
          submitLabel={document.submitLabel}
          open={document.open}
          setOpen={document.setOpen}
          setSnackbarState={setSnackbarState}
          key={index}
        />
      ))}
    </>
  );
};

export default CreateButton;
