import React from 'react';
import accounting from 'accounting';
import Divider from '@material-ui/core/Divider';
import { Title, ValuesContainer, Label, Value } from '../../styles';
import { withLDProvider, useFlags } from 'launchdarkly-react-client-sdk';

const ClearCapitalBlock = ({ ccData }) => {
  const formatMoney = (amount) => accounting.formatMoney(amount, '$ ', 0);
  const { ccLowerPropertyValue, ccMarketPropertyValue, ccUpperPropertyValue } = ccData;
  const { hideClearCapitalAvm } = useFlags();
  if (hideClearCapitalAvm) {
    return (
      <>
        <Divider orientation="vertical" flexItem style={{ margin: '0 16px' }} />
        <div>
          <Title>CC Home Value</Title>
          <ValuesContainer>
            <Label>High</Label> <Value>{formatMoney(ccUpperPropertyValue)}</Value>
          </ValuesContainer>
          <ValuesContainer>
            <Label>Median</Label> <Value>{formatMoney(ccMarketPropertyValue)}</Value>
          </ValuesContainer>
          <ValuesContainer>
            <Label>Low</Label> <Value>{formatMoney(ccLowerPropertyValue)}</Value>
          </ValuesContainer>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID,
  options: {
    bootstrap: 'localStorage',
  },
})(ClearCapitalBlock);
