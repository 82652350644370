import React, { useState } from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import reLoadIcon from './assets/reloadIcon.svg';

const ReloadIconComponent = ({ style, onClick }) => {
  const [rotate, setRotate] = useState(0);

  const handleClick = (e) => {
    e.stopPropagation();
    setRotate(rotate + 360);
    if (onClick) {
      onClick();
    }
  };
  return (
    <img
      onClick={handleClick}
      style={{
        cursor: 'pointer',
        transform: `rotate(-${rotate}deg)`,
        transition: 'transform .6s ease-in-out',
        ...style,
      }}
      src={reLoadIcon}
      alt="Reload"
      data-test-id="reload-button"
    />
  );
};
ReloadIconComponent.propTypes = {
  style: stylePropType,
  onClick: PropTypes.func.isRequired,
};

ReloadIconComponent.defaultProps = {
  style: {},
};

export default ReloadIconComponent;
