import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import apiClient from '../utils/apiClient';
import { runFormulas, getDealWarnings } from '../formulas/services/formulas';
import { onError, onWarning, onSuccess } from '../utils/snackbar';
import { dealHasHouseCanaryData } from '../utils';
import { UserRole } from '../formulas/dto/userDto';
import { automaticSfRefresh } from '../utils';

export const DealContext = createContext({});

export const useDealContext = () => useContext(DealContext);

// Initial revamp page
export const DealProvider = ({ children }) => {
  const getUserData = () => {
    let userData;
    try {
      userData = JSON.parse(localStorage.getItem('userData'));
    } catch (err) {
      console.error('Could not get user data from local storage: ', err);
      userData = {};
    }
    return userData;
  };

  const history = useHistory();
  const location = useLocation();
  const user = getUserData();
  const [dealData, setDealData] = useState({});
  const [defaultDeal, setDefaultDeal] = useState({});
  const [primaryDeal, setPrimaryDeal] = useState({});
  const [defaultWithPrimary, setDefaultWithPrimary] = useState({});
  const [loadingBar, setLoadingBar] = useState(true);
  const [optionName, setOptionName] = useState('Option 1 Estimate');
  const [showProgressBar, setShowProgressBar] = useState(loadingBar);
  const [userData, setUserData] = useState(user);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [finishedUwRecheck, setFinishedUwRecheck] = useState(false);
  const { search } = useLocation();
  const hasHC = dealHasHouseCanaryData(dealData);

  //Alternate Snapshot States
  const [alternateCF, setAlternateCF] = useState(dealData?.desiredCashout);
  const [alternateRent, setAlternateRent] = useState(dealData?.desiredRent);

  //offers states
  const [offers, setOffers] = useState({});
  const [offersLoading, setOffersLoading] = useState(true);

  const { dealId, snapshotId } = useParams();
  const dealToLoad = snapshotId || dealId;

  const updateSetDealData = (deal) => {
    setDealData(deal);
  };

  useEffect(async () => {
    try {
      const searchParams = queryString.parse(window.location.search);
      const options = {};
      if (searchParams?.id) {
        options.UserId = searchParams?.id;
      }
      if (searchParams.v) {
        options.VersionId = searchParams.v;
      }
      const deal = await apiClient.deal.get(dealToLoad, options);

      const warnings = getDealWarnings(deal, userData);
      const alertMessages = [];
      warnings.forEach((warning) => {
        if (warning.roleVisibility.includes(userData?.role)) {
          alertMessages.push(<li key={warning.message}>{warning.message}</li>);
        }
      });
      if (alertMessages.length > 0) {
        onWarning(setSnackbarState, <ul>{alertMessages}</ul>);
      }

      setOptionName(deal?.optionSelected);
      updateSetDealData(deal);
      const allDeals = await apiClient.deal.getDealsByOpportunityId(deal?.opportunityId);
      if (allDeals) {
        const defaultDeal = allDeals?.filter((deal) => !deal?.snapshotName).shift();
        setDefaultDeal(defaultDeal);
        const primaryDeal = allDeals?.filter((deal) => deal?.isPrimaryDeal).shift();
        setPrimaryDeal(primaryDeal);
        if (primaryDeal && userData?.role === UserRole.Underwriting) {
          const modifiedDefault = {
            ...defaultDeal,
            DealTypeId: primaryDeal.DealTypeId,
            // These are all of the fields that UW can edit
            purchasePrice: primaryDeal.purchasePrice,
            cashFunding: primaryDeal.cashFunding,
            monthlyRent: primaryDeal.monthlyRent,
            monthlyNetRent: primaryDeal.monthlyNetRent,
            realEstateTaxes: primaryDeal.realEstateTaxes,
            propertyInsurance: primaryDeal.propertyInsurance,
            floodInsurance: primaryDeal.floodInsurance,
            hoa: primaryDeal.hoa,
            closingCosts: primaryDeal.closingCosts,
            staticFields: [
              'purchasePrice',
              'cashFunding',
              'monthlyRent',
              'monthlyNetRent',
              'realEstateTaxes',
              'propertyInsurance',
              'floodInsurance',
              'hoa',
              'closingCosts',
            ],
          };
          // defaultWithPrimary will have everything the default has + fields from the primary that UW can edit, recalculated
          const calculatedDeal = runFormulas(modifiedDefault, userData);
          setDefaultWithPrimary(calculatedDeal);
        }
      }

      setLoadingBar(false);
    } catch (e) {
      let message = 'There was a problem getting the deal from the backend.';
      if (e.message === 'DealNotFound') {
        history.push('/404');
        message = `Deal with id=${dealToLoad} was not found.`;
      } else if (e.message === 'UnknownError') {
        const reloadPath = location.pathname.replace('-revamp', '');
        console.log(`Unknown Error, reloading page with the following route: ${reloadPath}`);
        history.push(reloadPath);
        history.go(0); // refresh
        message = `Unknown Error please log out and back in.`;
      } else if (e.length) {
        message = '';
        const codes = e?.map((err) => err.code);
        if (codes.indexOf('USER_ID_MISMATCH') > -1) {
          message += 'Invalid authorization id provided. ';
        }
        if (codes.indexOf('VERSION_ID_UNSUPPORTED') > -1) {
          message += 'Unsupported pricing version requested. ';
        }
        if (codes.indexOf('VERSION_EXPIRED') > -1) {
          message +=
            'Pricing version for deal has expired and is no longer supported. Please refresh to create a new deal with the latest version. ';
        }
      }

      onError(setSnackbarState, `Error: ${message}`);
      setShowProgressBar(false);
      setLoadingBar(false);
      console.error(e);
    }
  }, [dealToLoad]);

  useEffect(() => {
    try {
      if (Object.keys(userData ?? {}).length === 0) {
        const newUserData = getUserData();
        setUserData(newUserData);
      }
    } catch (e) {
      console.error('Could not get user data: ', e);
      setUserData({});
    }
  }, [userData]);

  useEffect(async () => {
    setOffersLoading(true);
    let receivedOffers = {};
    let rentInfo = {};
    const message = hasHC
      ? 'Please, request an exception. Make sure you update desired cash and desired rent in SalesForce.'
      : 'Please, request an alternate option. Make sure you update the customer’s desired cash funding and rent in Salesforce.';
    let title = '';
    if (userData?.role === UserRole.Underwriting) {
      rentInfo = { minRent: dealData.lowerMonthlyRent, maxRent: dealData.upperMonthlyRent };
    }
    try {
      // No need to get pricing for snapshots
      if (dealData?.id && !dealData.snapshotName) {
        receivedOffers = await apiClient.pricing.post(dealData?.id, rentInfo);
        setOffers(receivedOffers);

        if (Object.keys(receivedOffers).length <= 1 && userData?.role !== UserRole.Underwriting) {
          if (
            dealData.lowerPropertyValue == undefined ||
            dealData.upperPropertyValue == undefined
          ) {
            title = hasHC
              ? 'There are no pre-approved options for this deal.'
              : 'There is no home value House Canary data available.';
          }
          setSnackbarState({
            open: true,
            title,
            message,
            severity: 'error',
          });
        }
      }
    } catch (e) {
      console.error('Could not generate pricing offers: ', e);
      onError(setSnackbarState, `Error: ${e?.message ?? e}`);
    } finally {
      setOffersLoading(false);
    }

    setAlternateCF(dealData?.desiredCashout);
    setAlternateRent(dealData?.desiredRent);
  }, [dealData?.id, dealToLoad]);

  useEffect(() => {
    if (shouldRefresh && finishedUwRecheck) {
      setShouldRefresh(false);
      setFinishedUwRecheck(false);
      automaticSfRefresh(dealData, setDealData, search);
    } else if (finishedUwRecheck) {
      setFinishedUwRecheck(false);
    }
  }, [shouldRefresh, finishedUwRecheck]);

  const populateOptionSections = (cashFunding, rent, closingCosts, offerName) => {
    setOptionName(`${offerName} Estimate`);
    dealData.cashFunding = cashFunding;
    dealData.monthlyRent = rent;
    dealData.optionSelected = `${offerName} Estimate`;
    if (closingCosts) dealData.closingCosts = closingCosts;
    const calculatedDeal = runFormulas(dealData, userData);
    setDealData(calculatedDeal);
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ open: false });
  };

  const createAlternateSnapshot = async (userData, dealToCreate) => {
    const snapshot = await apiClient.deal
      .post(dealToCreate)
      .catch((e) => console.error(`Error creating new snapshot: ${e}`));
    if (snapshot && snapshot.id) {
      if (optionName === 'Exception Estimate') {
        onSuccess(
          setSnackbarState,
          'Your snapshot has been created. You can now request an exception.',
          'Before creating the request, please confirm your customer’s desired cash and rent are correct.',
        );
      } else if (userData?.role === UserRole.Underwriting) {
        onSuccess(
          setSnackbarState,
          'You can now email the estimate to your client or create a deal.',
        );
      } else {
        onSuccess(
          setSnackbarState,
          'Your snapshot has been created',
          'You can now email the estimate to your client or create a deal.',
        );
      }
      history.push(`/deal-calculator-revamp/${dealData.id}/snapshot/${snapshot.id}`);
    }
  };

  return (
    <DealContext.Provider
      value={{
        alternateCF,
        alternateRent,
        createAlternateSnapshot,
        dealData,
        dealId,
        dealToLoad,
        defaultDeal,
        defaultWithPrimary,
        setDefaultWithPrimary,
        handleCloseSnackbar,
        loadingBar,
        offers,
        offersLoading,
        optionName,
        populateOptionSections,
        primaryDeal,
        setAlternateCF,
        setAlternateRent,
        setPrimaryDeal,
        setDealData,
        setLoadingBar,
        setOffers,
        setOptionName,
        setShouldRefresh,
        setFinishedUwRecheck,
        setShowProgressBar,
        setSnackbarState,
        showProgressBar,
        snackbarState,
        snapshotId,
        updateSetDealData,
        userData,
      }}
    >
      {children}
    </DealContext.Provider>
  );
};
