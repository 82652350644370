import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
  Paper,
  IconButton,
  Collapse,
  Typography,
  Box,
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { theme } from '../DealCalculator/index';
import { useParams } from 'react-router-dom';
import apiClient from '../../utils/apiClient';
import { Body, Page, Title, ClientContact, StyledHeader3, StyledHeader2 } from './styles';

const OfferTCHeader = ({ children }) => (
  <TableCell style={{ paddingLeft: '0px', position: 'sticky', top: '0', backgroundColor: 'white' }}>
    {children}
  </TableCell>
);

const OfferTC = ({ children }) => (
  <TableCell style={{ padding: '6px', fontSize: '9pt' }}>{children}</TableCell>
);

const getRowStyle = (offer) => {
  if (offer.failedRules.length > 0) {
    return { backgroundColor: '#f0f0f5' };
  }
  if (offer.selectedOptions.length > 0) {
    return { backgroundColor: '#b3ffb3' };
  }
  return { backgroundColor: '#e6ffe6' };
};

const AllOffers = () => {
  const [allOffers, setAllOffers] = useState([]);
  const [validations, setValidations] = useState([]);
  const [optsSelected, setOptsSelected] = useState([]);
  const [dealData, setDealData] = useState({});
  const [isCollapsed, setIsCollapsed] = useState(true);

  const { dealId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (dealId) {
        try {
          setDealData(await apiClient.deal.get(dealId));
          const { offers, approvals, optionsSelected } = await apiClient.allOffers.post(dealId);
          setAllOffers(offers);
          setValidations(approvals);
          setOptsSelected(optionsSelected);
        } catch (err) {
          console.error(`${err} Error getting all offer details`);
        }
      }
    };
    fetchData();
  }, [dealId]);

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Page>
      <ThemeProvider theme={theme}>
        <Body>
          <Container maxWidth="xl" style={{ border: '1.5px solid #f3f3f3', padding: '14px' }}>
            <Paper
              elevation={24}
              variant="outlined"
              style={{
                padding: '20px',
                marginBottom: '15px',
                borderRadius: '8px',
              }}
            >
              <ClientContact>
                <StyledHeader3>
                  {dealData.firstName}&nbsp;{dealData.lastName}
                </StyledHeader3>
              </ClientContact>
              <Divider style={{ margin: '14px 0' }} />
              {dealData.snapshotName && (
                <Typography variant="h6" gutterBottom>
                  Snapshot: {dealData.snapshotName}
                </Typography>
              )}
              {!dealData.snapshotName && (
                <Typography variant="h6" gutterBottom>
                  Default Deal - Active Sheet
                </Typography>
              )}
            </Paper>

            <Typography variant="h5" gutterBottom>
              Generated Options
            </Typography>
            <Table style={{ textAlign: 'left', position: 'relative' }}>
              <TableHead>
                <TableRow style={{ borderBottom: '5px solid #00DBB1' }}>
                  <OfferTCHeader>
                    <Title>CashFunding</Title>
                  </OfferTCHeader>
                  <OfferTCHeader>
                    <Title>CFRate</Title>
                  </OfferTCHeader>
                  <OfferTCHeader>
                    <Title>Rent</Title>
                  </OfferTCHeader>
                  <OfferTCHeader>
                    <Title>CashRelease</Title>
                  </OfferTCHeader>
                  <OfferTCHeader>
                    <Title>DSCR</Title>
                  </OfferTCHeader>
                  <OfferTCHeader>
                    <Title>FinalDscr</Title>
                  </OfferTCHeader>
                  <OfferTCHeader>
                    <Title>CashFlow</Title>
                  </OfferTCHeader>
                  <OfferTCHeader>
                    <Title>NPV</Title>
                  </OfferTCHeader>
                  <OfferTCHeader>
                    <Title>NRY</Title>
                  </OfferTCHeader>
                  <OfferTCHeader>
                    <Title>SelfFund</Title>
                  </OfferTCHeader>
                  <OfferTCHeader>
                    <Title>Funding</Title>
                  </OfferTCHeader>
                  <OfferTCHeader>
                    <Title>FailedRules</Title>
                  </OfferTCHeader>
                  <OfferTCHeader>
                    <Title>Options</Title>
                  </OfferTCHeader>
                  <OfferTCHeader>
                    <Title>Debug</Title>
                  </OfferTCHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {optsSelected.map((offer, idx) => (
                  <>
                    <TableRow style={getRowStyle(offer)} key={offer.id}>
                      <OfferTC>{offer.cashFunding.toFixed(3)}</OfferTC>
                      <OfferTC>{offer.cashFundingRate.toFixed(2)}</OfferTC>
                      <OfferTC>{offer.rent.toFixed(2)}</OfferTC>
                      <OfferTC>{offer.cashRelease.toFixed(2)}</OfferTC>
                      <OfferTC>{offer.dscr.toFixed(3)}</OfferTC>
                      <OfferTC>{offer.finalDscr.toFixed(3)}</OfferTC>
                      <OfferTC>{offer.monthlyCashflow.toFixed(3)}</OfferTC>
                      <OfferTC>{offer.npv.toFixed(3)}</OfferTC>
                      <OfferTC>{offer.nry.toFixed(3)}</OfferTC>
                      <OfferTC>{offer.selfFund.toFixed(2)}</OfferTC>
                      <OfferTC>{offer.fundingSource}</OfferTC>
                      <OfferTC>{offer.failedRules.toString()}</OfferTC>
                      <OfferTC>{offer.selectedOptions.toString()}</OfferTC>
                      <OfferTC>{offer.offerDebugInfo.toString()}</OfferTC>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={15}>
                        <Typography variant="h6" gutterBottom>
                          DSCR approvalCriteria:
                          {JSON.stringify(validations[idx].DSCR.approvalCriteria)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
            <Box my={3} display="flex" alignItems="center">
              <IconButton onClick={handleCollapseToggle}>
                {isCollapsed ? <ExpandMore /> : <ExpandLess />}
                <Typography variant="button" style={{ marginLeft: 8 }}>
                  {isCollapsed ? 'Show All Offers' : 'Hide All Offers'}
                </Typography>
              </IconButton>
            </Box>
            <Collapse in={!isCollapsed}>
              <Table style={{ textAlign: 'left', position: 'relative' }}>
                <TableHead>
                  <TableRow style={{ borderBottom: '5px solid #00DBB1' }}>
                    <OfferTCHeader>
                      <Title>ID</Title>
                    </OfferTCHeader>
                    <OfferTCHeader>
                      <Title>CashFunding</Title>
                    </OfferTCHeader>
                    <OfferTCHeader>
                      <Title>CFRate</Title>
                    </OfferTCHeader>
                    <OfferTCHeader>
                      <Title>Rent</Title>
                    </OfferTCHeader>
                    <OfferTCHeader>
                      <Title>CashRelease</Title>
                    </OfferTCHeader>
                    <OfferTCHeader>
                      <Title>DSCR</Title>
                    </OfferTCHeader>
                    <OfferTCHeader>
                      <Title>FinalDscr</Title>
                    </OfferTCHeader>
                    <OfferTCHeader>
                      <Title>CashFlow</Title>
                    </OfferTCHeader>
                    <OfferTCHeader>
                      <Title>NPV</Title>
                    </OfferTCHeader>
                    <OfferTCHeader>
                      <Title>NRY</Title>
                    </OfferTCHeader>
                    <OfferTCHeader>
                      <Title>SelfFund</Title>
                    </OfferTCHeader>
                    <OfferTCHeader>
                      <Title>Funding</Title>
                    </OfferTCHeader>
                    <OfferTCHeader>
                      <Title>FailedRules</Title>
                    </OfferTCHeader>
                    <OfferTCHeader>
                      <Title>Options</Title>
                    </OfferTCHeader>
                    <OfferTCHeader>
                      <Title>Debug</Title>
                    </OfferTCHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allOffers.map((offer, idx) => (
                    <TableRow style={getRowStyle(offer)} key={offer.id}>
                      <OfferTC>{idx}</OfferTC>
                      <OfferTC>{offer.cashFunding.toFixed(3)}</OfferTC>
                      <OfferTC>{offer.cashFundingRate.toFixed(2)}</OfferTC>
                      <OfferTC>{offer.rent.toFixed(2)}</OfferTC>
                      <OfferTC>{offer.cashRelease.toFixed(2)}</OfferTC>
                      <OfferTC>{offer.dscr.toFixed(3)}</OfferTC>
                      <OfferTC>{offer.finalDscr.toFixed(3)}</OfferTC>
                      <OfferTC>{offer.monthlyCashflow.toFixed(3)}</OfferTC>
                      <OfferTC>{offer.npv.toFixed(3)}</OfferTC>
                      <OfferTC>{offer.nry.toFixed(3)}</OfferTC>
                      <OfferTC>{offer.selfFund.toFixed(2)}</OfferTC>
                      <OfferTC>{offer.fundingSource}</OfferTC>
                      <OfferTC>{offer.failedRules.toString()}</OfferTC>
                      <OfferTC>{offer.selectedOptions.toString()}</OfferTC>
                      <OfferTC>{offer.offerDebugInfo.toString()}</OfferTC>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </Container>
        </Body>
      </ThemeProvider>
    </Page>
  );
};

export default AllOffers;
