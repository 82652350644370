import _ from 'lodash';

const ALERT_ACTION = '@@deal-portal/ALERT_ACTION';

export const alertAction = (payload) => ({ type: ALERT_ACTION, payload });

const actionListeners = {
  [ALERT_ACTION]: (state, { payload }) => {
    return { ...state, ...payload };
  },
};

export default (state = {}, action) => {
  return _.get(actionListeners, action.type, _.identity)(state, action);
};
