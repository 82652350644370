var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { FinanceOptions } from '../../dto/pricing';
var configDefault = {
    COST_OF_CAPITAL: 10,
    COST_OF_EQUITY: 12,
    DSCR_MULTIPLIER: 1.15,
    DEFAULT_BORROWING_INTEREST_RATE: 5,
    DEFAULT_CASH_FUNDING_LIMIT_RATE: 85,
    DEFAULT_CLOSING_COST: 1.5,
    DEFAULT_DEAL_TYPE_ID: 1,
    DEFAULT_EK_PROCESSING_FEE: 4.99,
    DEFAULT_EK_B2B_PROCESSING_FEE: 3.75,
    DEFAULT_LANDLORD_EXPENSE_RATE: 10,
    DEFAULT_LANDLORD_INSURANCE_RATE: {
        LA: 0.65,
        FL: 0.57,
        OTHER: 0.35,
    },
    DEFAULT_MAX_CASH_FUNDING_LIMIT_RATE: 85,
    DEFAULT_MAX_CASH_FUNDING_LIMIT_RATE_AMERANT: 75,
    DEFAULT_MIN_CASH_FUNDING_LIMIT_RATE: 85,
    DEFAULT_RE_TAXES: {
        AL: 0.67,
        AR: 0.96,
        AZ: 0.46,
        CA: 1.04,
        CO: 0.58,
        CT: 1.96,
        DC: 0.82,
        DE: 0.68,
        FL: 1.19,
        GA: 1.12,
        HI: 0.25,
        ID: 0.56,
        IL: 2.25,
        IN: 1.08,
        IA: 2.29,
        KS: 1.48,
        KY: 0.98,
        LA: 0.96,
        MA: 1.16,
        MD: 1.16,
        ME: 1.19,
        MI: 1.84,
        MN: 1.13,
        MO: 1.19,
        MS: 1.3,
        NC: 0.79,
        ND: 1.28,
        NE: 1.67,
        NH: 1.58,
        NJ: 2.29,
        NM: 0.85,
        NV: 0.5,
        NY: 2.45,
        OH: 1.68,
        OK: 1.11,
        OR: 0.98,
        PA: 1.74,
        RI: 1.7,
        SC: 1.22,
        SD: 0.84,
        TN: 0.85,
        TX: 2.13,
        UT: 0.53,
        VA: 0.92,
        WA: 0.9,
        WI: 1.42,
        WV: 0.92,
        WY: 0.78,
        OTHER: 1,
    },
    DEFAULT_REALTOR_COST: 6,
    DEFAULT_SECURITY_DEPOSIT_FACTOR: 0,
    DEFAULT_TOTAL_COMISSION: 6,
    DEFAULT_TRANSFER_TAX: {
        AL: 0.15,
        AR: 0.33,
        AZ: 0,
        CA: 0.11,
        CO: 0.01,
        CT: 1.11,
        DC: 1.1,
        DE: 2,
        FL: 0.7,
        GA: 0.1,
        HI: 0.1,
        IA: 0.16,
        IL: 0.75,
        KS: 0.26,
        KY: 0.1,
        MA: 0.46,
        MD: 0.5,
        ME: 0.44,
        MI: 0.75,
        MN: 0.33,
        NC: 0.2,
        NE: 0.18,
        NH: 1.5,
        NJ: 0.35,
        NV: 0.18,
        NY: 1.43,
        OH: 0.1,
        OK: 0.75,
        PA: 1,
        RI: 0.28,
        SC: 0.38,
        SD: 0.1,
        TN: 0.37,
        VA: 0.15,
        VT: 1.25,
        WA: 1.28,
        WI: 0.3,
        WV: 0.34,
        OTHER: 0,
    },
    INSPECTION_APPRAISAL: 1250,
    LEGACY_SUPPORT_DAYS: 130,
    MAX_RENT_HOLDBACK: 85,
    MIN_HOME_PRICE: 100000,
    OPTION_PERIOD_INCREASE_YEAR_1: 0,
    OPTION_PERIOD_INCREASE_YEAR_2: 0,
    OPTION_PERIOD_INCREASE_YEAR_3: 0,
    OPTION_PERIOD_INCREASE_YEAR_4: 0,
    OPTION_PERIOD_INCREASE_YEAR_5: 0,
    REPAIR_HOLDBACK: 5000,
    WARRANTY: 860,
    pricing: {
        tvc: {
            criteria: {
                minCashFlow: {
                    approved: function (deal) {
                        return deal.monthlyRent * 0.15 * -1;
                    },
                    override: function (deal) {
                        return deal.monthlyRent * 0.15 * -1;
                    },
                },
                cashFundingRate: {
                    approved: function (deal) {
                        if (deal.purchasePrice < 600000) {
                            return 0.75;
                        }
                        else {
                            return 0.7;
                        }
                    },
                    override: function () { return 0.8; },
                },
                dscrSelfFundLimit: {
                    approved: function () { return 1; },
                    override: function () { return 1; },
                },
                dscr: {
                    approved: function () { return 1.15; },
                    override: function () { return 1; },
                },
                netRent: {
                    approved: function () { return 0; },
                    override: function () { return -0.1; },
                },
                cfMonth: {
                    approved: function () { return 48; },
                    override: function () { return 36; },
                },
                selfFundMax: {
                    approved: function () { return 0; },
                    override: function () { return 7000; },
                },
                npv: {
                    approved: function () { return 30000; },
                    override: function () { return 20000; },
                },
                minPurchasePrice: {
                    approved: function () { return 110000; },
                    override: function () { return 110000; },
                },
            },
            cashFunding: 70,
            cashFundingMin: 65,
            cashFundingMax: 75,
            costOfCapital: 10,
            dispositionMonth: 60,
            npvMonths: 48,
            originationFees: 1.75,
            originationFeesFixed: 964,
            dailyRates: {
                baseRate60: 7.75,
                baseRate65: 7.875,
                baseRate70: 8,
                baseRate75: 8.125,
                baseRate80: 8.25,
                interestOnlyAdjuster: 0.125,
                cashFundingLT150: 0.125,
                threeYearPP: 0.25,
                oneYearPP: 0.75,
            },
        },
        amerant: {
            criteria: {
                minCashFlow: {
                    approved: function (deal) {
                        return deal.monthlyRent * 0.15 * -1;
                    },
                    override: function (deal) {
                        return deal.monthlyRent * 0.15 * -1;
                    },
                },
                cashFundingRate: {
                    approved: function (deal) {
                        if (deal.purchasePrice < 600000) {
                            return 0.75;
                        }
                        else {
                            return 0.7;
                        }
                    },
                    override: function () { return 0.8; },
                },
                dscrSelfFundLimit: {
                    approved: function () { return 1; },
                    override: function () { return 1; },
                },
                dscr: {
                    approved: function () { return 1.15; },
                    override: function () { return 0.95; },
                },
                netRent: {
                    approved: function () { return 0; },
                    override: function () { return -0.1; },
                },
                cfMonth: {
                    approved: function () { return 48; },
                    override: function () { return 36; },
                },
                selfFundMax: {
                    approved: function () { return 0; },
                    override: function () { return 10000; },
                },
                npv: {
                    approved: function () { return 30000; },
                    override: function () { return 20000; },
                },
                minPurchasePrice: {
                    approved: function () { return 110000; },
                    override: function () { return 110000; },
                },
            },
            cashFunding: 70,
            cashFundingMin: 65,
            cashFundingMax: 75,
            costOfCapital: 7.5,
            dispositionMonth: 60,
            maxPurchasePrice: 720000,
            npvMonths: 48,
            originationFees: 1,
            originationFeesFixed: 750,
            dailyRates: {
                baseRate60: 7.75,
                baseRate65: 7.875,
                baseRate70: 8,
                baseRate75: 8.125,
                baseRate80: 8.25,
                interestOnlyAdjuster: 0.125,
                cashFundingLT150: 0.125,
                threeYearPP: 0.25,
                oneYearPP: 0.75,
            },
        },
    },
    agentFeeBreakdown: { acquisition: 0.25, disposition: 0.75 },
};
var configSellAndStay = __assign(__assign({}, configDefault), { COST_OF_CAPITAL: 10, DEFAULT_CASH_FUNDING_LIMIT_RATE: 70, DEFAULT_MAX_CASH_FUNDING_LIMIT_RATE: 75, DEFAULT_MIN_CASH_FUNDING_LIMIT_RATE: 65, MIN_NET_RENT_YIELD_PERCENT: 11.5, DEFAULT_EK_PROCESSING_FEE: 2.99, DEFAULT_EK_B2B_PROCESSING_FEE: 3.75, OPTION_PERIOD_INCREASE_YEAR_1: 4.25, OPTION_PERIOD_INCREASE_YEAR_2: 3.75, OPTION_PERIOD_INCREASE_YEAR_3: 3.25, OPTION_PERIOD_INCREASE_YEAR_4: 2.5, OPTION_PERIOD_INCREASE_YEAR_5: 2.5, pricing: {
        tvc: {
            criteria: {
                minCashFlow: {
                    approved: function (deal) {
                        return deal.monthlyRent * 0.075 * -1;
                    },
                    override: function (deal) {
                        return deal.monthlyRent * 0.075 * -1;
                    },
                },
                cashFundingRate: {
                    approved: function (deal) {
                        if (deal.purchasePrice < 600000) {
                            return 0.75;
                        }
                        else {
                            return 0.7;
                        }
                    },
                    override: function () { return 0.75; },
                },
                dscrSelfFundLimit: {
                    approved: function () { return 1; },
                    override: function () { return 1; },
                },
                dscr: {
                    approved: function (deal) {
                        if (deal.purchasePrice < 150000) {
                            return 1.2;
                        }
                        else if (deal.purchasePrice < 300000) {
                            return 1.1;
                        }
                        else if (deal.purchasePrice < 500000) {
                            return 1;
                        }
                        else {
                            return 0.95;
                        }
                    },
                    override: function () { return 0.95; },
                },
                netRent: {
                    approved: function () { return -Infinity; },
                    override: function () { return -Infinity; },
                },
                cfMonth: {
                    approved: function () { return 48; },
                    override: function () { return 36; },
                },
                selfFundMax: {
                    approved: function () { return 3000; },
                    override: function (deal) {
                        return Math.min(15000, 0.03 * deal.purchasePrice);
                    },
                },
                npv: {
                    approved: function (deal) {
                        return Math.max(Math.min(0.125 * deal.cashFunding, 30000), 15000);
                    },
                    override: function (deal) {
                        return Math.max(0.1 * deal.cashFunding, 15000);
                    },
                },
                minPurchasePrice: {
                    approved: function () { return 110000; },
                    override: function () { return 110000; },
                },
            },
            cashFunding: 70,
            cashFundingMin: 65,
            cashFundingMax: 75,
            costOfCapital: 7.5,
            dispositionMonth: 60,
            npvMonths: 48,
            originationFees: 1.25,
            originationFeesFixed: 964,
            dailyRates: {
                baseRate60: 7.75,
                baseRate65: 7.875,
                baseRate70: 8,
                baseRate75: 8.125,
                baseRate80: 8.25,
                interestOnlyAdjuster: 0.125,
                cashFundingLT150: 0.125,
                threeYearPP: 0.25,
                oneYearPP: 0.75,
            },
        },
        amerant: {
            criteria: {
                minCashFlow: {
                    approved: function (deal) {
                        return deal.monthlyRent * 0.075 * -1;
                    },
                    override: function (deal) {
                        return deal.monthlyRent * 0.075 * -1;
                    },
                },
                cashFundingRate: {
                    approved: function (deal) {
                        if (deal.purchasePrice < 600000) {
                            return 0.75;
                        }
                        else {
                            return 0.7;
                        }
                    },
                    override: function () { return 0.8; },
                },
                dscrSelfFundLimit: {
                    approved: function () { return 1; },
                    override: function () { return 1; },
                },
                dscr: {
                    approved: function (deal) {
                        if (deal.purchasePrice < 150000) {
                            return 1.2;
                        }
                        else if (deal.purchasePrice < 300000) {
                            return 1.1;
                        }
                        else if (deal.purchasePrice < 500000) {
                            return 1;
                        }
                        else {
                            return 0.95;
                        }
                    },
                    override: function () { return 0.95; },
                },
                netRent: {
                    approved: function () { return -Infinity; },
                    override: function () { return -Infinity; },
                },
                cfMonth: {
                    approved: function () { return 48; },
                    override: function () { return 36; },
                },
                selfFundMax: {
                    approved: function () { return 3000; },
                    override: function (deal) {
                        return Math.min(15000, 0.03 * deal.purchasePrice);
                    },
                },
                npv: {
                    approved: function (deal) {
                        return Math.max(Math.min(0.125 * deal.cashFunding, 30000), 15000);
                    },
                    override: function (deal) {
                        return Math.max(0.1 * deal.cashFunding, 15000);
                    },
                },
                minPurchasePrice: {
                    approved: function () { return 110000; },
                    override: function () { return 110000; },
                },
            },
            cashFunding: 70,
            cashFundingMin: 65,
            cashFundingMax: 75,
            costOfCapital: 7.5,
            dispositionMonth: 60,
            maxPurchasePrice: 720000,
            npvMonths: 48,
            originationFees: 1,
            originationFeesFixed: 750,
            dailyRates: {
                baseRate60: 7.75,
                baseRate65: 7.875,
                baseRate70: 8,
                baseRate75: 8.125,
                baseRate80: 8.25,
                interestOnlyAdjuster: 0.125,
                cashFundingLT150: 0.125,
                threeYearPP: 0.25,
                oneYearPP: 0.75,
            },
        },
    } });
var configMoveability = __assign(__assign({}, configDefault), { BACKEND_FEE_1: 1.0, BACKEND_FEE_2: 1.0, COST_OF_CAPITAL: 10.0, DEFAULT_CASH_FUNDING_LIMIT_RATE: 70, DEFAULT_MAX_CASH_FUNDING_LIMIT_RATE: 80, DEFAULT_MIN_CASH_FUNDING_LIMIT_RATE: 65, MAX_RENT_HOLDBACK: 100, MIN_NET_RENT_YIELD_PERCENT: 11.5, DEFAULT_EK_PROCESSING_FEE: 3.75, pricing: {
        tvc: {
            criteria: {
                minCashFlow: {
                    approved: function (deal) {
                        return deal.monthlyRent * 0.15 * -1;
                    },
                    override: function (deal) {
                        return deal.monthlyRent * 0.15 * -1;
                    },
                },
                cashFundingRate: {
                    approved: function (deal) {
                        if (deal.purchasePrice < 600000) {
                            return 0.75;
                        }
                        else {
                            return 0.7;
                        }
                    },
                    override: function () { return 0.75; },
                },
                dscrSelfFundLimit: {
                    approved: function () { return 1; },
                    override: function () { return 1; },
                },
                dscr: {
                    approved: function () { return 1.15; },
                    override: function () { return 1; },
                },
                netRent: {
                    approved: function () { return 0; },
                    override: function () { return -0.15; },
                },
                cfMonth: {
                    approved: function () { return 12; },
                    override: function () { return 10; },
                },
                selfFundMax: {
                    approved: function () { return 0; },
                    override: function () { return 7000; },
                },
                npv: {
                    approved: function () { return 1500; },
                    override: function () { return 1000; },
                },
                minPurchasePrice: {
                    approved: function () { return 110000; },
                    override: function () { return 110000; },
                },
            },
            cashFunding: 70,
            cashFundingMin: 65,
            cashFundingMax: 75,
            costOfCapital: 10.0,
            dispositionMonth: 12,
            npvMonths: 12,
            originationFees: 1.25,
            originationFeesFixed: 964,
            dailyRates: {
                baseRate60: 7.75,
                baseRate65: 7.875,
                baseRate70: 8,
                baseRate75: 8.125,
                baseRate80: 8.25,
                interestOnlyAdjuster: 0.125,
                cashFundingLT150: 0.125,
                threeYearPP: 0.25,
                oneYearPP: 0.75,
            },
        },
        amerant: {
            criteria: {
                minCashFlow: {
                    approved: function (deal) {
                        return deal.monthlyRent * 0.15 * -1;
                    },
                    override: function (deal) {
                        return deal.monthlyRent * 0.15 * -1;
                    },
                },
                cashFundingRate: {
                    approved: function (deal) {
                        if (deal.purchasePrice < 600000) {
                            return 0.75;
                        }
                        else {
                            return 0.7;
                        }
                    },
                    override: function () { return 0.8; },
                },
                dscrSelfFundLimit: {
                    approved: function () { return 1; },
                    override: function () { return 1; },
                },
                dscr: {
                    approved: function () { return 1.15; },
                    override: function () { return 0.95; },
                },
                netRent: {
                    approved: function () { return 0; },
                    override: function () { return -0.15; },
                },
                cfMonth: {
                    approved: function () { return 12; },
                    override: function () { return 10; },
                },
                selfFundMax: {
                    approved: function () { return 0; },
                    override: function () { return 10000; },
                },
                npv: {
                    approved: function () { return 3000; },
                    override: function () { return 1000; },
                },
                minPurchasePrice: {
                    approved: function () { return 110000; },
                    override: function () { return 110000; },
                },
            },
            cashFunding: 70,
            cashFundingMin: 65,
            cashFundingMax: 75,
            costOfCapital: 7.5,
            dispositionMonth: 12,
            maxPurchasePrice: 720000,
            npvMonths: 12,
            originationFees: 1,
            originationFeesFixed: 750,
            dailyRates: {
                baseRate60: 7.75,
                baseRate65: 7.875,
                baseRate70: 8,
                baseRate75: 8.125,
                baseRate80: 8.25,
                interestOnlyAdjuster: 0.125,
                cashFundingLT150: 0.125,
                threeYearPP: 0.25,
                oneYearPP: 0.75,
            },
        },
    } });
var configRelease = __assign(__assign({}, configDefault), { DEFAULT_CASH_FUNDING_LIMIT_RATE: 100, DEFAULT_LANDLORD_EXPENSE_RATE: 13, DEFAULT_MAX_CASH_FUNDING_LIMIT_RATE: 100, DEFAULT_SECURITY_DEPOSIT_FACTOR: 2 });
var configReleaseNonEK = __assign(__assign({}, configDefault), { DEFAULT_CASH_FUNDING_LIMIT_RATE: 100, DEFAULT_LANDLORD_EXPENSE_RATE: 13, DEFAULT_MAX_CASH_FUNDING_LIMIT_RATE: 100, DEFAULT_SECURITY_DEPOSIT_FACTOR: 2 });
var config = {
    default: configDefault,
    SellAndStay: configSellAndStay,
    MoveAbility: configMoveability,
    ReLease: configRelease,
    ReLeaseNonEK: configReleaseNonEK,
};
export var financeConfig = (_a = {},
    _a[FinanceOptions.DSCR] = {
        DISCOUNT_RATE: 9.25,
        LENDER: "tvc" /* TempleView */,
    },
    _a[FinanceOptions.DSCR_REFI_36] = {
        DISCOUNT_RATE: 9.25,
        LENDER: "tvc" /* TempleView */,
        REFINANCING_RATE: 7,
        REFINANCE_MONTH: 36,
    },
    _a[FinanceOptions.BRIDGE] = {
        DISCOUNT_RATE: 9.25,
        LENDER: "tvc" /* TempleView */,
    },
    _a[FinanceOptions.BRIDGE_REFI_24] = {
        DISCOUNT_RATE: 9.25,
        LENDER: "tvc" /* TempleView */,
        REFINANCING_RATE: 7,
        REFINANCE_MONTH: 24,
    },
    _a);
export default config;
