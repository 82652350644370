import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Colors } from '@easyknockorg/ek-ui-components';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import apiClient from '../../utils/apiClient';

import './index.css';

const theme = createTheme({
  typography: {
    fontFamily: "'Kumbh Sans', sans-serif",
    fontSize: 10,
  },
  overrides: {
    MuiAccordionSummary: {
      content: {
        alignItems: 'flex-start',
        width: '81%',
        flexGrow: 0,
      },
    },
    MuiAutocomplete: {
      option: {
        border: `1px solid ${Colors.BGW.greyWhite}`,
        marginBottom: '-1px',
        '&:first-of-type': {
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
        },
        '&:last-of-type': {
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
        },
      },
      listbox: {
        borderRadius: '5px',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 14,
        width: '203px',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 16,
        color: '#454b4e',
      },
    },
    MuiSlider: {
      root: {
        color: '#00DBB1',
        height: 4,
      },
      thumb: {
        height: 13,
        width: 13,
        backgroundColor: '#00DBB1',
        border: '2px solid currentColor',
        marginTop: -5,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
          boxShadow: 'inherit',
        },
      },
      active: {},
      valueLabel: {
        left: 'calc(-50% + 4px)',
      },
      track: {
        height: 4,
        borderRadius: 4,
      },
      rail: {
        height: 4,
        borderRadius: 4,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
  },
  palette: {
    text: { primary: '#000' },
    primary: { main: '#00DBB1' },
    error: { main: '#DE7410' },
  },
  shadows: ['none'],
});

const getDealTypeLabel = (dealTypeID) => {
  if (dealTypeID === 1) {
    return 'Sell & Stay';
  }
  if (dealTypeID === 2) {
    return 'Movability';
  }
  return dealTypeID;
};

const Dashboard = () => {
  const [deals, setDeals] = useState([]);

  useEffect(async () => {
    let topDeals = [];
    try {
      topDeals = await apiClient.deal.getTopDeals();
    } catch (e) {
      console.error(e);
    }
    setDeals(topDeals);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <ThemeProvider theme={theme}>
        <div className="screen-title">Recent Deals</div>

        <Grid
          style={{ marginTop: '0px' }}
          container
          justifyContent="space-between"
          spacing={2}
          md={12}
          lg={12}
        >
          {deals.map((deal) => (
            <Grid className="hideScrollbar" key={deal.id} md={4} lg={4}>
              <div className="card">
                <div className="title">
                  {deal.id} -
                  <a href={`/deal-calculator/${deal.id}`}>{`${deal.firstName} ${deal.lastName}`}</a>
                </div>
                <p>{deal.address}</p>
                <p>
                  {deal.city} {deal.zip}
                </p>
                <p>{deal.email}</p>
                <p>{deal.phone}</p>
                <p>DealType: {getDealTypeLabel(deal.DealTypeId)}</p>
                <p>VersionID: {deal.VersionId}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      </ThemeProvider>
    </div>
  );
};

export default Dashboard;
