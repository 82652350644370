import React from 'react';
import { UserRole } from '../../../../../formulas/dto/userDto';
import UwDefaultView from './UwDefaultView';
import UwSnapshotView from './UwSnapshotView';
import { useDealContext } from '../../../../../contexts/DealContext';

const UwSection = ({ dealData, setDealData, userData, setSnackbarState }) => {
  if (userData?.role === UserRole.Default) {
    // We should not display UW view for Sales
    return null;
  }

  if (dealData?.dealOwnerRole === null) {
    // No owner role, this is a default deal

    const { defaultWithPrimary, setDefaultWithPrimary } = useDealContext();
    return (
      <UwDefaultView
        dealData={defaultWithPrimary}
        setDealData={setDefaultWithPrimary}
        userData={userData}
        setSnackbarState={setSnackbarState}
      />
    );
  } else if (dealData?.dealOwnerRole === UserRole.Underwriting) {
    // It's a snapshot and underwriting created this deal
    return <UwSnapshotView dealData={dealData} userData={userData} />;
  } else {
    // It's a snapshot and Sales created this deal, no UW view to display here...
    return null;
  }
};

export default UwSection;
