import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    max-height: 100vh;
    height: 100vh;
    width: 100%;
  }

  body {
    font-family: sans-serif;
  }

  body.fontLoaded {
    font-family: 'Kumbh Sans', sans-serif;
  }
`;

export default GlobalStyle;
