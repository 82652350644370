import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import { Colors } from '@easyknockorg/ek-ui-components';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    padding: 20px;
  }
  .MuiDialogActions-root {
    padding: 16px 24px 8px 24px;
  }
  .MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 16px;
  }
  .MuiDialogTitle-root {
    padding-bottom: 0px;
  }
`;

export const DialogSubtitle = styled.h5`
  margin: 0;
  font-weight: normal;
  padding: 8px 24px 16px 24px;
  font-size: 15px;
  color: #565d61;
`;

export const StyledTextField = styled(TextField)`
  .MuiFormHelperText-root {
    font-size: 13px;
    color: ${Colors.BGW.gray4};
  }
`;
