import configLegacyMap from './config-legacy';
import configLatest, { financeConfig } from './config-latest';
var getConfig = function (versionId) {
    return configLegacyMap[versionId] || configLatest;
};
var getFinanceConfig = function () {
    // TODO: Add legacy config if needed afterwards
    return financeConfig;
};
export { getConfig, getFinanceConfig };
