import styled from 'styled-components';
import { Colors } from '@easyknockorg/ek-ui-components';

export const ContentTitle = styled.div`
  font-size: 13px;
  color: ${Colors.BGW.grey3};
`;

export const ContentValue = styled.div`
  font-size: 16px;
  color: ${({ disabled }) => (disabled ? Colors.BGW.darkGrey2 : Colors.BGW.outerSpace)};
`;
