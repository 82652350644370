import React, { useState } from 'react';
import apiClient from '../../utils/apiClient';
import { BtnContainer, Main, StyledButton, StyledContainer, TextBox, Title } from './styles';

const LogDecrypt = () => {
  const [decrypted, setDecrypted] = useState('');
  const [encrypted, setEncrypted] = useState('');
  const [showError, setShowError] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const handleClick = async () => {
    if (encrypted.length === 0) {
      setShowWarning(true);
      // Clean up possible old decrypted results still showing on the screen
      setDecrypted('');
      return;
    }
    try {
      const result = await apiClient.logDecrypt.post({ value: encrypted });
      setDecrypted(JSON.stringify(result, null, 2));
    } catch (error) {
      setShowError(true);
      setDecrypted('');
    }
  };

  const handleFocus = (event) => {
    event.target.select();
    setShowError(false);
    setShowWarning(false);
  };

  return (
    <StyledContainer>
      <Title>Object Decryption Form</Title>
      <Main>
        <form>
          <TextBox
            autoFocus
            id="encrypted-textfield"
            error={showError}
            fullWidth
            label="Encrypted value"
            multiline
            margin="dense"
            variant="outlined"
            type="text"
            rows={18}
            value={encrypted}
            helperText={
              showError
                ? 'Failed to decrypt value'
                : showWarning
                ? 'You need to pass an encrypted value'
                : null
            }
            onChange={(event) => setEncrypted(event.target.value)}
            onFocus={(event) => handleFocus(event)}
          />
          <BtnContainer>
            <StyledButton variant="outlined" onClick={() => handleClick()}>
              Decrypt
            </StyledButton>
          </BtnContainer>
        </form>
        <section>
          <h2>Decrypted value</h2>
          <pre>{decrypted}</pre>
        </section>
      </Main>
    </StyledContainer>
  );
};

export default LogDecrypt;
