import { Button, TextField } from '@material-ui/core';
import styled from 'styled-components';

const labelColor = '#00dbb1';
const mainFontColor = '#454b4e';
const textFieldColor = '#f44336';

export const BtnContainer = styled.div`
  margin-top: 12px;
`;

export const Main = styled.main`
  margin: 0 auto;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  margin-right: 8px;
  && {
    border-color: #02b39199;
    color: #02b391;
    font-family: Kumbh Sans;
  }
`;

export const StyledContainer = styled.div`
  color: ${mainFontColor};
  padding: 24px 56px;
`;

export const TextBox = styled(TextField)`
  .MuiFormHelperText-root {
    margin-left: 5px;
  }

  .MuiFormLabel-root,
  .MuiFormHelperText-root,
  .MuiInputBase-root {
    font-family: Kumbh Sans;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${({ error }) => (error ? textFieldColor : labelColor)};
  }

  .MuiInputBase-root {
    color: ${mainFontColor};
  }

  .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${(props) => (props.error ? textFieldColor : labelColor)};
    }
  }
`;

export const Title = styled.h1`
  text-align: center;
`;
