import styled from 'styled-components';
import { Colors } from '@easyknockorg/ek-ui-components';
import Radio from '@material-ui/core/Radio';
import Divider from '@material-ui/core/Divider';

const getCardHeight = (
  isExceptionCard,
  isSnapshot,
  isCurrentUserUW,
  showPreview,
  activeCard,
  noLender,
) => {
  if (isSnapshot) {
    return isCurrentUserUW && !showPreview ? '152px' : '200px';
  } else {
    if (isCurrentUserUW) {
      return showPreview ? '200px' : '152px';
    }
    if (isExceptionCard) {
      return 'auto';
    }
    if (noLender) {
      return '90px';
    }
    return '128px';
  }
};

export const CardContainer = styled.div`
  width: 320px;
  margin: 16px auto 0 auto;
  &.notalone {
    &:last-of-type {
      margin: ${({ disabled }) => (!disabled ? '0 auto' : '')};
    }
  }
`;

export const Card = styled.div`
  width: 320px;
  height: ${({ isException, isSnapshot, isCurrentUserUW, showPreview, active, noLender }) =>
    getCardHeight(isException, isSnapshot, isCurrentUserUW, showPreview, active, noLender)};
  letter-spacing: 0.5px;
  background-color: ${({ active, isSnapshot }) =>
    active && isSnapshot ? '#F5F6F7' : active ? '#F3FDFB' : ''};
  color: black;
  font-size: 18px;
  border: ${({ active, isSnapshot }) =>
    active && !isSnapshot ? '1px solid #00DBB1' : '1px solid #E7E7E7'};
  box-shadow: ${({ active, isSnapshot }) =>
    active && !isSnapshot ? '1px 1px 20px rgba(26, 48, 54, 0.2)' : ''};
  border-radius: 8px;
  cursor: ${({ isSnapshot }) => (isSnapshot ? 'not-allowed' : 'pointer')};
  padding: 20px;
  margin: 0 auto;
  justify-content: center;
  color: ${Colors.BGW.outerSpace};

  :hover {
    border-color: #00dbb1;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 26px;
`;

export const CardSelected = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  color: ${({ uw }) => (uw ? Colors.BGW.gray7 : Colors.PineGreen.secondaryGreen)};
  font-weight: bold;
  margin-bottom: 4px;
`;

export const CardSubheader = styled.div`
  color: ${Colors.BGW.darkGrey2};
  font-size: 13px;
  margin-top: 4px;
`;

export const CustomRadio = styled(Radio)`
  margin: 0;
  align-self: flex-start;

  &.MuiRadio-root {
    padding: 0;
  }

  &.MuiRadio-colorSecondary.Mui-checked {
    color: ${Colors.PineGreen.default};
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: ${({ activeCard }) => (activeCard ? '24px' : '0')};
`;

export const ContentTitle = styled.div`
  font-size: 13px;
  color: ${Colors.BGW.grey3};
`;

export const ContentValue = styled.div`
  font-size: 16px;
  color: ${({ disabled }) => (disabled ? Colors.BGW.darkGrey2 : Colors.BGW.outerSpace)};
`;

export const DividerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const DividerText = styled.div`
  font-size: 13px;
  color: ${Colors.BGW.darkGrey2};
  padding: 16px;
`;

export const CustomDivider = styled(Divider)`
  width: 42%;
`;

export const NoOfferDivider = styled(Divider)`
  width: 100%;
  margin: 26px 0 !important;
`;
