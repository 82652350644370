import React from 'react';
import { SnapshotButton } from '../../Organisms/Header/styles';
import { Colors, Header5 } from '@easyknockorg/ek-ui-components';
import { LoadingSpinner } from '../../Atoms/LoadingSpinner';
import { TextButton } from '../../Organisms/ActivityModal/styles';

const ActionButtons = ({
  onSubmit,
  submitLabel,
  disabled,
  loadingSpinner,
  onCancel,
  cancelLabel,
}) => {
  return (
    <>
      {cancelLabel && onCancel && <TextButton onClick={onCancel}>{cancelLabel}</TextButton>}
      <SnapshotButton
        color="primary"
        onClick={() => {
          onSubmit();
        }}
        size="large"
        variant="contained"
        disabled={disabled || loadingSpinner}
      >
        <Header5 style={{ margin: 0, color: disabled ? Colors.BGW.grey3 : 'inherit' }}>
          {loadingSpinner ? <LoadingSpinner /> : submitLabel}
        </Header5>
      </SnapshotButton>
    </>
  );
};

export default ActionButtons;
