import styled from 'styled-components';
import { Colors } from '@easyknockorg/ek-ui-components';

export const Cards = styled.div`
  margin: 0 auto;
  align-items: center;
  font-family: 'Kumbh Sans', sans-serif;
`;

export const Solution = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: ${({ uw }) => (uw ? '18px' : '13px')};
  margin: 0 auto 24px auto;
  color: ${({ uw }) => (uw ? Colors.BGW.darkGrey : Colors.BGW.darkGrey2)};
  font-weight: bold;
  max-height: 22px;
`;
