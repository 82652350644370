export const onSuccess = (setSnackbarState, title, message = '') => {
  setSnackbarState({
    open: true,
    title: title,
    message: message,
    severity: 'success',
  });
};

export const onWarning = (setSnackbarState, title, message = '') => {
  setSnackbarState({
    open: true,
    title: title,
    message: message,
    severity: 'warning',
  });
};

export const onError = (setSnackbarState, title, message = '') => {
  console.error(title);
  setSnackbarState({
    open: true,
    title: title,
    message: message,
    severity: 'error',
  });
};
