import styled from 'styled-components';
import { Colors, FlexContainer } from '@easyknockorg/ek-ui-components';
import { Header3, Header2 } from '@easyknockorg/ek-ui-components';
import TableCell from '@material-ui/core/TableCell';

export const Body = styled.div`
  display: flex;
  width: 100%;
  top: ${({ offsetTop }) => (offsetTop ? `${offsetTop}px` : '94px')};
  bottom: 50px;
`;

export const Page = styled.div`
  height: 100vh;
  overflow-x: hidden;
  margin: auto;
`;

export const Title = styled.h2`
  color: ${Colors.BGW.outerSpace};
  margin: 0;
`;

export const ClientContact = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledHeader3 = styled(Header3)`
  margin: 0;
  cursor: pointer;
  color: #292e30;
  font-size: 24px;
`;

export const StyledHeader2 = styled(Header2)`
  margin: 0;
  cursor: pointer;
  color: #292e30;
  font-size: 12px;
`;

export const StyledTableCell = styled(TableCell)`
  padding: '7px', 
  fontSize: '9pt', 
  border: 1 
`;
