import accounting from 'accounting';
import React from 'react';
import { ContentTitle, ContentValue } from './styles';
import { Divider } from '@material-ui/core';

export const Detail = ({ title, value, margin = 0, formatMoney = true, disabled }) => {
  const format = (amount) => accounting.formatMoney(amount, '$ ', 2);
  return (
    <div style={{ margin: margin }}>
      <ContentTitle>{title}</ContentTitle>
      <ContentValue disabled={disabled}>{formatMoney ? format(value) : value}</ContentValue>
    </div>
  );
};

export const DetailDivider = ({ margin }) => {
  return <Divider orientation="vertical" flexItem style={{ margin: margin ?? '0 16px' }} />;
};
