import React, { useState } from 'react';
import { Field } from 'redux-form';
import Slider from '../../../../components/ReduxFormWrapers/Slider';

const ControlledSlider = ({ step, marks, max, min, k, label, disabled }) => (
  <Field
    step={step}
    marks={marks}
    max={max}
    min={min}
    name={k}
    component={Slider}
    placeholder=""
    disabled={disabled}
  />
);

export default ControlledSlider;
