import React from 'react';
import { Colors, IconButton } from '@easyknockorg/ek-ui-components';
import { ActionContainer, ChevronButton, ChevronIconContainer } from './styles';

const ActionButton = ({
  value,
  handleClick,
  disabled,
  icon = null,
  iconPosition = 'right',
  iconSize = null,
  small = false,
}) => {
  const renderIcon = () => {
    if (icon) {
      if (iconPosition === 'right') {
        return (
          <ChevronIconContainer>
            <IconButton
              icon={icon}
              color={Colors.PineGreen.secondaryGreen}
              size={iconSize ?? 12}
              onClick={(evt) => handleClick(evt)}
            />
          </ChevronIconContainer>
        );
      } else if (iconPosition === 'left') {
        return (
          <IconButton
            icon={icon}
            color={Colors.PineGreen.secondaryGreen}
            size={iconSize ?? 20}
            onClick={(evt) => handleClick(evt)}
          />
        );
      }
    }
  };

  return (
    <ActionContainer small={small}>
      {iconPosition === 'left' && renderIcon()}
      <ChevronButton
        small={small}
        disabled={disabled}
        type="button"
        onClick={(evt) => handleClick(evt)}
      >
        {value}
      </ChevronButton>
      {iconPosition === 'right' && renderIcon()}
    </ActionContainer>
  );
};

export default ActionButton;
