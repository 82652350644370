var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormulaMapLatest } from './formulas-latest';
var FormulaMapV27 = /** @class */ (function (_super) {
    __extends(FormulaMapV27, _super);
    function FormulaMapV27(deal, user, runInit) {
        if (runInit === void 0) { runInit = true; }
        var _this = _super.call(this, deal, user, false) || this;
        _this.date = new Date('2024-07-23'); // Date in which version was moved to legacy (deploy date).(assumed)
        _this.rentHoldbackMonths = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                if (dealTypeId === 1 /* SellAndStay */) {
                    return 2;
                }
                else {
                    return 0;
                }
            },
            min: function () { return 0; },
            max: function () { return 11; },
        };
        _this.partialRentPrepaymentStartMonth = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                if (dealTypeId === 1 /* SellAndStay */) {
                    return _this.rentHoldbackAmount.calc() * _this.rentHoldbackMonths.calc() > 0
                        ? _this.rentHoldbackMonths.calc() + 1
                        : 0;
                }
                else {
                    // Movability and all other deal types on record right now
                    return 1;
                }
            },
        };
        if (runInit) {
            _this.init(deal, user);
        }
        return _this;
    }
    return FormulaMapV27;
}(FormulaMapLatest));
var FormulaMapV26 = /** @class */ (function (_super) {
    __extends(FormulaMapV26, _super);
    function FormulaMapV26(deal, user, runInit) {
        if (runInit === void 0) { runInit = true; }
        var _this = _super.call(this, deal, user, false) || this;
        _this.date = new Date('2024-04-01'); // Date in which version was moved to legacy (deploy date).(assumed)
        _this.rentHoldbackMonths = {
            calc: function () {
                if (_this.rentHoldbackAmount.calc() > 0) {
                    return _this.deal.rentHoldbackMonths || 11;
                }
                else {
                    return 0;
                }
            },
            min: function () { return 0; },
            max: function () { return 11; },
        };
        _this.cashRelease = {
            calc: function () {
                var dealTypeId = _this.DealTypeId.calc();
                var sellAndStayRelease = _this.cashFunding.calc() -
                    _this.easyKnockProcessingFee.calc() -
                    _this.liens.calc() -
                    _this.monthlyRent.calc() -
                    _this.proratedRent.calc() -
                    _this.rentHoldbackAmount.calc() * _this.rentHoldbackMonths.calc() -
                    _this.repairFundAmount.calc() -
                    (_this.isPostProcessingPostReview.calc() && _this.deal.pddCompletedDate != null
                        ? 0
                        : _this.warranty.calc()) -
                    _this.mortgageBalance.calc() -
                    _this.closingCosts.calc() -
                    _this.transferTax.calc() -
                    _this.remainingPropertyTax.calc();
                if (dealTypeId === 1 /* SellAndStay */)
                    return sellAndStayRelease;
                if (dealTypeId === 2 /* MoveAbility */) {
                    return sellAndStayRelease - _this.halfOptionFee.calc();
                }
                return sellAndStayRelease - _this.halfOptionFee.calc();
            },
        };
        if (runInit) {
            _this.init(deal, user);
        }
        return _this;
    }
    return FormulaMapV26;
}(FormulaMapV27));
var FormulaMapExpired = /** @class */ (function (_super) {
    __extends(FormulaMapExpired, _super);
    function FormulaMapExpired(deal, user, runInit) {
        if (runInit === void 0) { runInit = true; }
        var _this = _super.call(this, deal, user, false) || this;
        _this.date = new Date('2023-02-17'); // Date in which version was moved to legacy (deploy date).
        if (runInit) {
            _this.init(deal, user);
        }
        return _this;
    }
    return FormulaMapExpired;
}(FormulaMapV26));
export { FormulaMapExpired, FormulaMapV26, FormulaMapV27 };
