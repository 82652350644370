import React from 'react';
import { Colors } from '@easyknockorg/ek-ui-components';
import {
  LeftColumn,
  RightPanel,
  OptionCard,
  Icon,
  CardOption,
  Option,
  TextBox,
  Breakdown,
  LoadingRow,
} from './styles';

const SkeletonLoadingOverlay = ({ loading }) =>
  loading ? (
    <div
      style={{
        position: 'absolute',
        background: 'white',
        display: 'flex',
        flexDirection: 'row',
        left: 0,
        top: 0,
        width: '100%',
        zIndex: '1100',
        height: '100%',
      }}
    >
      <LeftColumn>
        <CardOption>
          <TextBox
            style={{ marginBottom: '28px' }}
            width={'134'}
            height={'12'}
            color={Colors.BGW.greyWhite}
          />
          <OptionCard color={'#F3FDFB'} border={Colors.EKGreen.default} boxShadow={true}>
            <Option>
              <TextBox width={'128'} height={'18'} color={Colors.PineGreen.focusOrHoverLight} />
              <Icon color={'#068A82'} />
            </Option>
            <Option>
              <TextBox width={'108'} height={'8'} color={Colors.PineGreen.focusOrHoverLight} />
              <TextBox width={'108'} height={'8'} color={Colors.PineGreen.focusOrHoverLight} />
            </Option>
            <Option>
              <TextBox width={'108'} height={'8'} color={Colors.PineGreen.focusOrHoverLight} />
              <TextBox width={'108'} height={'8'} color={Colors.PineGreen.focusOrHoverLight} />
            </Option>
            <TextBox style={{ alignSelf: 'center' }} width={'162'} height={'8'} color={'#068A82'} />
          </OptionCard>

          <OptionCard color={Colors.BGW.white} border={Colors.BGW.greyWhite}>
            <Option>
              <TextBox width={'128'} height={'18'} color={Colors.BGW.greyWhite} />
              <Icon color={Colors.BGW.greyWhite} />
            </Option>
            <Option>
              <TextBox width={'108'} height={'8'} color={Colors.BGW.greyWhite} />
              <TextBox width={'108'} height={'8'} color={Colors.BGW.greyWhite} />
            </Option>
            <Option>
              <TextBox width={'108'} height={'8'} color={Colors.BGW.greyWhite} />
              <TextBox width={'108'} height={'8'} color={Colors.BGW.greyWhite} />
            </Option>
          </OptionCard>
          <OptionCard color={Colors.BGW.white} border={Colors.BGW.greyWhite}>
            <Option>
              <TextBox width={'128'} height={'18'} color={Colors.BGW.greyWhite} />
              <Icon color={Colors.BGW.greyWhite} />
            </Option>
            <Option>
              <TextBox width={'108'} height={'8'} color={Colors.BGW.greyWhite} />
              <TextBox width={'108'} height={'8'} color={Colors.BGW.greyWhite} />
            </Option>
            <Option>
              <TextBox width={'108'} height={'8'} color={Colors.BGW.greyWhite} />
              <TextBox width={'108'} height={'8'} color={Colors.BGW.greyWhite} />
            </Option>
          </OptionCard>
          <OptionCard color={Colors.BGW.white} border={Colors.BGW.greyWhite}>
            <Option>
              <TextBox width={'128'} height={'18'} color={Colors.BGW.greyWhite} />
              <Icon color={Colors.BGW.greyWhite} />
            </Option>
            <Option>
              <TextBox width={'108'} height={'8'} color={Colors.BGW.greyWhite} />
              <TextBox width={'108'} height={'8'} color={Colors.BGW.greyWhite} />
            </Option>
            <Option>
              <TextBox width={'108'} height={'8'} color={Colors.BGW.greyWhite} />
              <TextBox width={'108'} height={'8'} color={Colors.BGW.greyWhite} />
            </Option>
          </OptionCard>
        </CardOption>
      </LeftColumn>

      <RightPanel>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <TextBox width={'271'} height={'24'} color={Colors.BGW.greyWhite} />
          <TextBox
            width={'258'}
            height={'56'}
            color={Colors.BGW.greyWhite}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              borderRadius: '100px',
              padding: '8px',
            }}
          >
            <TextBox
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '56px',
                padding: '8px',
              }}
              width={'99'}
              height={'40'}
              color={Colors.BGW.white}
            >
              <TextBox width={'67'} height={'12'} color={Colors.BGW.grey3} />
            </TextBox>
            <TextBox width={'117'} height={'12'} color={Colors.BGW.grey3} />
          </TextBox>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            gap: '40px',
          }}
        >
          <Breakdown>
            <LoadingRow>
              <TextBox
                style={{ marginBottom: '24px' }}
                width={'271'}
                height={'24'}
                color={Colors.BGW.greyWhite}
              />
              <TextBox
                style={{ marginBottom: '8px' }}
                width={'524'}
                height={'12'}
                color={Colors.BGW.greyWhite}
              />
              <TextBox width={'216'} height={'12'} color={Colors.BGW.greyWhite} />
            </LoadingRow>
            <LoadingRow
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <TextBox width={'278'} height={'18'} color={Colors.BGW.greyWhite} />
              <TextBox width={'83'} height={'18'} color={Colors.BGW.greyWhite} />
            </LoadingRow>
            <LoadingRow>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: '24px',
                }}
              >
                <TextBox width={'278'} height={'18'} color={Colors.BGW.greyWhite} />
                <TextBox width={'83'} height={'18'} color={Colors.BGW.greyWhite} />
              </div>
              <TextBox width={'99'} height={'8'} color={Colors.PineGreen.focusOrHoverLight} />
            </LoadingRow>
            <LoadingRow>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: '24px',
                }}
              >
                <TextBox width={'278'} height={'18'} color={Colors.BGW.greyWhite} />
                <TextBox width={'83'} height={'18'} color={Colors.BGW.greyWhite} />
              </div>
              <TextBox width={'99'} height={'8'} color={Colors.PineGreen.focusOrHoverLight} />
            </LoadingRow>
            <LoadingRow>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: '24px',
                }}
              >
                <TextBox width={'278'} height={'18'} color={Colors.BGW.greyWhite} />
                <TextBox width={'83'} height={'18'} color={Colors.BGW.greyWhite} />
              </div>
              <TextBox width={'99'} height={'8'} color={Colors.PineGreen.focusOrHoverLight} />
            </LoadingRow>
            <LoadingRow>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: '24px',
                }}
              >
                <TextBox width={'278'} height={'18'} color={Colors.BGW.greyWhite} />
                <TextBox width={'83'} height={'18'} color={Colors.BGW.greyWhite} />
              </div>
              <TextBox width={'99'} height={'8'} color={Colors.PineGreen.focusOrHoverLight} />
            </LoadingRow>
            <LoadingRow
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                background: Colors.Blues.oldAccent,
              }}
            >
              <TextBox width={'278'} height={'18'} color={'#ACBCD8'} />
              <TextBox width={'83'} height={'18'} color={'#ACBCD8'} />
            </LoadingRow>
          </Breakdown>
          <Breakdown
            style={{
              gap: '33px',
            }}
          >
            <div
              style={{
                width: '100%',
              }}
            >
              <LoadingRow>
                <TextBox
                  style={{ marginBottom: '24px' }}
                  width={'271'}
                  height={'24'}
                  color={Colors.BGW.greyWhite}
                />
                <TextBox
                  style={{ marginBottom: '8px' }}
                  width={'524'}
                  height={'12'}
                  color={Colors.BGW.greyWhite}
                />
                <TextBox width={'216'} height={'12'} color={Colors.BGW.greyWhite} />
              </LoadingRow>
              <LoadingRow
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <TextBox width={'278'} height={'18'} color={Colors.BGW.greyWhite} />
                <TextBox width={'83'} height={'18'} color={Colors.BGW.greyWhite} />
              </LoadingRow>
              <LoadingRow
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  background: Colors.Blues.oldAccent,
                }}
              >
                <TextBox width={'278'} height={'18'} color={'#ACBCD8'} />
                <TextBox width={'83'} height={'18'} color={'#ACBCD8'} />
              </LoadingRow>
            </div>
            <div
              style={{
                width: '100%',
              }}
            >
              <LoadingRow>
                <TextBox
                  style={{ marginBottom: '24px' }}
                  width={'271'}
                  height={'24'}
                  color={Colors.BGW.greyWhite}
                />
                <TextBox
                  style={{ marginBottom: '8px' }}
                  width={'524'}
                  height={'12'}
                  color={Colors.BGW.greyWhite}
                />
                <TextBox width={'216'} height={'12'} color={Colors.BGW.greyWhite} />
              </LoadingRow>
              <LoadingRow
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <TextBox width={'278'} height={'18'} color={Colors.BGW.greyWhite} />
                <TextBox width={'83'} height={'18'} color={Colors.BGW.greyWhite} />
              </LoadingRow>
              <LoadingRow
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  background: Colors.Blues.oldAccent,
                }}
              >
                <TextBox width={'278'} height={'18'} color={'#ACBCD8'} />
                <TextBox width={'83'} height={'18'} color={'#ACBCD8'} />
              </LoadingRow>
            </div>
          </Breakdown>
        </div>
      </RightPanel>
    </div>
  ) : null;

export default SkeletonLoadingOverlay;
