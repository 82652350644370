import React from 'react';
import { Header5 } from '@easyknockorg/ek-ui-components';
import { Divider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import houseIcon from '../../../images/house_icon.svg';
import mailIcon from '../../../images/mailIcon.svg';
import phoneIcon from '../../../images/phoneIcon.svg';
import { Detail } from '../../Atoms/Detail';
import EkRoundButton from '../../Atoms/EkRoundButton';
import { ClientAddress, ClientContact, HouseIcon, RoundButtons, StyledHeader3 } from './styles';
import ActionButton from '../../Atoms/ActionButton';

const CustomersDetails = ({ anchorEl, setAnchorEl, dealData, fullAddress, name, email, phone }) => {
  const open = Boolean(anchorEl);
  const { opportunityId } = dealData;

  const defaultDeal = dealData?.snapshots?.find((snapshot) => !snapshot.name);
  const desiredCashout = defaultDeal ? defaultDeal.desiredCashout : dealData?.desiredCashout;
  const desiredRent = defaultDeal ? defaultDeal.desiredRent : dealData?.desiredRent;
  const monthlyIncome = defaultDeal ? defaultDeal.monthlyIncome : dealData?.monthlyIncome;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    window.open(
      `${process.env.REACT_APP_SALESFORCE_HOST_NAME}/lightning/r/Opportunity/${opportunityId}/view`,
      'Edit details',
    );
  };

  return (
    <Popover
      id={open ? 'user-popover' : undefined}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Paper
        elevation={24}
        variant="outlined"
        style={{
          padding: '30px',
          borderRadius: '8px',
        }}
      >
        <ClientContact>
          <StyledHeader3>{name}</StyledHeader3>
          <RoundButtons>
            <EkRoundButton title={phone} href={`tel:${phone}`} icon={phoneIcon} secondary />
            <EkRoundButton title={email} href={`mailto:${email}`} icon={mailIcon} secondary />
          </RoundButtons>
        </ClientContact>
        <ClientAddress>
          <HouseIcon src={houseIcon} />
          {fullAddress}
        </ClientAddress>
        <Divider style={{ margin: '28px 0' }} />
        <div>
          <Header5>Customer's Details</Header5>
          <div style={{ display: 'flex', marginBottom: '24px' }}>
            <Detail title="Monthly Income" value={monthlyIncome} />
            <Divider orientation="vertical" flexItem style={{ margin: '0 16px' }} />
            <Detail title="Desired Cash" value={desiredCashout} />
            <Divider orientation="vertical" flexItem style={{ margin: '0 16px' }} />
            <Detail title="Desired Rent" value={desiredRent} />
          </div>
          <ActionButton
            value="Edit Customer's Details"
            handleClick={handleEdit}
            icon="chevronRight"
          />
        </div>
      </Paper>
    </Popover>
  );
};

export default CustomersDetails;
