/*

The VersionId should be upgraded whenever there are non-backwards-compatible pricing changes.
In other words some deals will have the old formulas/configs and some will have the new ones.

Steps to change pricing:
1. Increment the version number here, in api/config/version-id-latest.ts
2. Make config changes in api/services/config: config-latest.ts and config-legacy.ts
3. Make formula changes in api/services/formulas: formulas-latest.ts and formulas-legacy.ts
4. Make unit test changes in api/services/formulas: formulas-latest.spec.ts and
   formulas-legacy.spec.ts
5. Update configLegacyMap, configByVersion, and add FormulaMapX to services/formulas/index.ts
6. Update header in client folder to include latest version: client/src/components/Header/components/Actions/index.js
*/
export default 28;
