import styled from 'styled-components';
import Snackbar from '@material-ui/core/Snackbar';

export const Message = styled.div`
  color: #565d61;
  margin-top: 4px;
  font-size: 14px;
`;

export const SnackbarContent = styled.div`
  display: flex;
`;

export const StyledSnackbar = styled(Snackbar)`
  width: 95%;
  border-radius: 5px;
  box-shadow: 1px 1px 25px rgba(26, 48, 54, 0.1);
  border: 2px solid #e7e7e7;
  background-color: #ffffff;

  &.MuiSnackbar-root {
    z-index: 2200;
  }
  .MuiSnackbarContent-root {
    font-family: Kumbh Sans,sans-serif;
    letter-spacing: 0.01em;
    font-weight: 400;
    color: #454b4e;
    font-size: 16px;
    background-color: white;
    flex-grow: 1;
    justify-content: center;
    padding: 24px;
    line-height: 24px;
  }
  .MuiSnackbarContent-message {
    padding: 0;
    width: 95%;
  }
`;
