import React from 'react';
import TextField from '@material-ui/core/TextField';

const SelectInputWrapped = ({
  label,
  input,
  fullWidth,
  options = [],
  meta: { touched, invalid, error },
  disableautofocus,
  ...custom
}) => {
  return (
    <TextField
      key={label}
      InputLabelProps={{
        style: {
          fontSize: 13,
          fontFamily: "'Kumbh Sans', sans-serif",
        },
      }}
      inputProps={{
        style: {
          fontSize: 13,
          fontFamily: "'Kumbh Sans', sans-serif",
          color: '#454b4e',
        },
      }}
      fullWidth={fullWidth}
      size="small"
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
      select
      SelectProps={{
        native: true,
      }}
      variant="outlined"
    >
      {options.map((option) => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
  );
};

export default SelectInputWrapped;
