import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingOverlay from '../LoadingOverlay';
import apiClient from '../../utils/apiClient';
import { alertAction } from '../Alert/reducer';
import {
  overloadStore,
  initializePlayback,
  finishPlayback,
} from '../ReduxBugReporter/store-enhancer';

const errorText = (error) => {
  if (error.code === 'SALESFORCE_MISSING_CITY') {
    return 'Error: Please make sure the city on the main contact is not empty.';
  } else if (error.code === 'SALESFORCE_MISSING_EMAIL') {
    return 'Error: Please make sure the email on the main contact is not empty.';
  } else if (error.code === 'SALESFORCE_MISSING_FIRST_NAME') {
    return 'Error: Please make sure the first name on the main contact is not empty.';
  } else if (error.code === 'SALESFORCE_MISSING_LAST_NAME') {
    return 'Error: Please make sure the last name on the main contact is not empty.';
  } else if (error.code === 'SALESFORCE_MISSING_STATE') {
    return 'Error: Please make sure the state on the main contact is not empty.';
  } else if (error.code === 'SALESFORCE_MISSING_STREET') {
    return 'Error: Please make sure the address street on the main contact is not empty.';
  } else if (error.code === 'SALESFORCE_MISSING_ZIP') {
    return 'Error: Please make sure the zipcode on the main contact is not empty.';
  } else if (error.code === 'SALESFORCE_OPP_NOT_FOUND') {
    return 'Error: Could not find the opportunity in Salesforce.';
  } else {
    return 'Error: Unknown error getting Salesforce opportunity.';
  }
};

const SalesforceRedirect = ({ loading, dispatch }) => {
  const { opportunityId, sfDealId } = useParams();
  const { search } = useLocation();
  const [error, setError] = useState(null);
  const [dealId, setDealId] = useState(null);
  const [showProgressBar, setShowProgressBar] = useState(loading);

  useEffect(() => {
    const getDealId = async () => {
      let deal = null;
      let message = '';
      if (sfDealId) {
        deal = await apiClient.salesforce.getDeal(sfDealId);
      } else {
        const res = await apiClient.salesforce.get(opportunityId, search);
        deal = res?.deal;
        message = res?.message;
      }
      if (deal) {
        setDealId(deal.id);
      } else {
        setError(true);
        setShowProgressBar(false);
      }
      //TO DO (Maybe Remove)
      if (message !== '' && deal?.VersionId >= 18) {
        dispatch(
          alertAction({
            open: true,
            message,
            severity: 'warning',
            title: "Please create a new snapshot, the customer's information has changed",
          }),
        );
      }
      if (deal.underwritingStatus === 'Declined') {
        dispatch(
          alertAction({
            open: true,
            severity: 'warning',
            title: 'Underwriting has declined your exception.',
          }),
        );
      }
    };
    getDealId().catch((err) => {
      console.error('Error in Salesforce Redirect:', err);
      setError(err);
      setShowProgressBar(false);
    });
  }, [opportunityId, sfDealId]);
  if (error) {
    return (
      <h2
        style={{
          textAlign: 'center',
        }}
      >
        {errorText(error)}
      </h2>
    );
  }

  if (dealId) {
    return <Redirect to={`/deal-calculator/${dealId}`} />;
  }
  return <LoadingOverlay loading showProgressBar={showProgressBar} />;
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: !_.get(state, 'form.dealCalculator.values') || ownProps.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  const boundActions = bindActionCreators(
    { overloadStore, initializePlayback, finishPlayback },
    dispatch,
  );
  return {
    dispatch,
    ...boundActions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesforceRedirect);
