import React, { useEffect, useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Header4, SpinnerOverlay } from '@easyknockorg/ek-ui-components';

import {
  StyledDialog,
  DialogSubtitle,
  DialogErrorNote,
  StyledTextField,
} from '../FormDialog/styles';
import ActionButtons from '../../Molecules/ActionButtons';
import { formatNumberToString, numberWithCommas, validFloatingPointNumber } from '../../../utils';

const MultifieldModal = ({
  dealData,
  error,
  setError,
  fields,
  open,
  setOpen,
  title,
  subtitle,
  submitLabel,
  onSubmit,
  loading = false,
}) => {
  const [inputFields, setInputFields] = useState({});

  useEffect(async () => {
    setError('');
    let newFields = { ...inputFields };
    fields.forEach((field) => {
      newFields[field.name] = dealData[field.name];
    });
    setInputFields(newFields);
  }, [open]);

  const handleOnChange = (event, name) => {
    const newFields = { ...inputFields };
    const floatValueAsString = formatNumberToString(event.target.value);

    // If anything other than a floating point number is entered (with a max of 2 decimal places),
    // skip setting the input fields. This also ensures proper display of the number to the user.
    if (validFloatingPointNumber.test(floatValueAsString)) {
      newFields[name] = floatValueAsString;
      setInputFields(newFields);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        {loading && <SpinnerOverlay background="rgba(255, 255, 255, 0.6)" />}
        <Header4 style={{ margin: 0, padding: '16px 24px 0' }}>{title}</Header4>
        {subtitle && <DialogSubtitle>{subtitle}</DialogSubtitle>}
        {error && <DialogErrorNote>{error}</DialogErrorNote>}
        <DialogContent>
          {fields.map((field, idx) => {
            const { label, name } = field;
            const value = inputFields[name];
            const displayValueAsCurrency = numberWithCommas(value);

            return (
              <StyledTextField
                key={idx}
                type="text"
                label={label}
                value={displayValueAsCurrency}
                onChange={(event) => handleOnChange(event, name)}
                fullWidth
                variant="outlined"
                $docModalSpacing
                InputProps={{
                  startAdornment: <span>$&nbsp;</span>,
                }}
              />
            );
          })}
        </DialogContent>
        <DialogActions>
          <ActionButtons onSubmit={() => onSubmit(inputFields)} submitLabel={submitLabel} />
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default MultifieldModal;
