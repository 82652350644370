export var FinanceOptions;
(function (FinanceOptions) {
    FinanceOptions["DSCR"] = "DSCR";
    FinanceOptions["DSCR_REFI_36"] = "DSCR Refi";
    FinanceOptions["BRIDGE"] = "Bridge";
    FinanceOptions["BRIDGE_REFI_24"] = "Bridge Refi";
})(FinanceOptions || (FinanceOptions = {}));
export var ApprovalStatus;
(function (ApprovalStatus) {
    ApprovalStatus["APPROVED"] = "APPROVED";
    ApprovalStatus["OVERRIDE"] = "OVERRIDE";
    ApprovalStatus["EXCEPTION"] = "EXCEPTION";
})(ApprovalStatus || (ApprovalStatus = {}));
