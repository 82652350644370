import React from 'react';
import { Colors } from '@easyknockorg/ek-ui-components';
import { UserRole } from '../../../../../formulas/dto/userDto';
import {
  Card,
  CardContainer,
  CardHeader,
  CardSelected,
  DetailsContainer,
} from '../../../../../components/Molecules/OfferCard/styles';
import { Detail, DetailDivider } from '../../../../../components/Atoms/Detail';
import { Header5 } from '@easyknockorg/ek-ui-components';
import { generateUwStatusLabel, getPrimaryDeal } from '../../../../../utils';

const PreviousChoice = ({ dealData, isCurrentUserUW, isSnapshot, showUwStyle }) => {
  const primaryDealInfo = getPrimaryDeal(dealData);

  if (!primaryDealInfo) return null;

  const selectedTextUW =
    isCurrentUserUW && primaryDealInfo?.markedPrimaryBy === UserRole.Underwriting
      ? 'UNDERWRITING DECISION'
      : 'OPTION SELECTED';

  return (
    <CardContainer onlyCard={showUwStyle}>
      <Card disabled={showUwStyle} isSnapshot={isSnapshot} isCurrentUserUW={isCurrentUserUW}>
        <div>
          <CardSelected uw={isCurrentUserUW}>{selectedTextUW}</CardSelected>
          <CardHeader>
            <Header5 style={{ margin: 0 }} color={isSnapshot ? Colors.BGW.darkGrey2 : null}>
              {!isSnapshot &&
              isCurrentUserUW &&
              primaryDealInfo?.markedPrimaryBy === UserRole.Underwriting
                ? generateUwStatusLabel(dealData?.underwritingStatus)
                : primaryDealInfo?.optionSelected?.replace(' Estimate', '')}
            </Header5>
          </CardHeader>
          <DetailsContainer>
            <Detail
              disabled={isSnapshot}
              title="Cash Funding"
              value={primaryDealInfo?.cashFunding}
            />
            <DetailDivider margin="0 24px" />
            <Detail disabled={isSnapshot} title="Rent" value={primaryDealInfo?.monthlyRent} />
          </DetailsContainer>
        </div>
      </Card>
    </CardContainer>
  );
};

export default PreviousChoice;
