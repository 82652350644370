import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import history from './utils/history';
import dealCalculatorReducer from './pages/DealCalculator/reducer';
import alert from './components/Alert/reducer';

export default function createReducer() {
  const rootReducer = combineReducers({
    form: (state, action) => dealCalculatorReducer(formReducer(state, action), action),
    router: connectRouter(history),
    alert,
  });

  return rootReducer;
}
