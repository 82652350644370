import React from 'react';
import accounting from 'accounting';
import Grid from '@material-ui/core/Grid';

const RowLayout = ({ components = [], style, disabled }) => (
  <Grid style={style} container spacing={2}>
    {components.map(({ component: Elem, ...comp }, idx) => (
      <Grid key={idx} item xs={parseInt(comp.width || accounting.toFixed(12 / components.length))}>
        <Elem {...comp} disabled={disabled || comp.disabled} />
      </Grid>
    ))}
  </Grid>
);

export default RowLayout;
