import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Header4, IconButton } from '@easyknockorg/ek-ui-components';
import { StyledDialog, DialogSubtitle } from '../FormDialog/styles';

const SimpleDataViewerModal = ({ title, subtitle, open, setOpen }) => {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle>
          <Header4 style={{ margin: 0 }}>{title}</Header4>
        </DialogTitle>
        <IconButton key="x" icon="x" size="22" onClick={onClose} />
      </div>
      {subtitle && (
        <DialogSubtitle>
          <pre>{subtitle}</pre>
        </DialogSubtitle>
      )}
    </StyledDialog>
  );
};

export default SimpleDataViewerModal;
