import { getFormValues } from 'redux-form';

export const REDUX_FORM_ARRAY_REMOVE = '@@redux-form/ARRAY_REMOVE';
export const REDUX_FORM_CHANGE = '@@redux-form/CHANGE';

export const BANNER_ACTION = '@deal-portal/bannerAction';
export const BIND_FIELD_TO_FORMULA = '@deal-portal/bindFieldToFormula';
export const CLONING = '@deal-portal/cloning';
export const ESTIMATE_EMAIL_SENT = '@deal-portal/estimateEmailSent';
export const LOAD_DEAL = '@deal-portal/deal/load';
export const LOAD_DOCUMENT_DATA = '@deal-portal/documentData/load';
export const LOAD_ESTIMATE_EVENT_DATA = '@deal-portal/estimateEventData/load';
export const LOAD_SALESFORCE_REFETCH = '@deal-portal/salesforceRefetch/load';
export const LOGIN_FAILED = '@deal-portal/loginFailed';
export const LOGIN_SUCCESS = '@deal-portal/loginSuccess';
export const LOGOUT = '@deal-portal/logout';

export const DEAL_FORM_NAME = 'dealCalculator';

export const formSelector = (state) => getFormValues(DEAL_FORM_NAME)(state) || {};
