import React from 'react';
import { Popover } from '@material-ui/core';
import HcPopoverContent from './HcPopoverContent';

const HcPopover = ({ anchorEl, setAnchorEl, hcData, ccData }) => {
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const id = open ? 'housecanary-popover' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <HcPopoverContent hcData={hcData} ccData={ccData} />
    </Popover>
  );
};

export default HcPopover;
