import styled from 'styled-components';
import { Slider } from '@material-ui/core';
import { Colors } from '@easyknockorg/ek-ui-components';

export const StyledSlider = styled(Slider)`
  &.MuiSlider-root {
    padding: 0;
  }
  .MuiSlider-rail {
    background-color: ${Colors.BGW.greyWhite};
    opacity: 1;
  }
`;

export const TextFieldContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const SliderMarks = styled.div`
  margin: 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${Colors.BGW.gray4};
  font-weight: bold;
  font-size: 16px;
`;

export const InfoBullet = styled.img`
  cursor: pointer;
  margin-left: 8px;
  height: 18px;
  width: 18px;
`;

export const MaxMark = styled.div`
  display: flex;
  align-items: center;
`;

export const Form = styled.form`
  margin-top: 40px;
  padding: 0;
`;

export const RentReport = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0 24px 0;
`;
