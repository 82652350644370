import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import history from '../../utils/history';
import { connectDealApiClient } from '../../utils/apiClient';
import 'sanitize.css/sanitize.css';
import configureStore from '../../configureStore';

// Create redux store with history
let userData;
try {
  userData = JSON.parse(localStorage.getItem('userData'));
} catch (e) {
  console.error(e);
  userData = {};
}
const initialState = {
  form: {
    user: {
      accessToken: localStorage.getItem('token'),
      data: userData,
    },
  },
};
const store = configureStore(initialState, history);
connectDealApiClient(store);

const AppWrapper = ({ children }) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>{children}</ConnectedRouter>
  </Provider>
);

export default AppWrapper;
