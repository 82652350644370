import _ from 'lodash';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';

export const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login',
  authenticatedSelector: (state) => !!_.get(state, 'form.user.accessToken'),
  wrapperDisplayName: 'UserIsAuthenticated',
  // Returns true if the user auth state is loading
  //authenticatingSelector: state => state.user.isLoading,
  // Render this component when the authenticatingSelector returns true
  //AuthenticatingComponent: LoadingSpinner
});

const locationHelper = locationHelperBuilder({});

export const userIsNotAuthenticated = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  authenticatedSelector: (state) => !_.get(state, 'form.user.accessToken'),
  wrapperDisplayName: 'UserIsNotAuthenticated',
});
