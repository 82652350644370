import { React } from 'react';
import FormDialog from '../../../../components/Organisms/FormDialog';

const TextFieldModal = ({
  nsName,
  setNsName,
  onSubmit,
  nsOpen,
  setNsOpen,
  label,
  submitLabel = 'Submit',
  dealData,
  title,
  subtitle,
  helperText,
}) => {
  return (
    <FormDialog
      fields={[
        {
          label: label ?? 'Name',
          maxLength: 80, // Salesforce has a max length of 80 for Deal object names
          value: nsName,
          setValue: setNsName,
          subtitle,
        },
      ]}
      onSubmit={onSubmit}
      open={nsOpen}
      setOpen={setNsOpen}
      submitLabel={submitLabel ?? 'Create'}
      title={title ?? 'Create New Snapshot'}
      helperText={helperText}
      dealData={dealData}
    />
  );
};

export default TextFieldModal;
