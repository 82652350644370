import React from 'react';
import { connect } from 'react-redux';
import AlertBanner from "../Atoms/AlertBanner";
import { alertAction } from './reducer';

const Alert = ({ open = false, severity = 'success', title = '', message = '', handleClose }) => {
  // Atleast one of title or message should not be empty to display alerts
  if (!(title || message))
    return (<></>)
  return (
    <AlertBanner
      title={title}
      message={message}
      open={open}
      handleClose={handleClose}
      severity={severity}
    />
  );
};

export default connect((state) => state.alert, {
  handleClose: () => alertAction({ open: false }),
})(Alert);
