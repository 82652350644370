import styled from 'styled-components';
import { Header3, Colors } from '@easyknockorg/ek-ui-components';

export const ClientAddress = styled.div`
  display: flex;
  color: ${Colors.BGW.charcoal1};
  font-size: 16px;
  margin-top: 20px;
`;

export const ClientContact = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HouseIcon = styled.img`
  margin-right: 11px;
  font-size: 4px;
`;

export const RoundButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledHeader3 = styled(Header3)`
  margin: 0;
  cursor: pointer;
  color: ${Colors.BGW.outerSpace};
  font-size: 24px;
`;
