import { Colors, Header4, LabelMedium } from '@easyknockorg/ek-ui-components';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState } from 'react';
import { useDealContext } from '../../../contexts/DealContext';
import { runFormulas } from '../../../formulas/services/formulas';
import warningIcon from '../../../images/exclamation.svg';
import { currencySetStateHelper, numberWithCommas } from '../../../utils';
import ActionButton from '../../Atoms/ActionButton';
import ActionButtons from '../../Molecules/ActionButtons';
import { DialogSubtitle, StyledDialog, StyledTextField } from './styles';

import { InputAdornment } from '@material-ui/core';

const FormDialog = ({
  fields,
  onSubmit,
  open,
  setOpen,
  submitLabel = 'Submit',
  title,
  helperText,
}) => {
  const {
    alternateCF,
    alternateRent,
    createAlternateSnapshot,
    dealData,
    setAlternateCF,
    setAlternateRent,
    optionName,
    userData,
  } = useDealContext();
  const [loading, setLoading] = useState(false);
  const { cashFunding, purchasePrice, monthlyRent, lowerMonthlyRent, upperMonthlyRent } =
    dealData || {};
  const { label, maxLength, multiline, value, setValue, subtitle } = fields[0];

  const isException = optionName === 'Exception Estimate' && title === 'Create New Snapshot';
  const dataModified = alternateCF !== cashFunding || alternateRent !== monthlyRent;
  const alternateCFError =
    alternateCF > 0.85 * purchasePrice
      ? {
          status: true,
          helperText: `The desired cash should generally be below 85% of the home value in this case $${numberWithCommas(
            0.85 * purchasePrice,
          )}`,
        }
      : { status: false, helperText: null };
  const alternateRentError =
    lowerMonthlyRent !== 0 &&
    upperMonthlyRent !== 0 &&
    (alternateRent < lowerMonthlyRent || alternateRent > upperMonthlyRent)
      ? {
          status: true,
          helperText: `The desired rent should generally be within House Canary ranges (high: $${numberWithCommas(
            upperMonthlyRent,
          )}; low: $${numberWithCommas(lowerMonthlyRent)})`,
        }
      : { status: false, helperText: null };

  const onClose = () => {
    setOpen(false);
    clearFields();
  };
  const onSubmitFull = async () => {
    // Show spinner until submission is complete
    setLoading(true);

    //check if data is modified so that we can rerun the formulas if we need to
    if (isException && dataModified) {
      const newDeal = {
        ...dealData,
        cashFunding: alternateCF,
        createdBy: userData?.email,
        dealOwnerRole: userData?.role,
        desiredCashout: alternateCF,
        desiredRent: alternateRent,
        optionSelected: optionName,
        monthlyRent: alternateRent,
      };
      const calculatedValues = runFormulas(newDeal, userData);
      calculatedValues.snapshotName = value;
      await createAlternateSnapshot(userData, calculatedValues);
      setOpen(false);
      setLoading(false);
      clearFields();
      return;
    } else {
      await onSubmit();
      setOpen(false);
      setLoading(false);
      clearFields();
    }
  };

  const clearFields = () => {
    fields.forEach((field) => {
      field.setValue('');
    });
  };

  const disabled =
    value?.trim().length === 0 ||
    loading ||
    userData == undefined ||
    userData.role == undefined ||
    userData.email == undefined;

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div>
        <DialogTitle>
          {isException && (
            <LabelMedium style={{ margin: 0, color: Colors.PineGreen.default }}>
              ALTERNATE OPTION
            </LabelMedium>
          )}
          <Header4 style={{ margin: 0 }}>{title}</Header4>
        </DialogTitle>
        <DialogSubtitle>{subtitle}</DialogSubtitle>
      </div>
      <DialogContent>
        <StyledTextField
          key={label}
          autoFocus
          fullWidth
          inputProps={{ maxLength: maxLength || null }}
          label={label}
          multiline={multiline}
          onChange={(event) => setValue(event.target.value)}
          rows={multiline ? 4 : 1}
          type="text"
          value={value}
          variant="outlined"
          helperText={helperText ?? ''}
        />
        {isException && (
          <>
            <StyledTextField
              style={{ marginTop: '36px' }}
              key="cfAlternate"
              fullWidth
              inputProps={{
                onBlur: () => {
                  currencySetStateHelper(alternateCF, setAlternateCF, false);
                },
              }}
              label="Desired Cash"
              onChange={(event) => currencySetStateHelper(event.target.value, setAlternateCF)}
              type="text"
              value={numberWithCommas(alternateCF)}
              variant="outlined"
              helperText={alternateCFError.helperText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {alternateCFError.status && <img src={warningIcon} alt="warning" />}
                  </InputAdornment>
                ),
              }}
            />
            <StyledTextField
              style={{ marginTop: '36px' }}
              key="rentAlternate"
              fullWidth
              inputProps={{
                onBlur: () => {
                  currencySetStateHelper(alternateRent, setAlternateRent, false);
                },
              }}
              label="Desired Rent"
              onChange={(event) => currencySetStateHelper(event.target.value, setAlternateRent)}
              type="text"
              value={numberWithCommas(alternateRent)}
              variant="outlined"
              helperText={alternateRentError.helperText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {alternateRentError.status && <img src={warningIcon} alt="warning" />}
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
      </DialogContent>

      <DialogActions>
        {isException && <ActionButton value="Cancel" handleClick={onClose} />}

        <ActionButtons
          onSubmit={onSubmitFull}
          submitLabel={submitLabel}
          disabled={disabled}
          loadingSpinner={loading}
        />
      </DialogActions>
    </StyledDialog>
  );
};

export default FormDialog;
