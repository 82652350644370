import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import PrimaryFlag from '../../Atoms/PrimaryFlag';
import { Text } from './styles';
import GoToSnapshot from './GoToSnapshot';

const Dropdown = ({ dealData = {}, value = {}, setValue = {} }) => {
  const snapshots = dealData.snapshots || [];
  const { dealId: parentDealId } = snapshots.slice(-1)[0] || {};
  const [inputValue, setInputValue] = useState(value?.name || 'Default');
  const isSnapshot = !!value?.name;

  return (
    <Autocomplete
      id="snapshot-dropdown"
      data-test-id="snapshot-dropdown"
      disableClearable
      value={value}
      onChange={(event, newValue) => setValue(newValue)}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      options={snapshots}
      getOptionLabel={(option) => option?.name || 'Default'}
      ListboxProps={{ style: { maxHeight: 284, overflow: 'auto' } }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Snapshot"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            startAdornment: (value || inputValue === 'Default') && (
              <InputAdornment position="start">
                <Text>{value?.arrayIdx || 0} | </Text>
              </InputAdornment>
            ),
            endAdornment: (
              <div style={{ marginRight: '-8px' }}>
                {isSnapshot && value?.isPrimaryDeal && <PrimaryFlag />}
                {params.InputProps.endAdornment}
              </div>
            ),
          }}
        />
      )}
      renderOption={(snapshot) => {
        return <GoToSnapshot snapshot={{ ...snapshot, parentDealId }} />;
      }}
      style={{ display: 'inline-block', minWidth: '90%' }}
    />
  );
};

export default Dropdown;
