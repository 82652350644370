import apiClient from './apiClient';
import { UnderwritingStatusEnum } from './constansts';
import { UserRole } from '../formulas/dto/userDto';

export const lessThan = (otherField) => (value, previousValue, allValues) =>
  value <= allValues[otherField] ? value : previousValue;

export const greaterThan = (otherField) => (value, previousValue, allValues) =>
  value > allValues[otherField] ? value : previousValue;

export const toLower = (str) => str.charAt(0).toLowerCase() + str.slice(1);

export const numberWithCommas = (number) =>
  (number || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const validFloatingPointNumber = new RegExp(/^\d*\.?\d{0,2}$/);

export const formatNumberToString = (stringOrNumber) =>
  stringOrNumber
    ?.toString()
    .replace(/[,$a-zA-Z]/g, '')
    .trim();

const addEventListener = window.addEventListener || window.attachEvent;
const beforeUnloadEvent =
  addEventListener === window.attachEvent ? 'onbeforeunload' : 'beforeunload';

export const formatPrice = (value, noSpace = false) => {
  if (Number.isNaN(Number(value))) {
    return `$${noSpace ? '' : ' '}0`;
  }

  return `$${noSpace ? '' : ' '}${Number(value).toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

export const execBeforeTabCloses = (cb) => {
  addEventListener(beforeUnloadEvent, (e) => {
    e.preventDefault();
    const confirmationMessage = 'Are you sure to leave the page?';
    (e || window.event).returnValue = confirmationMessage;
    cb();
    return confirmationMessage;
  });
};

export const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const isValidEmailOrEmpty = (email) => !email || isValidEmail(email);

export const DealTypesEnum = {
  SellAndStay: 1,
  MoveAbility: 2,
  ReLease: 3,
  ReLeaseNonEK: 4,
};

export const DocumentType = {
  Addendum: {
    type: 'Addendum',
    filter: 'Addendum',
    recipients: ['Jarred Kessler', 'client', 'Client', 'Sales Rep'],
    silentSent: false,
    sendAfterCreation: true,
    docSentField: '',
  },
  Lease: {
    type: 'Lease Agreement',
    filter: 'Lease',
    recipients: ['Jarred Kessler', 'client', 'Client', 'Sales Rep'],
    silentSent: false,
    sendAfterCreation: false,
    docSentField: 'laSent',
  },
  LOI: {
    type: 'Letter of Intent',
    filter: 'LOI',
    recipients: ['Jarred Kessler', 'client', 'Client', 'Sales Rep'],
    silentSent: false,
    sendAfterCreation: true,
    docSentField: 'loiSent',
  },
  Purchase: {
    type: 'Purchase Agreement',
    filter: 'Purchase',
    recipients: ['Jarred Kessler', 'client', 'Client', 'Sales Rep'],
    silentSent: false,
    sendAfterCreation: true,
    docSentField: 'paSent',
  },
  Service: {
    type: 'Service Agreement',
    filter: 'Service',
    recipients: ['Jarred Kessler', 'client', 'Client', 'Sales Rep'],
    silentSent: false,
    sendAfterCreation: true,
    docSentField: 'saSent',
  },
  Triparty: {
    type: 'Triparty Agreement',
    filter: 'Triparty',
    recipients: ['Jarred Kessler', 'client', 'Client', 'Sales Rep'],
    silentSent: false,
    sendAfterCreation: true,
    docSentField: 'tpSent',
  },
};

export const SolutionName = {
  1: 'Sell & Stay',
  2: 'MoveAbility',
  3: 'ReLease',
  4: 'ReLease Non EK',
};

export const isVRevamp = (dealData) => {
  return dealData?.VersionId >= 18;
};

export const isSellStayV6AndAbove = (dealData) => {
  return dealData.DealTypeId == DealTypesEnum.SellAndStay && dealData.VersionId >= 6;
};

export const isMoveAbilityV6AndAbove = (dealData) => {
  return dealData.DealTypeId == DealTypesEnum.MoveAbility && dealData.VersionId >= 6;
};

export const isMoveAbilityV20AndAbove = (dealData) => {
  return dealData.DealTypeId == DealTypesEnum.MoveAbility && dealData.VersionId >= 20;
};

export const updateDealOnDB = (deal) => {
  try {
    apiClient.deal.updateDealFields(deal.id, deal);
  } catch (e) {
    console.error('Could not update deal: ', e);
  }
};

export const getDealCreationMsg = (dealData, userData) => {
  const msgObj = { title: '', message: '' };
  const defaultMsg =
    'Your deal has been created in Salesforce. Please email the estimate to your client before creating the LOI.';

  if (userData?.role === UserRole.Underwriting) {
    msgObj.title = defaultMsg;
  } else if (dealData.estimateEmailed) {
    msgObj.title = 'The estimate has been sent. You can now send the LOI.';
    msgObj.message = defaultMsg; // TODO: Remove this line after hard launch
    // TODO: Uncomment line below after hard launch
    //msgObj.message = dealData.loiSent ? defaultMsg : `${defaultMsg} You can now send the LOI.`;
  } else {
    msgObj.title = defaultMsg;
  }
  return msgObj;
};

export const getDocumentData = async (dealData) => {
  let docData = { documents: [], templates: {} };
  try {
    docData = await apiClient.document.get(dealData?.id);
    return docData;
  } catch (err) {
    console.error(`Error getting documents while refreshing: ${err}`);
    return docData;
  }
};

export const isDocumentExpired = (document) => {
  if (document?.status === 'document.voided') {
    return true;
  }

  if (!document || !document.expirationDate || Object.keys(document).length === 0) {
    return false;
  }

  const expirationDate = new Date(document.expirationDate);
  const now = new Date();

  if (now >= expirationDate) {
    return true;
  }

  return false;
};

export const isDocumentDeclined = (document) => {
  return document?.status === 'document.declined';
};

export const getPrimaryDeal = (dealData) =>
  dealData?.snapshots?.find((snapshot) => snapshot.isPrimaryDeal);

export const generateUwStatusLabel = (underwritingStatus) => {
  switch (underwritingStatus) {
    case UnderwritingStatusEnum.Unassigned:
    case UnderwritingStatusEnum.New:
    case UnderwritingStatusEnum.InReview:
    case UnderwritingStatusEnum.Reworked:
    case UnderwritingStatusEnum.Escalated:
    case UnderwritingStatusEnum.SentToLender:
      return 'UW Queue';
    case UnderwritingStatusEnum.Approved:
      return 'Approved';
    case UnderwritingStatusEnum.Declined:
      return 'Declined';
    default:
      return null;
  }
};

export const calcCashFundingPercentage = (cashFunding, purchasePrice) => {
  return cashFunding && purchasePrice ? Math.round((cashFunding / purchasePrice) * 100) : 0;
};

export const activityModalInfo = (
  laActivityOpen,
  setLaActivityOpen,
  loiActivityOpen,
  setLoiActivityOpen,
  paActivityOpen,
  setPaActivityOpen,
  saActivityOpen,
  setSaActivityOpen,
  tpActivityOpen,
  setTpActivityOpen,
) => [
  {
    documentType: DocumentType.Lease,
    title: 'Lease Agreement Activity',
    open: laActivityOpen,
    setOpen: setLaActivityOpen,
    submitLabel: 'View LA',
  },
  {
    documentType: DocumentType.LOI,
    title: 'Letter of Intent Activity',
    open: loiActivityOpen,
    setOpen: setLoiActivityOpen,
    submitLabel: 'View LOI',
  },

  {
    documentType: DocumentType.Purchase,
    title: 'Purchase Agreement Activity',
    open: paActivityOpen,
    setOpen: setPaActivityOpen,
    submitLabel: 'View PA',
  },
  {
    documentType: DocumentType.Service,
    title: 'Service Agreement Activity',
    open: saActivityOpen,
    setOpen: setSaActivityOpen,
    submitLabel: 'View SA',
  },
  {
    documentType: DocumentType.Triparty,
    title: 'Triparty Agreement Activity',
    open: tpActivityOpen,
    setOpen: setTpActivityOpen,
    submitLabel: 'View TP',
  },
];

export const documentModalInfo = (
  createLA,
  setCreateLA,
  createLOI,
  setCreateLOI,
  createPA,
  setCreatePA,
) => [
  {
    docType: DocumentType.Lease,
    title: 'Create Lease Agreement',
    submitLabel: 'Create LA',
    open: createLA,
    setOpen: setCreateLA,
  },
  {
    docType: DocumentType.LOI,
    title: 'Create Letter of Intent',
    submitLabel: 'Create LOI',
    open: createLOI,
    setOpen: setCreateLOI,
  },
  {
    docType: DocumentType.Purchase,
    title: 'Create Purchase Agreement',
    submitLabel: 'Create PA',
    open: createPA,
    setOpen: setCreatePA,
  },
];

export const dealHasHouseCanaryData = (dealData) => {
  return (
    !!dealData.lowerMonthlyRent &&
    !!dealData.medianMonthlyRent &&
    !!dealData.upperMonthlyRent &&
    !!dealData.lowerPropertyValue &&
    !!dealData.medianPropertyValue &&
    !!dealData.upperPropertyValue
  );
};

export const validateDeal = (fieldsToValidate, dealData) => {
  let fields = fieldsToValidate;
  let invalidField = null;

  for (let i = 0; i < fields.length; i++) {
    const currentField = fields[i];
    const dealField = dealData[currentField];

    if (!dealField) {
      invalidField = currentField;
      break;
    }
  }
  return invalidField;
};

export const isTriPartyValid = (docType, ownersAgent) => {
  if (docType.type !== DocumentType.Triparty.type) return true;
  if (ownersAgent) return true;
  return false;
};

export const matchesDefault = (dealDataA, dealDataB, fieldsToCompare) => {
  for (const field of fieldsToCompare) {
    if (dealDataA[field] !== dealDataB[field]) {
      return false;
    }
  }
  return true;
};

export const automaticSfRefresh = async (dealData, setDealData, search) => {
  // This call will refresh from SF and update the primary deal on the DB
  const res = await apiClient.salesforce.get(dealData.opportunityId, search, true);
  const fetchedDeal = res?.deal;
  // We need to get the entire deal. Above endpoint call does not return snapshots array.
  const refreshedDeal = await apiClient.deal.get(fetchedDeal?.id);

  if (
    dealData.isPrimaryDeal !== refreshedDeal.isPrimaryDeal &&
    refreshedDeal.isPrimaryDeal != null
  ) {
    let tempDealData = {
      ...dealData,
      isPrimaryDeal: refreshedDeal.isPrimaryDeal,
      snapshots: refreshedDeal.snapshots,
    };
    setDealData(tempDealData);
  }
};

export const convertStringToNumber = (value, allowDecimal = true) => {
  if (value === undefined || value === null) return value;
  if (typeof value !== 'string') return value;
  if (value === '') return null;

  const trimmedString = value.replace(/[^\d.-]/g, '');

  const lastIsDecimal = trimmedString[trimmedString.length - 1] === '.';
  const lastIsDecimalZero =
    trimmedString[trimmedString.length - 1] === '0' &&
    trimmedString[trimmedString.length - 3] === '.';

  if (allowDecimal && (lastIsDecimal || lastIsDecimalZero)) return trimmedString;
  const parsedNumber = parseFloat(trimmedString);

  return parsedNumber;
};

export const currencySetStateHelper = (newValue, setState, allowDecimal = true) => {
  if (typeof newValue === 'string') {
    const numbersAfterDecimal = newValue?.split('.')[1];

    if (numbersAfterDecimal?.length > 2) {
      return;
    }
    setState(convertStringToNumber(newValue, allowDecimal));
  } else if (typeof newValue === 'number') {
    setState(newValue);
  } else {
    return;
  }
};
