import React, { useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import apiClient from '../../../utils/apiClient';
import { StyledDialog, DialogSubtitle } from '../FormDialog/styles';
import {
  TextButton,
  TableCellHead,
  TableCellBody,
  StyledDialogActions,
  ExpiredNote,
} from './styles';
import { Header4, IconButton } from '@easyknockorg/ek-ui-components';
import ActionButtons from '../../Molecules/ActionButtons';
import {
  DocumentType,
  getDocumentData,
  isDocumentDeclined,
  isDocumentExpired,
} from '../../../utils';
import AlertBanner from '../../Atoms/AlertBanner';
import SimpleModal from '../SimpleModal';
import { CustomRadio } from '../../../pages/DealCalculator/components/OfferCards/styles';
import { onError, onWarning, onSuccess } from '../../../utils/snackbar';
import { LoadingSpinner } from '../../Atoms/LoadingSpinner';

const ActivityModal = ({
  title,
  documentType,
  subtitle,
  open,
  setOpen,
  dealData,
  setDealData,
  userData,
  type,
  setHasLOIs,
  submitLabel,
}) => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentObj, setDocumentObj] = useState({});
  const [documents, setDocuments] = useState([]);
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const [estimateData, setEstimateData] = useState({ events: [] });
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expirationInProgress, setExpirationInProgress] = useState(false);

  let activeRadio = null;

  useEffect(async () => {
    if (dealData?.snapshotName) {
      try {
        if (type === 'document') {
          setLoading(true);
          const docData = await getDocumentData(dealData);
          const filteredDocs = docData.documents.filter(
            (document) => document.type === documentType.type,
          );
          setDocuments(filteredDocs);
          setLoading(false);
        } else if (type === 'email') {
          const estimateEventData = await apiClient.estimate.getEvents(dealData?.id);
          setEstimateData(estimateEventData);
        }
      } catch (e) {
        onError(setSnackbarState, `Error getting data: ${JSON.stringify(e)}`);
      }
    }
  }, [dealData]);

  useEffect(() => {
    if (documentType?.type === 'Letter of Intent') {
      if (documents?.length > 0) {
        setHasLOIs(true);
      } else {
        setHasLOIs(false);
      }
    }
  }, [documents]);

  const onClose = () => {
    setOpen(false);

    if (type === 'document') {
      setSelectedDocument(null);
    }
  };

  const onGeneratePreview = async () => {
    const payload = {
      receiptEmail: dealData?.email,
    };

    let res;
    try {
      setLoading(true);
      res = await apiClient.document.postLink(selectedDocument, payload);
    } catch (err) {
      res = err;
    }
    if (res.success === true) {
      window.open(res.linkURL, 'Document Preview');
    } else {
      onClose();
      onWarning(setSnackbarState, 'Could not generate preview', res.message);
    }
    setLoading(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ open: false });
  };

  const renderHead = () => {
    let head;
    if (type === 'document') {
      head = [' ', 'Title', 'Approver / Recipients', 'Time Created'];
    } else if (type === 'email') {
      head = ['Name', 'Time', 'Email', 'Type'];
    }

    return (
      <TableRow>
        {head.map((title, idx) => (
          <TableCellHead key={idx}>{title}</TableCellHead>
        ))}
      </TableRow>
    );
  };

  const renderBody = () => {
    if (type === 'document') {
      return (
        documents &&
        documents.map((document) => {
          activeRadio = selectedDocument === document.id.toString();
          return (
            <TableRow key={document.id}>
              <TableCell>
                {!isDocumentExpired(document) && !isDocumentDeclined(document) && (
                  <CustomRadio
                    checked={activeRadio}
                    name="selected-document"
                    onChange={(event) => {
                      setSelectedDocument(event.target.value);
                      setDocumentObj(document);
                    }}
                    value={document.id}
                  />
                )}
              </TableCell>
              <TableCellBody bold>
                {document.name}
                {isDocumentExpired(document) ? (
                  <ExpiredNote>Expired</ExpiredNote>
                ) : isDocumentDeclined(document) ? (
                  <ExpiredNote>Declined</ExpiredNote>
                ) : null}
              </TableCellBody>
              <TableCellBody>
                {document.recipients.map((recipient) => recipient.email).join(',\n')}
              </TableCellBody>
              <TableCellBody>{new Date(document.createdAt).toLocaleString()}</TableCellBody>
            </TableRow>
          );
        })
      );
    } else if (type === 'email') {
      return estimateData?.events?.map((estimateEvent) => (
        <TableRow key={estimateEvent.timestamp}>
          <TableCellBody>{estimateEvent.event}</TableCellBody>
          <TableCellBody>{new Date(estimateEvent.timestamp).toLocaleString()}</TableCellBody>
          <TableCellBody>{estimateEvent.email}</TableCellBody>
          <TableCellBody>{estimateEvent.type}</TableCellBody>
        </TableRow>
      ));
    }
  };

  const onExpire = async () => {
    setExpirationInProgress(true);
    const newStatus = 'document.voided';
    const pandaDocId = documentObj.pandaDocId;
    const response = await apiClient.document.status(pandaDocId, newStatus, dealData.id);
    if (response?.data?.success) {
      setDealData(response.data.data);
      onSuccess(setSnackbarState, response?.data?.message);
    } else {
      onError(setSnackbarState, response?.data?.message);
    }
    onClose();
    setOpenConfirmation(false);

    if (selectedDocument) {
      setSelectedDocument(null);
    }
    setExpirationInProgress(false);
  };

  const isDisabled =
    selectedDocument === null ||
    isDocumentExpired(documentObj) ||
    isDocumentDeclined(documentObj) ||
    loading ||
    expirationInProgress;
  return (
    <>
      <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>
            <Header4 style={{ margin: 0 }}>{title}</Header4>
          </DialogTitle>
          <IconButton key="x" icon="x" onClick={onClose} />
        </div>
        {subtitle && <DialogSubtitle>{subtitle}</DialogSubtitle>}
        <DialogContent>
          <Table>
            <TableHead>{renderHead()}</TableHead>
            <TableBody>{renderBody()}</TableBody>
          </Table>
          {type === 'document' && (
            <StyledDialogActions>
              {(documentType?.type === DocumentType.LOI.type ||
                documentType?.type === DocumentType.Triparty.type ||
                documentType?.type === DocumentType.Purchase.type) && (
                <TextButton
                  onClick={() => setOpenConfirmation(true)}
                  disabled={isDisabled}
                  className={!selectedDocument || expirationInProgress ? 'disabled' : ''}
                >
                  Expire {documentType?.type}
                </TextButton>
              )}
              <ActionButtons
                onSubmit={onGeneratePreview}
                submitLabel={loading ? <LoadingSpinner /> : submitLabel}
                disabled={isDisabled}
              />
            </StyledDialogActions>
          )}
        </DialogContent>
      </StyledDialog>
      <AlertBanner
        handleClose={handleCloseSnackbar}
        title={snackbarState.title}
        message={snackbarState.message}
        open={snackbarState.open}
        severity={snackbarState.severity}
      />
      <SimpleModal
        title="Expire this document?"
        subtitle="Expiring this document will allow you to create a new one."
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        submitLabel="Yes, expire"
        onSubmit={onExpire}
        loading={expirationInProgress}
      />
    </>
  );
};

export default ActivityModal;
