import React, { useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { StyledDialog, DialogSubtitle } from '../FormDialog/styles';
import { Header4, IconButton } from '@easyknockorg/ek-ui-components';
import ActionButtons from '../../Molecules/ActionButtons';
import { SpinnerOverlay } from '@easyknockorg/ek-ui-components';

const SimpleModal = ({ title, subtitle, open, setOpen, submitLabel, onSubmit, loading }) => {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <StyledDialog open={open} onClose={onClose} maxWidth="md">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <DialogTitle>
            <Header4 style={{ margin: 0 }}>{title}</Header4>
          </DialogTitle>
          <IconButton key="x" icon="x" size="22" onClick={onClose} />
        </div>
        {subtitle && <DialogSubtitle>{subtitle}</DialogSubtitle>}
        <DialogActions>
          <ActionButtons
            onSubmit={onSubmit}
            submitLabel={submitLabel}
            onCancel={onClose}
            cancelLabel="Cancel"
            loadingSpinner={loading}
          />
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default SimpleModal;
