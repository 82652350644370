import React from 'react';
import { Field } from 'redux-form';
import Select from '../../../../components/ReduxFormWrapers/Select';

const SelectInput = ({ k, style, options, label, fullWidth = true, disabled = false }) => (
  <Field
    options={options}
    fullWidth={fullWidth}
    style={style}
    name={k}
    label={label}
    component={Select}
    placeholder=""
    disabled={disabled}
  />
);

export default SelectInput;
