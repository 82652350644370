import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import accounting from 'accounting';
import TextField from '../../../../components/ReduxFormWrapers/TextField';
import Slider from '../Slider';
import Percent from '../Percent';
import ReloadIcon from '../../containers/ReloadIcon';

const unFormatMoney = (amount) => accounting.unformat(amount);
const formatMoney = (amount) => accounting.formatMoney(amount, '$ ', 0);

const lessThan = (maxValue) => (value, previousValue) => value <= maxValue ? value : maxValue;

const TextInput = ({
  format,
  parse,
  hasWarningIcon,
  hasWarn,
  label,
  fullWidth,
  style,
  k,
  max,
  min,
  normalize,
  slider,
  percent,
  overridable,
  tooltip = null,
  disabled = false,
  formatAsMoney = false,
  errorFormula,
  ...rest
}) => (
  <>
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        ...style,
      }}
    >
      <Field
        hasWarningIcon={hasWarningIcon}
        hasWarn={hasWarn}
        fullWidth={disabled || !(overridable || percent)}
        format={formatAsMoney ? formatMoney : format}
        parse={formatAsMoney ? unFormatMoney : parse}
        name={k}
        label={label}
        component={TextField}
        min={min}
        normalize={max >= 0 ? lessThan(max) : (value) => value}
        placeholder=""
        tooltip={tooltip}
        errorFormula={errorFormula}
        disabled={disabled}
        style={{ width: '100%' }}
      />
      {percent && (
        <Percent
          style={{
            margin: '8px 4px 0px 4px',
            width: '20%',
          }}
          {...percent}
        />
      )}
      {overridable && !disabled && (
        <ReloadIcon
          style={{
            marginLeft: `${percent ? '0px' : '16px'}`,
            marginTop: '8px',
          }}
          field={k}
        />
      )}
    </div>
    <div>
      {slider && (
        <div
          style={{
            flexBasis: '100%',
            height: 0,
          }}
        />
      )}
      {slider && (
        <Slider
          {...{
            ...slider,
            k,
            max,
            min,
            disabled,
          }}
        />
      )}
    </div>
  </>
);

const mapStateToProps = (state, ownProps) => {
  return {
    hasWarn: !!_.get(state, `form.dealCalculator.syncErrors.${ownProps.k}`),
  };
};
export default connect(mapStateToProps)(TextInput);
