import React, { useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import infoIcon from '../../../../../images/info.svg';

const StyledPopover = withStyles(() => ({
  paper: {
    backgroundColor: '#f5f5f9',
    border: '1px solid #dadde9',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  root: {
    marginLeft: '25px',
  },
}))(Popover);

const InfoAdornment = ({ Tooltip, dealData = null }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <InputAdornment position="end">
        <img
          alt="info"
          onClick={handleClick}
          src={infoIcon}
          style={{ cursor: 'pointer' }}
          height="18px"
          width="18px"
        />
      </InputAdornment>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Tooltip passedDealData={dealData} />
      </StyledPopover>
    </>
  );
};

export default InfoAdornment;
