import React from 'react';
import { Link } from 'react-router-dom';
import PrimaryFlag from '../../Atoms/PrimaryFlag';
import { LinkContainer, LinkContent, Text } from './styles';

const GoToSnapshot = ({ snapshot }) => {
  const date = new Date(snapshot.createdAt);
  const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear() - 2000}`;
  const isSnapshot = !!snapshot.name;

  return (
    <div style={{ width: '100%' }}>
      <Link
        style={{ textDecoration: 'none' }}
        to={
          snapshot.name === null
            ? `/deal-calculator-revamp/${snapshot.dealId}`
            : `/deal-calculator-revamp/${snapshot.parentDealId}/snapshot/${snapshot.dealId}`
        }
      >
        <LinkContainer>
          <LinkContent>
            <Text>{snapshot.arrayIdx} | </Text>
            <div style={{ marginLeft: '8px' }}>
              <Text color="#454B4E">{snapshot.name || 'Default'}</Text>
              <Text fontSize="13px">{formattedDate || ''}</Text>
            </div>
          </LinkContent>
          {isSnapshot && snapshot?.isPrimaryDeal && <PrimaryFlag />}
        </LinkContainer>
      </Link>
    </div>
  );
};

export default GoToSnapshot;
