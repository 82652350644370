import _ from 'lodash';
import diff from 'shallow-diff';
import { runFormulas } from '../../formulas/services/formulas';
import { dealFormSections } from './sectionFieldsMap';
import {
  BANNER_ACTION,
  BIND_FIELD_TO_FORMULA,
  CLONING,
  ESTIMATE_EMAIL_SENT,
  LOAD_DOCUMENT_DATA,
  LOAD_ESTIMATE_EVENT_DATA,
  LOAD_SALESFORCE_REFETCH,
  LOGIN_SUCCESS,
  LOGOUT,
  REDUX_FORM_ARRAY_REMOVE,
  REDUX_FORM_CHANGE,
} from './constants';

const calculateValues = (deal = {}, user = {}) => runFormulas(deal, user);

const fieldsConfig = (user) =>
  dealFormSections({}, user).reduce(
    (accumu, { fields }) => ({
      ...accumu,
      ...fields.reduce(
        (acc, { k: key, label, overridable }) => ({
          ...acc,
          [key]: { label, overridable },
        }),
        {},
      ),
    }),
    {},
  );

const fieldIsOverridable = (field, user) =>
  _.get(fieldsConfig(user), `${field}.overridable`, false);

const onFieldChange = (state, action) => {
  const staticFields = _.get(state, 'dealCalculator.values.staticFields', {}).filter(
    (field) => field,
  );
  const changedField = action.meta.field;
  const userData = _.get(state, 'user.data', {});

  // override field formula if it should be overriden on change
  if (!staticFields.includes(changedField) && fieldIsOverridable(changedField, userData)) {
    _.set(state, `dealCalculator.values.staticFields`, [...staticFields, changedField]);
  }

  const calculatedValues = calculateValues(state.dealCalculator.values, userData);

  const fieldsUpdated = diff(state.dealCalculator.values, calculatedValues)
    .updated.filter(
      (uf) => changedField !== uf && _.get(fieldsConfig(userData), `${uf}.overridable`, false),
    )
    .map((uf) => fieldsConfig(userData)[uf].label);

  const fieldsNotUpdated = staticFields
    .filter((field) => changedField !== field)
    .map((field) => fieldsConfig(userData)[field].label);

  const isSnackBarVisible =
    fieldsUpdated.length && (changedField === 'purchasePrice' || changedField === 'DealTypeId');

  return {
    ...state,
    snackBar: {
      visible: isSnackBarVisible,
      updatedFieldLabel: isSnackBarVisible && fieldsConfig(userData)[changedField].label,
      fieldsUpdated,
      fieldsNotUpdated,
    },
    dealCalculator: {
      ...state.dealCalculator,
      values: {
        ...state.dealCalculator.values,
        ...calculatedValues,
      },
    },
  };
};

const onLoadDocumentData = (state, action) => {
  return {
    ...state,
    documentData: {
      ...action.payload,
      loaded: true,
    },
  };
};

const onLoadEstimateEventData = (state, action) => {
  return {
    ...state,
    estimateEventData: {
      ...action.payload,
      loaded: true,
    },
  };
};

const onLoadSalesforceRefetch = (state, action) => {
  return {
    ...state,
    dealCalculator: {
      ...state.dealCalculator,
      values: {
        ...state.dealCalculator.values,
        ...action.payload,
      },
    },
  };
};

const onBindFieldToFormula = (state, action) => {
  const field = action.payload;
  const staticFields = _.get(state, 'dealCalculator.values.staticFields', []).filter(
    (sf) => sf !== field,
  );
  const userData = _.get(state, 'user.data', {});

  return {
    ...state,
    dealCalculator: {
      ...state.dealCalculator,
      values: {
        ...calculateValues(
          {
            ...{ ...state.dealCalculator.values, staticFields },
          },
          userData,
        ),
      },
    },
  };
};

const onBannerAction = (state, action) => {
  return {
    ...state,
    snackBar: { ...state.snackBar, visible: action.payload === 'open' },
  };
};

const onLoginSuccess = (state, action) => {
  localStorage.setItem('token', action.payload.accessToken);
  localStorage.setItem('userData', JSON.stringify(action.payload.data));
  return { ...state, user: action.payload };
};

const onLogout = (state) => {
  localStorage.removeItem('token');
  localStorage.removeItem('userData');
  window.location.replace('/');
  return { ...state, user: null };
};

const onEstimateEmailSent = (state) => ({
  ...state,
  dealCalculator: {
    ...state.dealCalculator,
    values: {
      ...state.dealCalculator.values,
      dealStatus: 'Proposal',
      estimateEmailed: true,
    },
  },
});

const onSetCloning = (state, action) => ({
  ...state,
  cloning: !!action.payload,
});

const actionListeners = {
  [BANNER_ACTION]: onBannerAction,
  [BIND_FIELD_TO_FORMULA]: onBindFieldToFormula,
  [CLONING]: onSetCloning,
  [ESTIMATE_EMAIL_SENT]: onEstimateEmailSent,
  [LOAD_DOCUMENT_DATA]: onLoadDocumentData,
  [LOAD_ESTIMATE_EVENT_DATA]: onLoadEstimateEventData,
  [LOAD_SALESFORCE_REFETCH]: onLoadSalesforceRefetch,
  [LOGIN_SUCCESS]: onLoginSuccess,
  [LOGOUT]: onLogout,
  [REDUX_FORM_ARRAY_REMOVE]: onFieldChange,
  [REDUX_FORM_CHANGE]: onFieldChange,
};

export default (state = {}, action) => {
  const listener = actionListeners[action.type];
  return listener ? listener(state, action) : state;
};
