var ErrorCode;
(function (ErrorCode) {
    ErrorCode["CONTACT_ROLE_NOT_FOUND"] = "CONTACT_ROLE_NOT_FOUND";
    ErrorCode["DEAL_NOT_FOUND"] = "DEAL_NOT_FOUND";
    ErrorCode["SALESFORCE_MISSING_CITY"] = "SALESFORCE_MISSING_CITY";
    ErrorCode["SALESFORCE_MISSING_EMAIL"] = "SALESFORCE_MISSING_EMAIL";
    ErrorCode["SALESFORCE_MISSING_FIRST_NAME"] = "SALESFORCE_MISSING_FIRST_NAME";
    ErrorCode["SALESFORCE_MISSING_LAST_NAME"] = "SALESFORCE_MISSING_LAST_NAME";
    ErrorCode["SALESFORCE_MISSING_STATE"] = "SALESFORCE_MISSING_STATE";
    ErrorCode["SALESFORCE_MISSING_STREET"] = "SALESFORCE_MISSING_STREET";
    ErrorCode["SALESFORCE_MISSING_ZIP"] = "SALESFORCE_MISSING_ZIP";
    ErrorCode["SALESFORCE_OPP_NOT_FOUND"] = "SALESFORCE_OPP_NOT_FOUND";
    ErrorCode["UNKNOWN"] = "UNKNOWN";
    ErrorCode["USER_ID_MISMATCH"] = "USER_ID_MISMATCH";
    ErrorCode["VERSION_ID_UNSUPPORTED"] = "VERSION_ID_UNSUPPORTED";
    ErrorCode["VERSION_EXPIRED"] = "VERSION_EXPIRED";
})(ErrorCode || (ErrorCode = {}));
export default ErrorCode;
