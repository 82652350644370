import styled from 'styled-components';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { Colors } from '@easyknockorg/ek-ui-components';

export const StyledFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    margin-top: ${({ $docModalSpacing }) => ($docModalSpacing ? '16px' : 0)};
  }
`;
export const StyledInputLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    font-size: 13px;
    color: ${Colors.BGW.grey3};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-size: 16px;
    color: #454b4e;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    padding: 14px 16px;
  }
`;
