import {
  Colors,
  Header4,
  Header5,
  RadioButtons,
  TextBodyCopySmall,
} from '@easyknockorg/ek-ui-components';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useState } from 'react';
import ActionButton from '../../Atoms/ActionButton';
import { LoadingSpinner } from '../../Atoms/LoadingSpinner';
import { SnapshotButton } from '../../Organisms/Header/styles';
import { DialogSubtitle, StyledDialog } from './styles';

const FormRadio = ({ title, subtitle, items, open, onClose, onSubmit, submitLabel }) => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div>
        <DialogTitle>
          <Header4 style={{ margin: 0 }}>{title}</Header4>
        </DialogTitle>
        <DialogSubtitle>{subtitle}</DialogSubtitle>
      </div>
      <DialogContent>
        <RadioButtons items={items} selected={selected} setSelected={setSelected} />
      </DialogContent>
      <DialogActions>
        <ActionButton value="Cancel" handleClick={onClose} />
        <SnapshotButton
          color="primary"
          onClick={() => onSubmit(selected, setLoading)}
          size="large"
          variant="contained"
          disabled={selected === null || loading}
        >
          <Header5 style={{ margin: 0, color: selected === null ? Colors.BGW.grey3 : 'inherit' }}>
            {loading ? <LoadingSpinner /> : submitLabel}
          </Header5>
        </SnapshotButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default FormRadio;
