import styled from 'styled-components';
import {
  Colors,
  Header6,
  TextBodyCopySmall,
  TextBodyCopyMedium,
} from '@easyknockorg/ek-ui-components';

export const Divider = styled.div`
  border: 1px solid ${Colors.BGW.gray4};
  width: 0;
  height: 100%;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  gap: 16px;
  height: 100%;
  background: ${Colors.BGW.gray6};
  border: 2px solid ${Colors.BGW.greyWhite};
`;

export const Title = styled(Header6)`
  color: ${Colors.BGW.darkGrey};
  margin-top: 0;
`;

export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled(TextBodyCopySmall)`
  color: ${Colors.BGW.gray4};
  margin: 0;
`;

export const Value = styled(TextBodyCopyMedium)`
  color: ${Colors.BGW.darkGrey};
  margin: 0;
`;
