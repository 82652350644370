import { getStateCode } from '../../util/format';
export var getFundingSources = function (deal) {
    var state = deal.state;
    // Remove when we expire v25
    if (state && state !== '' && deal.VersionId > 23) {
        var stateCode = getStateCode(state);
        var stateMap = {
            AL: ["tvc" /* TempleView */],
            AK: ["tvc" /* TempleView */],
            AZ: ["tvc" /* TempleView */],
            AR: ["tvc" /* TempleView */],
            CA: ["tvc" /* TempleView */],
            CO: ["tvc" /* TempleView */],
            CT: ["tvc" /* TempleView */],
            DE: [],
            DC: [],
            FL: ["tvc" /* TempleView */],
            GA: ["tvc" /* TempleView */],
            HI: ["tvc" /* TempleView */],
            ID: ["tvc" /* TempleView */],
            IL: ["tvc" /* TempleView */],
            IN: ["tvc" /* TempleView */],
            IA: ["tvc" /* TempleView */],
            KS: ["tvc" /* TempleView */],
            KY: ["tvc" /* TempleView */],
            LA: ["tvc" /* TempleView */],
            ME: ["tvc" /* TempleView */],
            MD: ["tvc" /* TempleView */],
            MA: [],
            MI: ["tvc" /* TempleView */],
            MN: ["tvc" /* TempleView */],
            MS: ["tvc" /* TempleView */],
            MO: ["tvc" /* TempleView */],
            MT: ["tvc" /* TempleView */],
            NE: ["tvc" /* TempleView */],
            NV: ["tvc" /* TempleView */],
            NH: ["tvc" /* TempleView */],
            NJ: ["tvc" /* TempleView */],
            NM: ["tvc" /* TempleView */],
            NY: ["tvc" /* TempleView */],
            NC: ["tvc" /* TempleView */],
            ND: [],
            OH: ["tvc" /* TempleView */],
            OK: ["tvc" /* TempleView */],
            OR: ["tvc" /* TempleView */],
            PA: ["tvc" /* TempleView */],
            RI: ["tvc" /* TempleView */],
            SC: ["tvc" /* TempleView */],
            SD: [],
            TN: ["tvc" /* TempleView */],
            TX: ["tvc" /* TempleView */],
            UT: ["tvc" /* TempleView */],
            VT: [],
            WA: ["tvc" /* TempleView */],
            WV: ["tvc" /* TempleView */],
            VA: ["tvc" /* TempleView */],
            WI: ["tvc" /* TempleView */],
            WY: ["tvc" /* TempleView */],
        };
        var selectedStateMap = stateMap[stateCode];
        return selectedStateMap;
    }
    else {
        return [];
    }
};
export var getDefaultFundingSource = function (deal) {
    if (deal.state) {
        var fundingSources = getFundingSources(deal);
        if (fundingSources.length === 0) {
            console.warn('No Lenders Available.');
            return;
        }
        return fundingSources[0];
    }
    else {
        return;
    }
};
export var getStateRentDueDays = function (deal) {
    var state = deal.state;
    var stateMap = {
        AL: 5,
        AK: 5,
        AZ: 5,
        AR: 5,
        CA: 5,
        CO: 7,
        CT: 9,
        DE: 5,
        DC: 5,
        FL: 5,
        GA: 5,
        HI: 5,
        ID: 5,
        IL: 5,
        IN: 5,
        IA: 5,
        KS: 5,
        KY: 5,
        LA: 5,
        ME: 15,
        MD: 5,
        MA: 30,
        MI: 5,
        MN: 5,
        MS: 5,
        MO: 5,
        MT: 5,
        NE: 5,
        NV: 5,
        NH: 5,
        NJ: 5,
        NM: 5,
        NY: 5,
        NC: 5,
        ND: 5,
        OH: 5,
        OK: 5,
        OR: 4,
        PA: 5,
        RI: 5,
        SC: 5,
        SD: 5,
        TN: 5,
        TX: 2,
        UT: 5,
        VT: 5,
        WA: 5,
        WV: 5,
        VA: 5,
        WI: 5,
        WY: 5,
    };
    if (state) {
        var stateCode = getStateCode(state);
        return stateMap[stateCode].toString();
    }
    return '5';
};
