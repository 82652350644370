import React from 'react';
import ActionButton from '../../../components/Atoms/ActionButton';
import accounting from 'accounting';
import { Colors, Header5 } from '@easyknockorg/ek-ui-components';
import Divider from '@material-ui/core/Divider';
import { UserRole } from '../../../formulas/dto/userDto';
import successIcon from '../../../images/checkCircleGreen.svg';
import { calcCashFundingPercentage } from '../../../utils';
import {
  Card,
  CardContainer,
  CardHeader,
  CardSelected,
  CardSubheader,
  ContentTitle,
  ContentValue,
  CustomRadio,
  DetailsContainer,
  DividerContainer,
  CustomDivider,
  DividerText,
} from './styles';
import { nameFormatter } from '../../../utils/helpers';

export const OfferCard = ({
  activeCard,
  cashFunding,
  clickedCard,
  clickedRadio,
  closingCosts,
  dealData,
  handleClick,
  handlePreview,
  handleRadioChange,
  index,
  isSnapshot,
  offersLength,
  offerName,
  optionException,
  rent,
}) => {
  const formatMoney = (amount) => accounting.formatMoney(amount, '$ ', 0);

  // Deal Owner -> The person who created the snapshot (NULL for default deal)
  const isDealOwnerUW = dealData?.dealOwnerRole === UserRole.Underwriting;
  const selectedText = isDealOwnerUW ? 'UNDERWRITING DECISION' : 'OPTION SELECTED';

  const cashFundingPercentage = calcCashFundingPercentage(cashFunding, dealData?.purchasePrice);
  return (
    <CardContainer
      className={offersLength > 0 ? 'notalone' : ''}
      key={index}
      disabled={isSnapshot}
      data-test-id={`outer-box-of-${nameFormatter(offerName)}`}
    >
      <Card
        key={index}
        clickedCard={clickedCard}
        active={activeCard}
        isSnapshot={isSnapshot}
        isException={optionException}
        showPreview={activeCard && isSnapshot}
        onClick={() => handleClick(index, cashFunding, rent, closingCosts, offerName)}
      >
        <div>
          {activeCard && isSnapshot && (
            <CardSelected>
              {selectedText}
              {isDealOwnerUW && <img src={successIcon} alt="success underwriting icon" />}
            </CardSelected>
          )}
          <CardHeader>
            <Header5 style={{ margin: 0 }} color={isSnapshot ? Colors.BGW.darkGrey2 : null}>
              {offerName}
            </Header5>

            {!isSnapshot && (
              <CustomRadio
                checked={index === clickedRadio || activeCard}
                onChange={() => handleRadioChange(index)}
                value={index}
              />
            )}
          </CardHeader>
          {!isSnapshot && optionException && (
            <CardSubheader>Based on the desired cash and rent in SalesForce.</CardSubheader>
          )}
          <DetailsContainer
            activeCard={activeCard && offerName !== 'Exception'}
            data-test-id={`inner-box-of-${nameFormatter(offerName)}`}
          >
            <div>
              <ContentTitle>{`Cash Funding (${cashFundingPercentage}%)`}</ContentTitle>
              <ContentValue disabled={isSnapshot}>{formatMoney(cashFunding)}</ContentValue>
            </div>
            <Divider orientation="vertical" flexItem style={{ margin: '0 16px' }} />
            <div>
              <ContentTitle>{optionException ? 'Desired Rent' : 'Rent'}</ContentTitle>
              <ContentValue disabled={isSnapshot}>{formatMoney(rent)}</ContentValue>
            </div>
          </DetailsContainer>
          {activeCard && isSnapshot && (
            <ActionButton
              value="Preview Estimate"
              handleClick={handlePreview}
              icon="chevronRight"
              disabled={!isSnapshot}
            />
          )}
        </div>
      </Card>
      {index === offersLength - 2 ? (
        <DividerContainer>
          <CustomDivider />
          <DividerText>or</DividerText>
          <CustomDivider />
        </DividerContainer>
      ) : null}
    </CardContainer>
  );
};
