export const sampleDealData = {
  ContactId: 120,
  DealTypeId: 1,
  VersionId: 12,
  address: '3800 Reservoir Road Northwest',
  annualRent: 52218.75,
  cashFunding: 390000,
  cashProceedRatio: 7.603822227788911,
  cashRelease: 227658.4375,
  cashUponSaleYear1: 162750,
  cashUponSaleYear2: 144750,
  cashUponSaleYear3: 129750,
  cashUponSaleYear4: 117750,
  cashUponSaleYear5: 108750,
  city: 'Maine',
  closingCosts: 9000,
  companyName: null,
  county: null,
  createdAt: '2022-10-10T23:17:51.975Z',
  creditScore: null,
  creditScoreOver550: null,
  remainingPropertyTax: 1000,
  dealStatus: 'Proposal',
  dealStatusMoreInfo: null,
  desiredCashout: 300000,
  desiredRent: 4351.5625,
  dscr: 165.77380952380952,
  easyKnockProcessingFee: 29940,
  email: 'easyknocktesting+ekcp-repurchase@gmail.com',
  estimateEmailed: true,
  firstName: 'Repurchase',
  hoa: null,
  id: 493,
  incomeRatio: 0,
  isPrimaryDeal: false,
  landlordExpenses: 6266,
  landlordInsurance: 6000,
  lastName: 'Test',
  leadSource: 'Website Qualification Form',
  legalDescription: null,
  liens: null,
  lowerMonthlyRent: null,
  lowerPropertyValue: null,
  medianMonthlyRent: null,
  medianPropertyValue: null,
  minCashout: null,
  mobilePhone: null,
  monthlyIncome: null,
  monthlyNetRent: 2829.3958333333335,
  monthlyRent: 4351.5625,
  mortgageBalance: 100000,
  netRentYieldPercent: 8.705833333333333,
  opportunityId: '0068C000003pJJSQA2',
  optionFeeYear1: 22500,
  optionFeeYear2: 18000,
  optionFeeYear3: 15000,
  optionFeeYear4: 12000,
  optionFeeYear5: 9000,
  optionPeriodIncreaseYear1: 3.75,
  optionPeriodIncreaseYear2: 3,
  optionPeriodIncreaseYear3: 2.5,
  optionPeriodIncreaseYear4: 2,
  optionPeriodIncreaseYear5: 1.5,
  optionValue: 210000,
  pddCompletedDate: null,
  phone: '(555) 555 5555',
  propertyInsurance: null,
  purchasePrice: 600000,
  realEstateTaxes: 6000,
  realtorCosts: 36000,
  referredBy: null,
  rentHoldbackAmount: 0,
  rentHoldbackMonths: 0,
  rentHoldbackPays: 0,
  rentTenantPays: null,
  repairFundAmount: 0,
  repurchasePriceYear1: 401250,
  repurchasePriceYear2: 419250,
  repurchasePriceYear3: 434250,
  repurchasePriceYear4: 446250,
  repurchasePriceYear5: 455250,
  repurchaseYieldPercent: null,
  salesforceDealId: 'a0X8C000000JtP7UAK',
  secondPartyEmail: null,
  secondPartyFirstName: null,
  secondPartyLastName: null,
  securityDeposit: 0,
  snapshotName: 'Salesforce new values',
  snapshots: [],
  stageName: 'Discovery',
  state: 'Hawaii',
  staticFields: [],
  titleInsurance: 0,
  totalAssetYieldPercent: 12.455833333333333,
  transferTax: 600,
  updatedAt: '2022-10-10T23:26:33.835Z',
  upperMonthlyRent: null,
  upperPropertyValue: null,
  utmSource: null,
  uuid: 'fb1cd0c7-9e92-4cb8-bb33-f875dd540099',
  warranty: 1200,
  zip: '20007',
};

export const DealPortalOutcomesType = {
  Approved: 'APPROVED',
  Override: 'OVERRIDE',
  Exception: 'EXCEPTION',
};

export const SalesExceptionRequestReasons = [
  { value: 'Rent Too High' },
  { value: 'Cash Release Too Low' },
  { value: 'Not Enough Cash to Close Deal' },
  { value: 'Repair Issue' },
  { value: 'Home Condition' },
  { value: 'Independent Appraisal' },
  { value: 'Other Due Diligence Issue' },
];

export const NoOptionsReason = [{ value: 'No Pre-Approved Option' }];

export const UnderwritingStatusEnum = {
  Unassigned: 'Unassigned',
  New: 'New',
  InReview: 'In Review',
  Reworked: 'Reworked',
  Escalated: 'Escalated to Committee',
  SentToLender: 'Review Sent to Lender',
  Approved: 'Approved',
  Declined: 'Declined',
  Withdrawn: 'Withdrawn',
};

export const DealStages = {
  Discovery: 1,
  Proposal: 2,
  'Application Review': 3,
  'LOI Sent': 4,
  Screening: 5,
  Processing: 6,
  'Processing Post Review': 7,
  'PA Sent': 8,
  'Pre-Close': 9,
  'Clear to Close': 10,
  'Transaction Closed': 11,
  'On Hold': 12,
  Declined: 13,
};

export const NewEstimateSalesTeamBetaList = [
  // Sales
  'jack.doherty@easyknock.com',
  'kenney.hentschke@easyknock.com',
  // Eng/Product
  'luca.guglielmi@easyknock.com',
  'gabriel.reyes@easyknock.com',
  'brock.arnold@easyknock.com',
  'jorge.cuaron@easyknock.com',
  //QA
  'oleg.neskoromnyi@easyknock.com',
  'rohini.kanala@easyknock.com',
];
