import React, { useEffect, useState } from 'react';
import { generateUwStatusLabel } from '../../../utils/index';
import { Oval } from './styles';

const UnderwritingLabel = ({ underwritingStatus }) => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const newStatus = generateUwStatusLabel(underwritingStatus);
    setStatus(newStatus);
  }, [underwritingStatus]);

  return (
    <>
      <Oval className={status}>{status}</Oval>
    </>
  );
};

export default UnderwritingLabel;
