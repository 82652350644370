import styled from 'styled-components';

export const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
`;

export const LinkContent = styled.div`
  display: flex;
  font-size: 16px;
`;

export const Text = styled.div`
  color: ${({ color }) => color || '#6e767c'};
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
`;
