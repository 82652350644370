import React from 'react';
import { connect } from 'react-redux';
import GoogleLogin from 'react-google-login';
import { loginSuccess } from '../../pages/DealCalculator/actions';
import { alertAction } from '../Alert/reducer';
import apiClient from '../../utils/apiClient';
import ekLogo from './eklogo.svg';

const Login = ({ onSuccess, onError, authenticated }) => (
  <div
    style={{
      height: '500px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    }}
  >
    <img style={{ height: '50px' }} src={ekLogo} alt="Easy Knock Logo" />
    <h2 style={{ color: '#454b4e' }}>Deal Portal</h2>
    <GoogleLogin
      style={{ height: '500px' }}
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      buttonText="Login"
      onSuccess={async ({ accessToken, ...rest }) => {
        const { token, user } = await apiClient.getLoginToken(rest.tokenObj.id_token);
        onSuccess({ accessToken: token, data: user });
      }}
      onFailure={(p) => onError({ open: true, message: p.message, severity: 'error' })}
      cookiePolicy="single_host_origin"
      isSignedIn
    />
  </div>
);

export default connect(null, {
  onSuccess: loginSuccess,
  onError: alertAction,
})(Login);
