import styled from 'styled-components';
import { Colors, Header4 } from '@easyknockorg/ek-ui-components';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

export const CustomHeader4 = styled(Header4)`
  margin: 0;
  cursor: pointer;
`;

export const GridSnapshotDropdown = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const RoundButton = styled.button`
  margin-right: 16px;
  border-radius: 100px;
  border: ${({ border }) => border ?? 'none'};
  background: ${({ background }) => background ?? '#00dbb1'};
  box-shadow: ${({ shadow }) => (shadow ? ' 0px 0px 20px rgba(0, 219, 177, 0.4)' : 'none')};
  cursor: pointer;
  min-width: ${({ size }) => (size ? `${size}px` : '52px')};
  min-height: ${({ size }) => (size ? `${size}px` : '52px')};
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    filter: brightness(70%);
  }
`;

export const Flag = styled.span`
  background: ${Colors.Blues.oldAccent};
  border: 1px solid ${Colors.Blues.oldAccent};
  border-radius: 100px;
  font-size: 13px;
  padding: 2px 8px;
  white-space: nowrap;
  margin: ${({ margin }) => margin};

  &.approved {
    background: ${Colors.PineGreen.secondaryGreenTransparency};
    border: ${Colors.PineGreen.secondaryGreenTransparency};
  }
  &.override {
    background: ${Colors.Secondary.orangeTransparency};
    border: ${Colors.Secondary.orangeTransparency};
  }
  &.exception {
    background: ${Colors.Secondary.redTransparency};
    border: ${Colors.Secondary.redTransparency};
  }
`;

export const FlagContainer = styled.div`
  margin-top: 3px;
  display: ${({ display }) => display};
`;

export const StyledSpan = styled.span`
  color: ${Colors.BGW.charcoal1};
  font-size: 15px;
`;

export const SnapshotButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SnapshotButton = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    font-weight: bold;
    padding: 14px 16px;
    min-width: 125px;
  }
`;

export const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: #e7e7e7;
    color: #565d61;
    font-size: 15px;
  }

  & .MuiTooltip-arrow {
    ::before {
      background-color: #e7e7e7;
    }
  }
`;
