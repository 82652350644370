import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { Colors } from '@easyknockorg/ek-ui-components';

export const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: ${Colors.BGW.greyWhite};
    color: #565d61;
    font-size: 15px;
  }
  & .MuiTooltip-arrow {
    ::before {
      background-color: ${Colors.BGW.greyWhite};
    }
  }
`;
