import { Colors, stylesHeader5 } from '@easyknockorg/ek-ui-components';
import { TableCell } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import styled from 'styled-components';

export const ExpiredNote = styled.div`
  font-size: 13px;
  color: #6e767c;
  font-weight: normal;
  padding-top: 4px;
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 40px 0 0 0 !important;
`;

export const TableCellHead = styled(TableCell)`
  &.MuiTableCell-head {
    font-size: 13px;
    color: #6e767c;
    padding: 16px;
  }
`;

export const TableCellBody = styled(TableCell)`
  &.MuiTableCell-body {
    font-size: ${({ bold }) => (bold ? '16px' : '15px')};
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
    color: ${({ bold }) => (bold ? '#454B4E' : '#565D61')};
    padding: 24px 16px;
  }
`;

export const TextButton = styled.button`
  ${stylesHeader5};
  background-color: transparent;
  color: ${Colors.PineGreen.secondaryGreen};
  outline: none;
  border: none;
  cursor: pointer;
  &.disabled {
    color: ${Colors.BGW.greyWhite};
    cursor: default;
    :hover {
      font-size: 18px;
    }
  }
  :hover {
    font-size: 19px;
  }
`;
