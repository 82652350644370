import React, { useEffect, useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {
  getDocumentData,
  isValidEmail,
  isValidEmailOrEmpty,
  isTriPartyValid,
} from '../../../utils';
import {
  StyledDialog,
  DialogSubtitle,
  StyledTextField,
  SpinnerOverlayHolder,
} from '../FormDialog/styles';
import { StyledFormControl, StyledMenuItem } from './styles';
import { Header4 } from '@easyknockorg/ek-ui-components';
import apiClient from '../../../utils/apiClient';
import { SpinnerOverlay } from '@easyknockorg/ek-ui-components';
import { updateDealOnDB } from '../../../utils';
import ActionButtons from '../../Molecules/ActionButtons';
import { DealPortalOutcomesType } from '../../../utils/constansts';
import { DocumentType, validateDeal } from '../../../utils';
import { onError, onSuccess } from '../../../utils/snackbar';

const NewDocumentModal = ({
  dealData,
  docType,
  open,
  setOpen,
  title,
  subtitle,
  submitLabel,
  setSnackbarState,
  setDealData,
}) => {
  const [documentData, setDocumentData] = useState({ documents: [], templates: {} });
  const [template, setTemplate] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [salesRep, setSalesRep] = useState(dealData?.salesRep);
  const [ownersAgent, setOwnersAgent] = useState(dealData?.ownersAgentEmail);

  const [firstPartyRecipient, setFirstPartyRecipient] = useState(dealData?.email);
  const [secondPartyRecipient, setSecondPartyRecipient] = useState(
    dealData?.secondPersonOnTitleEmail,
  );

  useEffect(() => {
    if (open) setFirstPartyRecipient(dealData?.email);
  }, [dealData?.email, open]);

  useEffect(() => {
    if (open) setSecondPartyRecipient(dealData?.secondPersonOnTitleEmail);
  }, [dealData?.secondPersonOnTitleEmail, open]);

  useEffect(() => {
    if (open) setSalesRep(dealData?.salesRep);
  }, [dealData?.salesRep, open]);

  useEffect(() => {
    if (open && dealData?.ownersAgentEmail) setOwnersAgent(dealData?.ownersAgentEmail);
  }, [dealData?.ownersAgentEmail, open]);

  const fields = [
    {
      key: 'ownersAgentEmail',
      label: 'Real Estate Agent',
      value: ownersAgent,
      disabled: true,
    },
    {
      label: 'First Party Recipient',
      value: firstPartyRecipient,
      setValue: setFirstPartyRecipient,
      helperText: null,
    },
    {
      label: 'Second Party Recipient',
      value: secondPartyRecipient,
      setValue: setSecondPartyRecipient,
      helperText: 'Optional',
    },
    {
      label: 'Sales Rep Email',
      value: salesRep,
      setValue: setSalesRep,
      helperText: 'Optional',
    },
  ];

  useEffect(async () => {
    try {
      if (dealData?.id) {
        setLoadingTemplates(true);
        const docData = await getDocumentData(dealData);
        setDocumentData(docData);
      }
    } catch (err) {
      console.log('err getting document data, err = ', err);
    } finally {
      setLoadingTemplates(false);
    }
  }, [dealData?.id]);

  const generateTitle = () => {
    let title = '';
    const document = docType.type.toLowerCase();
    const titleOptions = {
      type1: `The ${document} has been sent`,
      type2: `The ${document} has been created and sent.`,
      type3: `The ${document} has been created and sent to your client.`,
    };
    if (docType === DocumentType.LOI) {
      if (
        dealData.dealPortalOutcome === DealPortalOutcomesType.Exception ||
        dealData.dealPortalOutcome === DealPortalOutcomesType.Override
      ) {
        title = titleOptions.type1;
      } else {
        title = titleOptions.type2;
      }
    } else if (docType === DocumentType.Purchase || docType === DocumentType.Lease) {
      title = titleOptions.type3;
    } else if (docType === DocumentType.Service) {
      title = titleOptions.type2;
    } else if (docType === DocumentType.Triparty) {
      title = titleOptions.type2;
    }
    return title;
  };

  const onCreateSubmit = async () => {
    const invalidField = validateDeal(['snapshotName', 'salesforceDealId'], dealData);

    if (invalidField) {
      onCreateClose();
      onError(
        setSnackbarState,
        `Error creating document w/o ${invalidField} for dealId: ${dealData.id}`,
      );
      return;
    }

    setLoading(true);
    const payload = {
      documentType: docType.filter,
      firstPartyRecipients: firstPartyRecipient,
      salesAddresses: salesRep,
      secondPartyRecipients: secondPartyRecipient,
      ownersAgent: ownersAgent,
      templateId: template,
    };

    try {
      await apiClient.document.post(dealData.id, payload);
      onCreateClose();
      onSuccess(setSnackbarState, generateTitle());
      // Mark document as Sent on the database
      const dealToUpdate = { ...dealData, [docType.docSentField]: true };
      setDealData(dealToUpdate);
      updateDealOnDB(dealToUpdate);

      if (docType === DocumentType.LOI && dealData.salesforceDealId) {
        // After creation of LOI, set this deal as primary on Salesforce

        await apiClient.salesforce.patch(dealData.salesforceDealId, {
          isPrimaryFlag__c: true,
        });
      }
    } catch (err) {
      onError(setSnackbarState, `Error creating document: ${err.message}`);
      onCreateClose();
    }
  };

  const triPartyValid = isTriPartyValid(docType, ownersAgent);

  const isFormInvalid =
    !template ||
    !isValidEmail(firstPartyRecipient) ||
    !isValidEmailOrEmpty(secondPartyRecipient) ||
    !isValidEmailOrEmpty(salesRep) ||
    !triPartyValid;

  const onCreateClose = () => {
    setLoading(false);
    setOpen(false);
    clearFields();
  };

  const clearFields = () => {
    fields.forEach((field) => {
      if (field.setValue) {
        field.setValue('');
      }
    });
    setTemplate('');
  };

  const alphaSortTemplates = (templatesArray) => {
    if (templatesArray?.length > 0) {
      return templatesArray.sort((a, b) => {
        const formattedA = a.name.replace(/ /g, '');
        const formattedB = b.name.replace(/ /g, '');
        return formattedA > formattedB ? 1 : formattedB > formattedA ? -1 : 0;
      });
    }
    return [];
  };

  return (
    <StyledDialog open={open} onClose={onCreateClose} maxWidth="sm" fullWidth>
      {loadingTemplates && (
        <SpinnerOverlayHolder>
          <SpinnerOverlay background="rgba(255, 255, 255, 0.6)" />
        </SpinnerOverlayHolder>
      )}
      <DialogTitle>
        <Header4 style={{ margin: 0 }}>{title}</Header4>
      </DialogTitle>
      {subtitle && <DialogSubtitle>{subtitle}</DialogSubtitle>}
      <DialogContent>
        <StyledFormControl variant="outlined" fullWidth $docModalSpacing={!subtitle}>
          <InputLabel id="demo-simple-select-outlined-label">Select Template</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={template}
            onChange={(event) => setTemplate(event.target.value)}
            label="Select Template"
            fullWidth
            inputProps={{ maxLength: 80 }}
            type="text"
            variant="outlined"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            {alphaSortTemplates(documentData.templates[docType.filter]).map((templateItem) => (
              <StyledMenuItem key={templateItem.id} value={templateItem.id}>
                {templateItem.name}
              </StyledMenuItem>
            ))}
          </Select>
        </StyledFormControl>
        {fields.map((field, idx) => {
          const { label, value, setValue, helperText, key } = field;
          if (key === 'ownersAgentEmail' && docType.type !== DocumentType.Triparty.type) {
            return null;
          }
          return (
            <StyledTextField
              key={idx}
              type="email"
              label={label}
              value={value}
              disabled={field.disabled}
              onChange={(event) => setValue(event.target.value)}
              error={!isValidEmailOrEmpty(value)}
              helperText={isValidEmailOrEmpty(value) ? helperText : 'Please enter a valid email'}
              fullWidth
              variant="outlined"
              $docModalSpacing
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <ActionButtons
          onSubmit={onCreateSubmit}
          submitLabel={submitLabel}
          disabled={isFormInvalid || loading}
          loadingSpinner={loading}
        />
      </DialogActions>
    </StyledDialog>
  );
};

export default NewDocumentModal;
