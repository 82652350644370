import React from 'react';

const Percent = ({ percent, style }) => (
  <div
    style={{
      textAlign: 'center',
      width: '15%',
      color: 'rgb(69, 75, 78)',
      fontSize: 13,
      ...style,
    }}
  >
    {percent}%
  </div>
);

export default Percent;
