var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormulaMapLatest } from './formulas-latest';
import { FormulaMapExpired, FormulaMapV26, FormulaMapV27 } from './formulas-legacy';
import versionIdLatest from '../../config/version-id-latest';
import ErrorCode from '../../dto/errorCode';
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export var getFormulaMapClass = function (versionId) {
    if (!versionId) {
        versionId = versionIdLatest;
    }
    if (versionId === 28)
        return FormulaMapLatest;
    else if (versionId === 27)
        return FormulaMapV27;
    else if (versionId === 26)
        return FormulaMapV26;
    else if (versionId < 26)
        return FormulaMapExpired;
    throw { code: ErrorCode.VERSION_ID_UNSUPPORTED };
};
export var runFormulas = function (deal, user) {
    var dealCopy = __assign({}, deal);
    var FormulaMapClass = getFormulaMapClass(deal.VersionId);
    var formulaMap = new FormulaMapClass(deal, user);
    for (var property in formulaMap) {
        var formula = formulaMap[property];
        if (formula && formula.hasOwnProperty('calc')) {
            dealCopy[property] = formula.calc();
        }
        if (formula &&
            formula.hasOwnProperty('min') &&
            !formulaMap.hasOwnProperty("min".concat(capitalizeFirstLetter(property)))) {
            dealCopy["min".concat(capitalizeFirstLetter(property))] = formula.min();
        }
        if (formula &&
            formula.hasOwnProperty('max') &&
            !formulaMap.hasOwnProperty("max".concat(capitalizeFirstLetter(property)))) {
            dealCopy["max".concat(capitalizeFirstLetter(property))] = formula.max();
        }
    }
    return dealCopy;
};
export var getDealWarnings = function (deal, user) {
    var warnings = [];
    var FormulaMapClass = getFormulaMapClass(deal.VersionId);
    var formulaMap = new FormulaMapClass(deal, user);
    for (var property in formulaMap) {
        var formula = formulaMap[property];
        if (formula && formula.hasOwnProperty('warning')) {
            var warning = formula.warning();
            if (warning) {
                warnings.push(warning);
            }
        }
    }
    return warnings;
};
