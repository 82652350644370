import _ from 'lodash';
import configLatest from './config-latest';
var v27 = function () {
    var config = _.cloneDeep(configLatest);
    config.SellAndStay.DEFAULT_EK_PROCESSING_FEE = 4.99;
    config.SellAndStay.OPTION_PERIOD_INCREASE_YEAR_1 = 3.75;
    config.SellAndStay.OPTION_PERIOD_INCREASE_YEAR_2 = 3;
    config.SellAndStay.OPTION_PERIOD_INCREASE_YEAR_3 = 2.5;
    config.SellAndStay.OPTION_PERIOD_INCREASE_YEAR_4 = 2;
    config.SellAndStay.OPTION_PERIOD_INCREASE_YEAR_5 = 1.5;
    return config;
};
var v26 = function () {
    var config = _.cloneDeep(v27());
    return config;
};
var configByVersion = {
    26: v26(),
    27: v27(),
};
export default configByVersion;
