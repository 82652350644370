import styled, { keyframes } from 'styled-components';
import { Colors } from '@easyknockorg/ek-ui-components';

const shineAnimation = keyframes`
 0% { filter: brightness(1); }
 100% { filter: brightness(0.87);}
`;

export const LeftColumn = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 56px 56px;
  gap: 24px;

  width: 400px;
  height: 100%;
  left: 0px;
  top: 0px;

  background: ${Colors.BGW.white};

  border-right: 1px solid ${Colors.BGW.greyWhite};
`;

export const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 56px 56px;
  gap: 40px;

  width: 100%;
  height: 100%;

  background: ${Colors.BGW.white};
`;

export const TextBox = styled.div`
  ${({ width }) => `max-width: ${width + 'px'};`}
  ${({ height }) => `height: ${height + 'px'};`}
  ${({ color }) => `background-color: ${color};`}
  width: 100%;
  border-radius: 5px;
  animation: ${shineAnimation} 1s linear infinite alternate;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const OptionCard = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  gap: 24px;

  width: 288px;
  height: 176px;

  ${({ color }) => `background: ${color};`}

  ${({ border }) => `border: 1px solid ${border};`}

  ${({ boxShadow }) => (boxShadow ? 'box-shadow: 1px 1px 20px rgba(26, 48, 54, 0.2);' : '')}

  border-radius: 8px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;

  border-radius: 12px;

  flex: none;
  order: 1;
  flex-grow: 0;
  ${({ color }) => `background: ${color};`}
`;

export const Label = styled.div`
  width: 108px;
  height: 8px;

  background: ${Colors.PineGreen.focusOrHoverLight};
  border-radius: 5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const CardOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 248px;
  height: 88px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Breakdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  flex: none;
  order: 0;
  flex-grow: 1;
`;

export const LoadingRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  border: 2px solid ${Colors.BGW.greyWhite};
  border-top: none;

  &:first-of-type {
    border-top: 2px solid ${Colors.BGW.greyWhite};
    border-radius: 5px 5px 0px 0px;
  }

  &:last-of-type {
    border-radius: 0px 0px 5px 5px;
  }
`;
