import React from 'react';
import warnIcon from '../../Alert/icons/warningIcon.svg';
import errorIcon from '../../Alert/icons/errorIcon.svg';
import IconButton from '@material-ui/core/IconButton';
import successIcon from '../../../images/checkCircle.svg';
import { Icon } from '@easyknockorg/ek-ui-components';
import { SnackbarContent, StyledSnackbar, Message } from './styles';

const AlertBanner = ({ handleClose, open = false, severity = '', title = '', message = '' }) => {
  const iconSrc =
    severity === ''
      ? null
      : severity === 'success'
      ? successIcon
      : severity === 'warning'
      ? warnIcon
      : errorIcon;

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <Icon icon="x" color="#068A82" size="20" weight="bold" />
    </IconButton>
  );

  return (
    <StyledSnackbar
      open={open}
      autoHideDuration={6000}
      message={
        <SnackbarContent>
          {iconSrc && (
            <div style={{ margin: '0px 24px 0px 12px' }}>
              <img src={iconSrc} alt="alert icon" />
            </div>
          )}
          <div>
            <div style={{ fontWeight: 'bold' }}>{title}</div>
            {message && <Message>{message}</Message>}
          </div>
        </SnackbarContent>
      }
      action={action}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    />
  );
};

export default AlertBanner;
