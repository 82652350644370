import { SpinnerOverlay } from '@easyknockorg/ek-ui-components';
import { ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import SkeletonLoadingOverlay from '../../components/Atoms/LoadingOverlay/Skeleton';
import UnderwritingLabel from '../../components/Atoms/UnderwritingLabel';
import OptionsTab from '../../components/Molecules/OptionsTab';
import Footer from '../../components/Organisms/Footer';
import Header from '../../components/Organisms/Header/index';
import OfferCardsGroup from '../../components/Organisms/OfferCardsGroup';
import { useDealContext } from '../../contexts/DealContext';
import useWindowSize from '../../Hooks/useWindowSize';
import { OptionsContent, theme } from '../DealCalculator/index';
import {
  Body,
  DealFormColumn,
  FooterHolder,
  HeaderHolder,
  OptionsColumn,
  OptionsContentRow,
  OptionsHeaderRow,
  Page,
  Title,
} from './styles';
import AlertBanner from '../../components/Atoms/AlertBanner';
import { UserRole } from '../../formulas/dto/userDto';
import {UNDERWRITING_ESTIMATE_LABEL} from "../../constants";


const RevampCalculator = () => {
  const {
    dealData,
    defaultDeal,
    offers,
    handleCloseSnackbar,
    loadingBar,
    optionName,
    primaryDeal,
    setDealData,
    snackbarState,
    userData,
  } = useDealContext();
  const [alignment, setAlignment] = useState('estimate');
  const { width } = useWindowSize();
  const [dimensions, setDimensions] = useState({
    offsetTop: null,
    offsetBottom: null,
  });
  const isSnapshot = !!dealData?.snapshotName;
  const isCurrentUserUW = userData?.role === UserRole.Underwriting;

  const offersOnly = { ...offers };
  if (offersOnly.exception) {
    delete offersOnly.exception;
  }

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment) setAlignment(newAlignment);
  };

  useEffect(() => {
    const HeaderKey = document?.querySelector('.header-holder');
    const FooterKey = document?.querySelector('.footer-holder');

    setDimensions({
      offsetTop: HeaderKey?.offsetHeight,
      offsetBottom: FooterKey?.offsetHeight,
    });
  }, [width]);

  const generateTitle = () => {
    const isSnapshotOwnerSales = dealData.dealOwnerRole === UserRole.Default;
    const isCurrentUserSales = userData?.role === UserRole.Default;

    if (isSnapshot) {
      return isSnapshotOwnerSales ? dealData.optionSelected : UNDERWRITING_ESTIMATE_LABEL;
    }
    return isCurrentUserSales ? optionName : 'Closing Estimate';
  };

  const noOffersAvailable =
    Object.keys(offersOnly).length === 0 ||
    Object.values(offersOnly).every((item) => item === null);

  return (
    <Page>
      <ThemeProvider theme={theme}>
        <HeaderHolder className="header-holder">
          <Header
            dealData={dealData}
            defaultDeal={defaultDeal}
            noOffersAvailable={noOffersAvailable}
            optionName={optionName}
            setDealData={setDealData}
            userData={userData}
          />
        </HeaderHolder>
        <Body offsetTop={dimensions.offsetTop}>
          <SkeletonLoadingOverlay loading={loadingBar} />
          {!isSnapshot && isCurrentUserUW && !primaryDeal ? (
            <h2 style={{ textAlign: 'center', width: '100%' }}>
              {`No primary deal found for this Salesforce opportunity: ${dealData?.opportunityId}`}
            </h2>
          ) : (
            <>
              <DealFormColumn>
                <OfferCardsGroup />
              </DealFormColumn>
              <OptionsColumn>
                <OptionsHeaderRow>
                  <div style={{ display: 'flex', alignItems: 'center' }} data-test-id="options-estimate">
                    <Title>{generateTitle()}</Title>

                    {dealData?.underwritingStatus && isSnapshot && (
                      <UnderwritingLabel underwritingStatus={dealData?.underwritingStatus} />
                    )}
                  </div>
                  <OptionsTab alignment={alignment} handleAlignment={handleAlignment} />
                </OptionsHeaderRow>
                <OptionsContentRow align="flex-start">
                  <OptionsContent alignment={alignment} dealData={dealData} userData={userData} />
                </OptionsContentRow>
              </OptionsColumn>
            </>
          )}
        </Body>
        <FooterHolder className="footer-holder">
          <Footer dealData={dealData} />
        </FooterHolder>
        <AlertBanner
          handleClose={handleCloseSnackbar}
          title={snackbarState.title}
          message={snackbarState.message}
          open={snackbarState.open}
          severity={snackbarState.severity}
        />
      </ThemeProvider>
    </Page>
  );
};

export default RevampCalculator;
