import React from 'react';
import { Switch, Route } from 'react-router-dom';
import _DealCalculator from '../../pages/DealCalculator';
import _Login from '../Login';
import _SalesforceRedirect from '../SalesforceRedirect';
import _Dashboard from '../../pages/Dashboard';
import _LogDecrypt from '../../pages/LogDecrypt';
import _EquifaxPdf from '../EquifaxPdf';

import { userIsNotAuthenticated, userIsAuthenticated } from '../../utils/auth';
import Alert from '../Alert';
import GlobalStyle from '../../global-styles';
import RevampCalculator from '../../pages/RevampCalculator/index';
import AllOffers from '../../pages/Offers/index';
import { DealProvider } from '../../contexts/DealContext';

const Dashboard = userIsAuthenticated(_Dashboard);
const DealCalculator = userIsAuthenticated(_DealCalculator);
const LogDecrypt = userIsAuthenticated(_LogDecrypt);
const Login = userIsNotAuthenticated(_Login);
const SalesforceRedirect = userIsAuthenticated(_SalesforceRedirect);
const EquifaxPdf = userIsAuthenticated(_EquifaxPdf);

const RevampCalcPrime = () => (
  <DealProvider>
    <RevampCalculator />
  </DealProvider>
);

const Error = () => (
  <h1
    style={{
      textAlign: 'center',
    }}
  >
    Deal Not Found{' '}
  </h1>
);

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/deal-calculator/:dealId" component={DealCalculator} />
        <Route
          exact
          path="/deal-calculator/:dealId/snapshot/:snapshotId"
          component={DealCalculator}
        />
        <Route exact path="/deal-calculator-revamp/:dealId" component={RevampCalcPrime} />
        <Route
          exact
          path="/deal-calculator-revamp/:dealId/snapshot/:snapshotId"
          component={RevampCalcPrime}
        />
        <Route exact path="/deal-calculator-revamp/:dealId/offers" component={AllOffers} />
        <Route exact path="/log-decrypt" component={LogDecrypt} />
        <Route exact path="/salesforce/:opportunityId" component={SalesforceRedirect} />
        <Route exact path="/salesforce/deal/:sfDealId" component={SalesforceRedirect} />
        <Route exact path="/enrichment/pdf" component={EquifaxPdf} />
        <Route path="/login" component={Login} />
        <Route path="/404" component={Error} />
        <Route path="" component={Dashboard} />
      </Switch>
      <GlobalStyle />
      <Alert />
    </>
  );
}

export default App;
