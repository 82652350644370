import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReloadIconComponent from '../../../../components/ReloadIcon';
import { bindFieldToFomula as bindFieldToFomulaAction } from '../../actions';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => dispatch(bindFieldToFomulaAction(ownProps.field)),
});

const ReloadIcon = connect(null, mapDispatchToProps)(ReloadIconComponent);

ReloadIcon.propTypes = {
  field: PropTypes.string.isRequired,
};

export default ReloadIcon;
