import axios from 'axios';
import { axiosErrorInterceptor } from './utils';

let store;

export const connectDealApiClient = (newStore) => {
  store = newStore;
};

const getStore = () => store;
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

instance.interceptors.response.use(function (response) {
  return response;
}, axiosErrorInterceptor(getStore));

export default {
  deal: {
    getTopDeals: () =>
      instance
        .get(`/deal/tops`, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
    get: (id, options) =>
      instance
        .get(`/deal/${id}`, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
          params: options,
        })
        .then((res) => res.data)
        .catch((err) => {
          if (err?.response?.status === 404) {
            throw Error('DealNotFound');
          }
          throw Error('UnknownError');
        }),
    getDPEventsByOpportunityId: (opportunityId) =>
      instance
        .get(`/deal/events/${opportunityId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err?.response?.data;
        }),
    getDPRateSheetById: (rateSheetId) =>
      instance
        .get(`/deal/ratesheet/${rateSheetId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err?.response?.data;
        }),
    getDealsByOpportunityId: (id) =>
      instance
        .get(`/deal/byOpportunity/${id}`, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
    patch: (id, payload) =>
      instance
        .patch(`/deal/${id}`, payload, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
    post: (payload) =>
      instance
        .post('/deal', payload, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
    put: (id, payload) =>
      instance
        .put(`/deal/${id}`, payload, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
    updateDealFields: (id, payload) =>
      instance
        .put(`/deal/${id}/update`, payload, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
    updateRefetchedData: (id, payload) =>
      instance
        .put(`/deal/${id}/refetchedData`, payload, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
  },
  document: {
    delete: (documentId) =>
      instance
        .delete(`/document/${documentId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
    get: async (dealId) => {
      const templates = {};
      const templateTypes = ['Addendum', 'Lease', 'LOI', 'Purchase', 'Service', 'Triparty'];
      await Promise.all(
        templateTypes.map(async (templateType) => {
          const res = await instance.get(`/document/templates?type=${templateType}`, {
            headers: {
              Authorization: `Bearer ${store.getState().form.user.accessToken}`,
            },
          });
          templates[templateType] = res.data.documentData;
        }),
      );
      const documents = await instance.get(`/document/deal/${dealId}`, {
        headers: {
          Authorization: `Bearer ${store.getState().form.user.accessToken}`,
        },
      });
      return {
        documents: documents.data,
        templates,
      };
    },
    post: (dealId, payload) =>
      instance
        .post(`/document/deal/${dealId}`, payload, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
    postLink: (documentId, payload) =>
      instance
        .post(`/document/${documentId}/link`, payload, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
    status: async (pandaDocId, newStatus, dealId) => {
      try {
        const documents = await instance.put(
          `/document/status/${pandaDocId}`,
          { newStatus, dealId },
          {
            headers: {
              Authorization: `Bearer ${store.getState().form.user.accessToken}`,
            },
          },
        );
        return documents;
      } catch (error) {
        return error.response;
      }
    },
  },
  estimate: {
    getEvents: (dealId) =>
      instance
        .get(`/estimate/events/${dealId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
    post: (dealId, payload) =>
      instance
        .post(`/estimate/${dealId}`, payload, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
    sendEstimateToW2EK: (qeId, payload = {}) =>
      instance.post(`/estimate/link/w2ek/${qeId}`, payload, {
        headers: {
          Authorization: `Bearer ${store.getState().form.user.accessToken}`,
        },
      }),
  },
  logDecrypt: {
    post: (payload) =>
      instance
        .post(`/logger/decrypt`, payload, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          throw err?.response?.data;
        }),
  },
  pipeline: {
    get: (opportunityId, getPrimary = false) =>
      instance
        .get(`/pipeline/opportunity/${opportunityId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
          params: { getPrimary: getPrimary },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
  },
  salesforce: {
    getDeal: (sfDealId) =>
      instance
        .get(`/salesforce/deal/${sfDealId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
    get: (opportunityId, search, getPrimary = false) =>
      instance
        .get(`/salesforce/opportunity/${opportunityId}${search}`, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
          params: { getPrimary: getPrimary },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
    put: (dealId) =>
      instance
        .put(`/salesforce/deal/${dealId}`, null, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
    patch: (sfDealId, payload) =>
      instance
        .patch(`/salesforce/deal/${sfDealId}`, payload, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
    refetch: (dealId) =>
      instance
        .post(`/salesforce/deal/${dealId}/refetch`, null, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err?.response?.data;
        }),
  },
  pricing: {
    post: async (dealId, payload) => {
      try {
        const res = await instance.post(`/pricing/offers/${dealId}`, payload, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        });
        // TODO: Remove after MVP bug resolution phase
        console.log('Pricing Response', res.data);
        return res.data;
      } catch (err) {
        throw err?.response?.data;
      }
    },
  },
  allOffers: {
    post: async (dealId, payload) => {
      try {
        const res = await instance.post(`/pricing/all-offers/${dealId}`, payload, {
          headers: {
            Authorization: `Bearer ${store.getState().form.user.accessToken}`,
          },
        });
        // TODO: Remove after MVP bug resolution phase
        console.log('All Offers Response', res.data);
        return res.data;
      } catch (err) {
        throw err?.response?.data;
      }
    },
  },
  enrichmentEngine: {
    getPdf: (pdfId) =>
      instance.get(`/enrichment/pdf?id=${pdfId}`, {
        headers: {
          Authorization: `Bearer ${store.getState().form.user.accessToken}`,
        },
        responseType: 'blob',
      }),
  },
  getLoginToken: (googleToken) =>
    instance.post('/auth/google/callback', { token: googleToken }).then((res) => res.data),
};
