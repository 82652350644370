import styled from 'styled-components';
import { Colors, FlexContainer } from '@easyknockorg/ek-ui-components';

export const Body = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  top: ${({ offsetTop }) => (offsetTop ? `${offsetTop}px` : '94px')};
  bottom: 50px;
`;

export const DealFormColumn = styled.div`
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  min-width: 400px;
  padding: 40px;
  border-right: 1px solid ${Colors.BGW.greyWhite};
`;

export const FooterHolder = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

export const HeaderHolder = styled.div`
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: ${Colors.BGW.white};
`;

export const OptionsColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 48px 40px;
`;

export const OptionsHeaderRow = styled(FlexContainer)`
  width: 100%;
  margin-bottom: 40px;
`;

export const OptionsContentRow = styled(FlexContainer)`
  & > div {
    max-width: 48%;
    flex-basis: fit-content;
    margin: -40px 0 0;
  }
`;

export const Page = styled.div`
  height: 100vh;
`;

export const Title = styled.h2`
  color: ${Colors.BGW.outerSpace};
  margin: 0;
`;

export const UWLabel = styled.div`
  margin-left: 16px;
  padding" 2px 8px;

`;
