import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useGoogleLogout } from 'react-google-login';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import actionsIcon from '../../../../images/actions.svg';
import {
  markEstimateEmailAsSent,
  localSessionLogout,
} from '../../../../pages/DealCalculator/actions';
import { alertAction } from '../../../Alert/reducer';
import {
  activityModalInfo,
  DealTypesEnum,
  DocumentType,
  matchesDefault,
  isMoveAbilityV20AndAbove,
} from '../../../../utils';
import ActivityModal from '../../../Organisms/ActivityModal';
import { UserRole } from '../../../../formulas/dto/userDto';
import { B2B_VERSION } from '../../../../formulas/config/b2b-version';
import { getStateCode } from '../../../../formulas/util/format';
import NewDocumentModal from '../../../Organisms/NewDocumentModal';
import { useDealContext } from '../../../../contexts/DealContext';
import { onWarning } from '../../../../utils/snackbar';
const StyledImg = styled.img`
  cursor: pointer;
  max-height: 50px;
  padding: 15px;
`;

const ActionsList = withStyles({
  root: {
    padding: 0,
  },
})(List);

const ActionsListSubheader = withStyles({
  root: {
    padding: '0 0 0 5px',
    lineHeight: '20px',
    fontWeight: 'bold',
  },
})(ListSubheader);

const ActionsListItem = withStyles({
  root: {
    padding: '5px',
  },
})(ListItem);

const ActionsTextBold = withStyles({
  primary: {
    color: '#454b4e',
    fontFamily: 'Kumbh Sans',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '22px',
  },
})(ListItemText);

const StyledPopover = withStyles(() => ({
  paper: {
    backgroundColor: '#f5f5f9',
    border: '1px solid #dadde9',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}))(Popover);

const Actions = ({
  passedDealData,
  setDealData,
  userData,
  mappedDealData,
  logoutAction,
  onError,
  setSnackbarState,
}) => {
  const dealData =
    Object.keys(mappedDealData).length > 0 ? mappedDealData : passedDealData ? passedDealData : {};

  const { id: dealId, uuid, VersionId } = dealData;

  const { defaultDeal } = useDealContext();

  const { signOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    onLogoutSuccess: logoutAction,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [hasLOIs, setHasLOIs] = useState(false);

  // Estimate activity
  const [eaOpen, setEaOpen] = useState(false);
  // Purchase agreement
  const [paActivityOpen, setPaActivityOpen] = useState(false);
  // Lease agreement
  const [laActivityOpen, setLaActivityOpen] = useState(false);
  // Letter of intent
  const [loiActivityOpen, setLoiActivityOpen] = useState(false);
  // Service agreement
  const [createSA, setCreateSA] = useState(false);
  const [saActivityOpen, setSaActivityOpen] = useState(false);
  // TriParty
  const [tpOpen, setTpOpen] = useState(false);
  const [tpActivityOpen, setTpActivityOpen] = useState(false);

  const [isBetaUser, setIsBetaUser] = useState(true);

  const handleClick = (event) => {
    event.stopPropagation();
    if ([B2B_VERSION, 26, 27, 28].includes(VersionId)) {
      setAnchorEl(event.currentTarget);
    } else {
      onError(
        'CTA is disabled due to version being outdated, ' +
          'reach out to your manager for clarification',
      );
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onPreviewEstimate = () => {
    handleClose();
    window.open(`${process.env.REACT_APP_W2EK_ROOT_URL}/estimate/${dealData.uuid}`);
  };

  const handleOpen = (setOpen) => {
    handleClose();
    setOpen(true);
  };

  const activityModals = activityModalInfo(
    laActivityOpen,
    setLaActivityOpen,
    loiActivityOpen,
    setLoiActivityOpen,
    paActivityOpen,
    setPaActivityOpen,
    saActivityOpen,
    setSaActivityOpen,
    tpActivityOpen,
    setTpActivityOpen,
  );

  const isSnapshot = !!dealData?.snapshotName;
  const isCurrentUserSales = userData?.role === UserRole.Default;
  const stateCode = getStateCode(dealData.state);
  const shouldEnableSA =
    isSnapshot &&
    dealData.salesforceDealId &&
    isCurrentUserSales &&
    dealData.DealTypeId === DealTypesEnum.SellAndStay &&
    ['FL', 'NC', 'TN'].includes(stateCode);

  return (
    <>
      <StyledImg alt="actions" onClick={handleClick} src={actionsIcon} />
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div style={{ margin: '10px 15px', minWidth: '180px' }}>
          <ActionsList
            component="nav"
            subheader={isSnapshot && <ActionsListSubheader>ESTIMATE</ActionsListSubheader>}
          >
            {isSnapshot && (
              <ActionsListItem button onClick={() => onPreviewEstimate(true)}>
                <ActionsTextBold>Preview Estimate</ActionsTextBold>
              </ActionsListItem>
            )}
            {isSnapshot && dealData.estimateEmailed && (
              <ActionsListItem button onClick={() => handleOpen(setEaOpen)}>
                <ActionsTextBold>Estimate Activity</ActionsTextBold>
              </ActionsListItem>
            )}
            {shouldEnableSA && (
              <>
                <Divider />
                <ActionsListSubheader style={{ marginTop: '10px' }}>SA</ActionsListSubheader>
                <ActionsListItem button onClick={() => handleOpen(setCreateSA)}>
                  <ActionsTextBold>Create Service Agreement</ActionsTextBold>
                </ActionsListItem>
                {dealData.saSent && (
                  <ActionsListItem button onClick={() => handleOpen(setSaActivityOpen)}>
                    <ActionsTextBold>Service Agreement Activity</ActionsTextBold>
                  </ActionsListItem>
                )}
              </>
            )}

            {/* TRIPARTY START */}
            {isMoveAbilityV20AndAbove(dealData) && dealData.salesforceDealId && (
              <>
                <Divider />
                <ActionsListSubheader style={{ marginTop: '10px' }}>TRIPARTY</ActionsListSubheader>
                <ActionsListItem
                  button
                  onClick={
                    matchesDefault(dealData, defaultDeal, [
                      'ownersAgentFirst',
                      'ownersAgentLast',
                      'ownersAgentEmail',
                      'commissionTotalPercent',
                    ])
                      ? () => handleOpen(setTpOpen)
                      : () => {
                          onWarning(
                            setSnackbarState,
                            `Snapshot Agent Details do not match Default Deal, please create a new snapshot`,
                          );
                          //Logging for salesassistance
                          console.log(
                            'default: ',
                            `${defaultDeal.ownersAgentFirst}, ${defaultDeal.ownersAgentLast}, ${defaultDeal.ownersAgentEmail}, ${defaultDeal.commissionTotalPercent}`,
                          );
                          console.log(
                            'snapshot: ',
                            `${dealData.ownersAgentFirst}, ${dealData.ownersAgentLast}, ${dealData.ownersAgentEmail}, ${dealData.commissionTotalPercent}`,
                          );
                        }
                  }
                >
                  <ActionsTextBold>Create Triparty Agreement</ActionsTextBold>
                </ActionsListItem>
                <ActionsListItem button onClick={() => handleOpen(setTpActivityOpen)}>
                  <ActionsTextBold>Triparty Agreement Activity</ActionsTextBold>
                </ActionsListItem>
              </>
            )}
            {/* TRIPARTY END */}

            {hasLOIs && (
              <>
                <Divider />
                <ActionsListSubheader style={{ marginTop: '10px' }}>LOI</ActionsListSubheader>
                <ActionsListItem button onClick={() => handleOpen(setLoiActivityOpen)}>
                  <ActionsTextBold>Letter of Intent Activity</ActionsTextBold>
                </ActionsListItem>
              </>
            )}
            {dealData.paSent === true && isCurrentUserSales && (
              <>
                <Divider />
                <ActionsListSubheader style={{ marginTop: '10px' }}>PA</ActionsListSubheader>
                <ActionsListItem button onClick={() => handleOpen(setPaActivityOpen)}>
                  <ActionsTextBold>Purchase Agreement Activity</ActionsTextBold>
                </ActionsListItem>
              </>
            )}
            {dealData.laSent === true && isCurrentUserSales && (
              <>
                <Divider />
                <ActionsListSubheader style={{ marginTop: '10px' }}>LA</ActionsListSubheader>
                <ActionsListItem button onClick={() => handleOpen(setLaActivityOpen)}>
                  <ActionsTextBold>Lease Agreement Activity</ActionsTextBold>
                </ActionsListItem>
              </>
            )}
            {isSnapshot && <Divider />}
            <ActionsListItem
              button
              onClick={() => {
                signOut();
              }}
            >
              <ActionsTextBold data-test-id="logout-button">Log out</ActionsTextBold>
            </ActionsListItem>
          </ActionsList>
        </div>
      </StyledPopover>
      <ActivityModal
        type="email"
        title="Estimate Activity"
        open={eaOpen}
        setOpen={setEaOpen}
        dealData={dealData}
        userData={userData}
        setDealData={setDealData}
      />
      {activityModals.map((modal, idx) => (
        <ActivityModal
          key={idx}
          type="document"
          documentType={modal.documentType}
          title={modal.title}
          open={modal.open}
          setOpen={modal.setOpen}
          dealData={dealData}
          setDealData={setDealData}
          userData={userData}
          submitLabel={modal.submitLabel}
          setHasLOIs={setHasLOIs}
        />
      ))}
      <NewDocumentModal
        dealData={dealData}
        setDealData={setDealData}
        docType={DocumentType.Service}
        title="Create Service Agreement"
        submitLabel="Create SA"
        open={createSA}
        setOpen={setCreateSA}
        setSnackbarState={setSnackbarState}
      />
      <NewDocumentModal
        dealData={dealData}
        setDealData={setDealData}
        docType={DocumentType.Triparty}
        title="Triparty"
        submitLabel="Create TP"
        open={tpOpen}
        setOpen={setTpOpen}
        setSnackbarState={setSnackbarState}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const dealData = _.get(state, 'form.dealCalculator.values') || {};
  return {
    mappedDealData: { ...dealData },
  };
};

export default connect(mapStateToProps, {
  markEstimateEmailAsSentAction: markEstimateEmailAsSent,
  onError: (message) => alertAction({ open: true, message, severity: 'error' }),
  logoutAction: localSessionLogout,
  goToUrlAction: push,
})(Actions);
