import styled from 'styled-components';
import { Colors } from '@easyknockorg/ek-ui-components';
export const Oval = styled.div`
  margin-left: 16px;
  padding: 2px 8px;
  color: ${Colors.BGW.charcoal1};
  border-radius: 100px;
  font-size: 13px;
  &.Approved {
    background-color: ${Colors.PineGreen.secondaryGreenTransparency};
  }
  &.Declined {
    background-color: ${Colors.Secondary.redTransparency};
  }
  &.UW {
    background-color: ${Colors.Secondary.orangeTransparency};
  }
`;
