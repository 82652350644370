import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';

const LoadingOverlay = ({ loading, showProgressBar }) =>
  loading ? (
    <div
      style={{
        position: 'absolute',
        backdropFilter: 'blur(6px)',
        left: 0,
        top: 0,
        width: '100%',
        zIndex: '1100',
        height: '100%',
      }}
    >
      {showProgressBar ? <LinearProgress /> : null}
    </div>
  ) : null;

const mapStateToProps = (state, ownProps) => {
  return {
    loading: !_.get(state, 'form.dealCalculator.values') || ownProps.loading,
    authenticated: _.get(state, 'form.user'),
  };
};

export default connect(mapStateToProps)(LoadingOverlay);
