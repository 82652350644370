import _ from 'lodash';
import accounting from 'accounting';
import TextInput from './components/TextInput';
import SelectInput from './components/SelectInput';
import RowLayout from './components/RowLayout';
import ActualRentTooltip from './components/Tooltip/ActualRentTooltip';
import HoldbackMonthsTooltip from './components/Tooltip/HoldbackMonthsTooltip';
import HomeValueTooltip from './components/Tooltip/HomeValueTooltip';
import TextDisplay from './components/TextDisplay';
import {
  DealTypesEnum,
  isMoveAbilityV6AndAbove,
  isSellStayV6AndAbove,
  isMoveAbilityV20AndAbove,
  isVRevamp,
  calcCashFundingPercentage,
} from '../../utils';
import { Colors } from '@easyknockorg/ek-ui-components';
import { UserRole } from '../../formulas/dto/userDto';
import MessageTooltip from './components/Tooltip/MessageTooltip';
import { DealStages } from '../../utils/constansts';
import { ALTERNATE_OPTION_LABEL } from '../../constants';

const houseInfoSectionMap = [
  {
    k: 'purchasePrice',
    label: 'Home Value',
    formatAsMoney: true,
    component: TextInput,
    tooltip: HomeValueTooltip,
  },
  {
    k: 'mortgageBalance',
    label: 'Mortgage Balance',
    formatAsMoney: true,
    component: TextInput,
  },
  {
    k: 'liens',
    label: 'Liens / Loans',
    formatAsMoney: true,
    component: TextInput,
  },
];

const genCashFundingSectionMap = (deal = {}, user) => {
  const maxCashFunding =
    (user && user.role) === 'Underwriting' ? deal.purchasePrice : deal.maxCashFunding;

  return [
    {
      component: SelectInput,
      k: 'DealTypeId',
      label: 'Solution',
      options: [
        { value: 1, label: 'Sell & Stay' },
        { value: 2, label: 'MoveAbility' },
        { value: 3, label: 'ReLease' },
        { value: 4, label: 'ReLease Non EK' },
      ],
    },
    {
      component: TextInput,
      k: 'cashFunding',
      label: 'Cash Funding',
      formatAsMoney: true,
      hasWarningIcon: true,
      max: maxCashFunding,
      overridable: true,
      percent: {
        percent: accounting.toFixed((deal.cashFunding / deal.purchasePrice) * 100, 2),
      },
      slider: {
        step: maxCashFunding && maxCashFunding / 100,
        marks: [
          { value: 0, label: '0%' },
          maxCashFunding
            ? {
                value: maxCashFunding,
                label: `${accounting.formatMoney(maxCashFunding, '$ ', 0)} / ${accounting.toFixed(
                  (maxCashFunding / deal.purchasePrice) * 100,
                )} %`,
              }
            : { value: '', label: '' },
        ],
      },
    },
  ];
};

const genRentCostsSectionMap = (deal = {}) => [
  {
    component: TextInput,
    formatAsMoney: true,
    k: 'realEstateTaxes',
    label: 'Real Estate Taxes',
    max: deal.maxRealEstateTaxes,
    overridable: true,
    percent: {
      percent: accounting.toFixed((deal.realEstateTaxes / deal.purchasePrice) * 100, 2),
    },
  },
  {
    component: TextInput,
    formatAsMoney: true,
    k: 'hoa',
    label: 'HOA',
  },
  {
    component: TextInput,
    formatAsMoney: true,
    k: 'landlordInsurance',
    label: 'Property Insurance',
    max: deal.maxLandlordInsurance,
    overridable: true,
    percent: {
      percent: deal.purchasePrice
        ? accounting.toFixed((deal.landlordInsurance / deal.purchasePrice) * 100, 1)
        : 0,
    },
  },
  {
    component: TextDisplay,
    formatAsMoney: true,
    k: 'landlordExpenses',
    label: 'Landlord Expense',
    percent: {
      percent: deal.annualRent
        ? Math.round((100 * deal.landlordExpenses) / deal.annualRent)
        : 'N/A',
    },
    style: { marginBottom: '40px', marginTop: '-10px' },
  },
];

const genMonthlyRentSectionMap = (deal = {}, user) => {
  const monthlyRentMin =
    deal.VersionId < 5 || (user && user.role) === 'Underwriting' ? 0 : deal.medianMonthlyRent || 0;
  const monthlyRentMax =
    deal.VersionId < 5 || (user && user.role) === 'Underwriting'
      ? 16000
      : deal.upperMonthlyRent || 16000;

  const desiredRentMin =
    deal.VersionId < 8 || (user && user.role) === 'Underwriting'
      ? 0
      : Math.round(0.15 * (deal.monthlyRent || 0));

  return [
    {
      component:
        deal.DealTypeId == DealTypesEnum.ReLease || deal.DealTypeId == DealTypesEnum.ReLeaseNonEK
          ? TextInput
          : RowLayout,
      k: 'rentHoldbackMonths',
      label: 'Staying',
      components: [
        {
          component: TextInput,
          k: 'rentHoldbackMonths',
          label: 'Staying',
        },
      ],
    },
    {
      component: TextInput,
      k: 'monthlyRent',
      label: 'Actual rent',
      formatAsMoney: true,
      overridable: true,
      min: monthlyRentMin,
      max: monthlyRentMax,
      slider: {
        step: 20,
        marks: [
          { value: monthlyRentMin, label: `$${monthlyRentMin}` },
          {
            value: monthlyRentMax,
            label: `$${monthlyRentMax}`,
          },
        ],
      },
      tooltip: ActualRentTooltip,
    },
    {
      component: TextInput,
      k: 'desiredRent',
      label: "Customer's Desired Rent",
      formatAsMoney: true,
      min: desiredRentMin,
      max: deal.monthlyRent,
      disabled: !deal.monthlyRent,
      slider: {
        step: deal.monthlyRent / 100,
        marks: [
          { value: desiredRentMin, label: `$${desiredRentMin}` },
          deal.monthlyRent
            ? {
                value: deal.monthlyRent,
                label: `${accounting.formatMoney(deal.monthlyRent, '$ ', 0)}`,
              }
            : { value: '', label: '' },
        ],
      },
    },
    {
      component: TextInput,
      k: 'rentHoldbackMonths',
      label: 'Holdback Months',
      errorFormula: (value) => {
        if (deal.desiredRent === 0 && value === 0) {
          return 'Cannot be 0 when desired rent is 0';
        }
        return null;
      },
      tooltip: deal.VersionId >= 8 ? HoldbackMonthsTooltip : null,
    },
    {
      component: RowLayout,
      components: [
        {
          component: TextDisplay,
          k: 'rentHoldbackPays',
          format: (value) => accounting.formatMoney(value, '$ ', 0),
          label: 'Monthly Rent Holdback',
        },
        {
          component: TextDisplay,
          k: 'rentHoldbackAmount',
          format: (value) => accounting.formatMoney(value, '$ ', 0),
          label: 'Total Rent Holdback',
        },
      ],
    },
    deal.DealTypeId == DealTypesEnum.ReLease && {
      component: TextDisplay,
      k: 'securityDeposit',
      format: (value) => accounting.formatMoney(value, '$ ', 0),
      label: 'Security Deposit',
    },
  ];
};

const getEkFees = (dealData) => {
  return {
    title: `EK Processing Fee ${
      dealData.purchasePrice
        ? ((dealData.easyKnockProcessingFee / dealData.purchasePrice) * 100).toFixed(2)
        : '0.00'
    }%`,
    icon: 'minus',
    amount: dealData.easyKnockProcessingFee,
  };
};

const getCashUponSaleV6 = (dealData) => {
  // Older snapshots aren't able to access `cashUponSaleYear1`
  // In that case we'll need to return the direct calculation here
  return (
    dealData.cashUponSaleYear1 ??
    (dealData.purchasePrice || 0) -
      (dealData.realtorCosts || 0) -
      (dealData.repurchasePriceYear1 || 0)
  );
};

const transactionCostsSectionMap = [
  {
    component: TextInput,
    k: 'closingCosts',
    label: 'Closing Costs',
    overridable: true,
    formatAsMoney: true,
  },
  {
    component: TextInput,
    k: 'transferTax',
    label: 'Transfer Tax',
    overridable: true,
    formatAsMoney: true,
  },
  {
    component: TextInput,
    k: 'remainingPropertyTax',
    label: 'Remaining Property Tax Owed',
    overridable: true,
    formatAsMoney: true,
  },
];

export const cashFundingSectionFields = (dealData) => {
  const ekFees = getEkFees(dealData);

  return cashFundingVRevamp(dealData, ekFees);
};

const rentAndRepairCostsHeaderAmount = (dealData, postPDD) => {
  // After version v26 is expired, deleted the else statement (v26/v27)
  if (dealData.VersionId >= 27) {
    return (
      dealData.proratedRent +
      dealData.rentPrepayment +
      dealData.rentHoldbackAmount * (12 - dealData.rentHoldbackMonths) +
      dealData.repairFundAmount +
      dealData.warranty
    );
  } else {
    return (
      dealData.proratedRent +
      dealData.monthlyRent +
      dealData.rentHoldbackAmount * dealData.rentHoldbackMonths +
      dealData.repairFundAmount +
      (postPDD ? 0 : dealData.warranty)
    );
  }
};

const rentPrepaymentAmount = (dealData) => {
  if (dealData.VersionId >= 27) {
    return dealData.rentPrepayment;
  } else {
    return dealData.monthlyRent;
  }
};

const partialRentPrepaymentAmount = (dealData) => {
  if (dealData.VersionId >= 27) {
    return dealData.rentHoldbackAmount * (12 - dealData.rentHoldbackMonths);
  } else {
    return dealData.rentHoldbackAmount * dealData.rentHoldbackMonths;
  }
};

// Dalton: UI needs to change to read  (12 - rentHoldbackMonths) in UI (v26/v27)
const partialRentPrepaymentAmountLabel = (dealData) => {
  if (dealData.VersionId >= 27) {
    return `Partial Rent Prepayment:\n ${accounting.formatMoney(
      dealData.rentHoldbackAmount || 0,
      '$ ',
      2,
      // STARTING WITH V27 Holdabck Months is no longer tied to holdback amount
      // but represents the number of months of full prepayment as required by the lender
    )} monthly for ${12 - dealData.rentHoldbackMonths || 0} months`;
  } else {
    return `Partial Rent Prepayment:\n ${accounting.formatMoney(
      dealData.rentHoldbackAmount || 0,
      '$ ',
      2,
      // PRIOR TO V27 Holdabck Months was tied to holdback amount so we can use it directly here
    )} monthly for ${dealData.rentHoldbackMonths || 0} months`;
  }
};

const cashFundingVRevamp = (dealData, ekFees) => {
  const cashFundingPercentage = calcCashFundingPercentage(
    dealData?.cashFunding,
    dealData?.purchasePrice,
  );

  const postPDD =
    DealStages.hasOwnProperty(dealData.stageName) &&
    DealStages[dealData.stageName] >= DealStages['Processing Post Review'] &&
    dealData.pddCompletedDate != null;

  const EstimatedTransactionCosts = isMoveAbilityV20AndAbove(dealData)
    ? {
        title: 'Estimated Transaction Costs',
        icon: 'minus',
        amount:
          dealData.closingCosts +
          dealData.transferTax +
          dealData.remainingPropertyTax +
          dealData.agentFirstFeeValue,
        details: [
          {
            title: 'Estimated Seller Closing Costs',
            amount: dealData.closingCosts,
            icon: 'minus',
          },
          {
            title: 'Estimated Transfer Tax',
            amount: dealData.transferTax,
            icon: 'minus',
          },
          {
            title: 'Estimated Remaining Property Tax Owed',
            amount: dealData.remainingPropertyTax,
            icon: 'minus',
          },
        ],
      }
    : {
        title: 'Estimated Transaction Costs',
        icon: 'minus',
        amount: dealData.closingCosts + dealData.transferTax + dealData.remainingPropertyTax,
        details: [
          {
            title: 'Estimated Seller Closing Costs',
            amount: dealData.closingCosts,
            icon: 'minus',
          },
          {
            title: 'Estimated Transfer Tax',
            amount: dealData.transferTax,
            icon: 'minus',
          },
          {
            title: 'Estimated Remaining Property Tax Owed',
            amount: dealData.remainingPropertyTax,
            icon: 'minus',
          },
        ],
      };

  return [
    {
      title: "Client's Cash",
      message:
        'The amount of money the client will receive upon us purchasing your house after the following costs:',
    },
    {
      title: `Cash Funding (${cashFundingPercentage}%)`,
      amount: dealData.cashFunding || 0,
    },
    {
      title: ekFees.title,
      icon: ekFees.icon,
      amount: ekFees.amount,
      details: ekFees.details,
    },
    {
      title: 'Rent and Repair Costs',
      icon: 'minus',
      amount: rentAndRepairCostsHeaderAmount(dealData, postPDD),
      details: [
        {
          title: 'Prorated Rent:',
          amount: dealData.proratedRent,
          icon: 'minus',
        },
        {
          title: 'Rent Prepayment',
          amount: rentPrepaymentAmount(dealData),
          icon: 'minus',
        },
        {
          title: partialRentPrepaymentAmountLabel(dealData),
          amount: partialRentPrepaymentAmount(dealData),
          icon: 'minus',
        },
        {
          title: `Seller's Credit`,
          amount: dealData.repairFundAmount,
          icon: 'minus',
        },
        {
          // STARTING WITH v27 we are switching back to an always present, always calculated warranty
          title: `Warranty`,
          amount: dealData.VersionId >= 27 ? dealData.warranty : postPDD ? 0 : dealData.warranty,
          icon: 'minus',
        },
      ],
    },
    {
      title: 'Mortgage and Liens',
      icon: 'minus',
      amount: dealData.mortgageBalance + dealData.liens,
      details: [
        {
          title: 'Mortgage',
          amount: dealData.mortgageBalance,
          icon: 'minus',
        },
        {
          title: 'Other Loans/Liens',
          amount: dealData.liens,
          icon: 'minus',
        },
      ],
    },
    EstimatedTransactionCosts,
    {
      title: "Client's Cash",
      icon: 'equals',
      amount: dealData.cashRelease,
      summary: true,
      titleAdornment: 'dollar',
    },
  ];
};

export const dealFormSections = (deal, user) => [
  {
    title: 'House Info',
    fields: houseInfoSectionMap,
  },
  {
    title: 'Cash Funding',
    fields: genCashFundingSectionMap(deal, user).filter((f) => f),
  },
  {
    title: 'Rent Costs',
    fields: genRentCostsSectionMap(deal).filter((f) => f),
  },
  {
    title: 'Monthly Rent',
    fields: genMonthlyRentSectionMap(deal, user).filter((f) => f),
  },
  {
    title: 'Transaction Costs',
    fields: transactionCostsSectionMap,
    deal: deal,
  },
];

const getMoveDetails = (dealData, year) => {
  return [
    {
      title: 'Estimated Home Sale Price',
      icon: 'minusDark',
      amount: dealData.purchasePrice || 0,
    },
    {
      title: 'Estimated Agent Cost',
      icon: 'minusDark',
      amount: dealData.realtorCosts || 0,
    },
    {
      title: `Total Due to EK in Year ${year}`,
      icon: 'minusDark',
      amount: getRepurchasePriceByYear(dealData, year) || 0,
    },
  ];
};

const getRepurchaseDetails = (dealData, year) => {
  // Start with an initial array, and concatenate each year information
  // Each year will display its own information + info from the previous years
  const array = [
    {
      title: 'Option Exercise Price',
      amount: dealData.cashFunding,
      icon: 'plus',
    },
    {
      title:
        dealData.VersionId <= 14 || dealData.DealTypeId === DealTypesEnum.MoveAbility
          ? 'Remaining Year 1 Option Fee'
          : `Year 1 Option Fee (${(
              (dealData.optionFeeYear1 / dealData.purchasePrice) *
              100
            ).toFixed(2)}%)`,
      amount:
        dealData.VersionId <= 14 || dealData.DealTypeId === DealTypesEnum.MoveAbility
          ? dealData.optionFeeYear1 * 0.5
          : dealData.optionFeeYear1,
      icon: 'plus',
    },
  ];

  if (year === 1) return array;

  array.push({
    title: `Year 2 Option Fee (${dealData.optionPeriodIncreaseYear2}%)`,
    amount: dealData.optionFeeYear2,
    icon: 'plus',
  });

  if (year === 2) return array;

  array.push({
    title: `Year 3 Option Fee (${dealData.optionPeriodIncreaseYear3}%)`,
    amount: dealData.optionFeeYear3,
    icon: 'plus',
  });

  if (year === 3) return array;

  array.push({
    title: `Year 4 Option Fee (${dealData.optionPeriodIncreaseYear4}%)`,
    amount: dealData.optionFeeYear4,
    icon: 'plus',
  });

  if (year === 4) return array;

  array.push({
    title: `Year 5 Option Fee (${dealData.optionPeriodIncreaseYear5}%)`,
    amount: dealData.optionFeeYear5,
    icon: 'plus',
  });

  return array;
};

const getRepurchasePriceByYear = (dealData, year) => {
  if (year === 1) return dealData.repurchasePriceYear1;
  else if (year === 2) return dealData.repurchasePriceYear2;
  else if (year === 3) return dealData.repurchasePriceYear3;
  else if (year === 4) return dealData.repurchasePriceYear4;
  else if (year === 5) return dealData.repurchasePriceYear5;
};

export const monthlyNetRentFields = (dealData) => {
  const { monthlyRent, monthlyNetRent, rentHoldbackAmount } = dealData;

  return [
    {
      title: 'Monthly Net Rent',
      message:
        'The amount to be paid during the term of the rent holdback. After it expires, the Monthly Gross Rent will be the monthly amount paid.',
    },
    {
      title: 'Monthly Gross Rent',
      amount: monthlyRent || 0,
    },
    {
      title: 'Monthly Rent Holdback',
      icon: 'minus',
      amount: rentHoldbackAmount || 0,
    },
    {
      title: 'Monthly Net Rent',
      icon: 'equals',
      summary: true,
      titleAdornment: 'dollar',
      amount: monthlyNetRent || 0,
    },
  ];
};

export const monthlyRentSectionFields = (dealData) => {
  return [
    {
      title: 'Monthly Gross Rent',
      amount: dealData.monthlyRent,
    },
    {
      title: 'Monthly Rent Holdback',
      icon: 'minus',
      message: `Rent holdback for ${
        isSellStayV6AndAbove(dealData) || isMoveAbilityV6AndAbove(dealData) ? '' : 'the first'
      } ${dealData.rentHoldbackMonths ?? 0} months`,
      amount: dealData.rentHoldbackPays,
    },
    {
      title: 'Monthly Net Rent',
      icon: 'equals',
      amount: dealData.monthlyRent - dealData.rentHoldbackPays,
      summary: true,
      titleAdornment: 'dollar',
    },
  ];
};

export const optionValueFields = (dealData) => {
  return [
    {
      title: 'Option Value',
      message:
        'Estimated value of the Option Agreement that allows the client to either repurchase the property or direct EasyKnock to sell.',
    },
    {
      title: 'Home Value',
      amount: dealData.purchasePrice || 0,
    },
    {
      title: 'Cash Funding',
      icon: 'minus',
      amount: dealData.cashFunding || 0,
    },
    {
      title: 'Current Option Value',
      icon: 'equals',
      summary: true,
      titleAdornment: 'dollar',
      amount: (dealData.purchasePrice || 0) - (dealData.cashFunding || 0), // TODO: make formula for this
    },
  ];
};

export const repurchasePriceSectionFields = (dealData) => {
  return [
    {
      title: 'Opt 1: Repurchase',
      message:
        'These are the totals by year the customer will have to pay EK if they decide to exercise their option to buy the house back.',
    },
    {
      title: 'Total Due to EK Year 1',
      icon: 'equals',
      amount: dealData.repurchasePriceYear1,
      details: getRepurchaseDetails(dealData, 1),
    },
    {
      title: 'Total Due to EK Year 2',
      icon: 'equals',
      amount: dealData.repurchasePriceYear2,
      details: getRepurchaseDetails(dealData, 2),
    },
    {
      title: 'Total Due to EK Year 3',
      icon: 'equals',
      amount: dealData.repurchasePriceYear3,
      details: getRepurchaseDetails(dealData, 3),
    },
    {
      title: 'Total Due to EK Year 4',
      icon: 'equals',
      amount: dealData.repurchasePriceYear4,
      details: getRepurchaseDetails(dealData, 4),
    },
    {
      title: 'Total Due to EK Year 5',
      icon: 'equals',
      amount: dealData.repurchasePriceYear5,
      details: getRepurchaseDetails(dealData, 5),
    },
  ];
};

const moveFieldsV5AndBelow = (dealData) => {
  return [
    {
      title: 'Estimated Future Home Sale Price',
      amount: dealData.purchasePrice || 0,
    },
    {
      title: 'Repurchase Price',
      icon: 'minus',
      amount: dealData.repurchasePriceYear1 || 0,
    },
    {
      title: 'Estimated Closing Cost',
      icon: 'minus',
      amount: dealData.closingCosts || 0,
    },
    {
      title: 'Estimated Agent Cost',
      icon: 'minus',
      amount: dealData.realtorCosts || 0,
    },
    {
      title: 'Cash Upon Sale',
      icon: 'equals',
      amount:
        (dealData.purchasePrice || 0) -
        (dealData.closingCosts || 0) -
        (dealData.realtorCosts || 0) -
        (dealData.repurchasePriceYear1 || 0),
      summary: true,
      titleAdornment: 'dollar',
    },
  ];
};

export const moveSectionFields = (dealData) => {
  if (isSellStayV6AndAbove(dealData)) {
    return [
      {
        title: 'Opt 2: Move',
        message:
          'These are the total cash estimates by year the customer will receive if they have EasyKnock sell the house on the market.',
      },
      {
        title: 'Cash Upon Sale Year 1',
        icon: 'equals',
        amount: getCashUponSaleV6(dealData),
        details: getMoveDetails(dealData, 1),
      },
      {
        title: 'Cash Upon Sale Year 2',
        icon: 'equals',
        amount: dealData.cashUponSaleYear2,
        details: getMoveDetails(dealData, 2),
      },
      {
        title: 'Cash Upon Sale Year 3',
        icon: 'equals',
        amount: dealData.cashUponSaleYear3,
        details: getMoveDetails(dealData, 3),
      },
      {
        title: 'Cash Upon Sale Year 4',
        icon: 'equals',
        amount: dealData.cashUponSaleYear4,
        details: getMoveDetails(dealData, 4),
      },
      {
        title: 'Cash Upon Sale Year 5',
        icon: 'equals',
        amount: dealData.cashUponSaleYear5,
        details: getMoveDetails(dealData, 5),
      },
    ];
  } else if (isMoveAbilityV6AndAbove(dealData)) {
    return [
      {
        title: 'Move',
        message:
          'This is an estimate for what the customer will receive when they have EasyKnock sell the house on the market.',
      },
      {
        title: 'Estimated Home Sale Price',
        amount: dealData.purchasePrice || 0,
      },
      {
        title: 'Option Exercise Price',
        icon: 'minus',
        amount: dealData.repurchasePriceYear1 || 0,
      },
      {
        title: 'Estimated Agent Cost',
        icon: 'minus',
        amount: dealData.realtorCosts || 0,
      },
      {
        title: 'Cash Upon Sale',
        icon: 'equals',
        amount: getCashUponSaleV6(dealData),
        summary: true,
        titleAdornment: 'dollar',
      },
    ];
  }
};

// ------------------------------------------------------ Revamp ------------------------------------------------------
export const dealFormVRevamp = (deal, user) => [
  {
    title: 'Funding Information',
    fields: fundingFields(deal, user),
  },
  {
    title: 'Rent',
    fields: rentFields(deal, user),
  },
  {
    title: 'Additional Costs',
    fields: additionalCostsFields(deal, user),
  },
];

const fundingFields = (deal, user) => {
  const maxCashFunding =
    (user && user.role) === 'Underwriting' ? deal.purchasePrice : deal.maxCashFunding;

  return [
    {
      name: 'purchasePrice',
      label: 'Purchase Price',
      formatAsMoney: true,
      overridable: true,
      tooltip: HomeValueTooltip,
    },
    {
      name: 'cashFunding',
      label: 'Cash Funding',
      formatAsMoney: true,
      overridable: true,
      percent: accounting.toFixed((deal?.cashFunding / deal?.purchasePrice) * 100, 2),
      ranges: {
        min: 0,
        max: maxCashFunding,
      },
      slider: {
        max: maxCashFunding,
        minLabel: '$ 0',
        maxLabel: `${accounting.formatMoney(maxCashFunding, '$ ', 0)}`,
      },
    },
  ];
};

const rentFields = (deal, user) => {
  let lowerMonthlyRent = deal.lowerMonthlyRent;
  if (
    user?.role === UserRole.Underwriting &&
    deal.optionSelected?.includes(ALTERNATE_OPTION_LABEL) &&
    deal.desiredRent < deal.lowerMonthlyRent
  ) {
    lowerMonthlyRent = deal.desiredRent;
  }
  return [
    {
      name: 'monthlyRent',
      label: 'Gross Rent',
      formatAsMoney: true,
      overridable: true,
      tooltip: ActualRentTooltip,
      ranges: {
        min: lowerMonthlyRent,
        max: deal.upperMonthlyRent,
      },
    },
    {
      name: 'monthlyNetRent',
      label: "Customer's Net Rent",
      formatAsMoney: true,
      overridable: true,
      ranges: {
        min: 0,
        max: deal.monthlyRent,
      },
    },
  ];
};

const additionalCostsFields = (deal, user) => {
  return [
    {
      name: 'realEstateTaxes',
      label: 'Real Estate Taxes',
      formatAsMoney: true,
      overridable: true,
    },
    {
      name: 'propertyInsurance',
      label: 'Property Insurance',
      formatAsMoney: true,
      overridable: true,
      tooltip: MessageTooltip,
    },
    {
      name: 'floodInsurance',
      label: 'Flood Insurance',
      formatAsMoney: true,
      overridable: true,
    },
    {
      name: 'hoa',
      label: 'HOA',
      formatAsMoney: true,
      overridable: true,
    },
    {
      name: 'closingCosts',
      label: 'Closing Costs',
      formatAsMoney: true,
      overridable: true,
    },
  ];
};
