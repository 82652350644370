import MuiSlider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

const WrappedSlider = ({ input: { onChange, ...inputProps }, ...props }) => (
  <MuiSlider
    aria-labelledby="slider"
    {...inputProps}
    {...props}
    onChange={(e, val) => {
      e.stopPropagation();
      onChange(val);
    }}
  />
);

export default WrappedSlider;
