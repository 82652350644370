import { Colors } from '@easyknockorg/ek-ui-components';
import Grid from '@material-ui/core/Grid';
import { createTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useDealContext } from '../../contexts/DealContext';
import { UserRole } from '../../formulas/dto/userDto';
import { isMoveAbilityV20AndAbove, isSellStayV6AndAbove, isVRevamp } from '../../utils';
import apiClient from '../../utils/apiClient';
import CalculatorCard, { MoveAbilityCalculatorCard } from './components/CalculatorCard';
import './index.css';
import {
  cashFundingSectionFields,
  monthlyNetRentFields,
  moveSectionFields,
  optionValueFields,
  repurchasePriceSectionFields,
} from './sectionFieldsMap';

let ir;
const saveDealEvery = _.memoize((t, saveData) => {
  if (ir) {
    clearInterval(ir);
    ir = null;
  }
  ir = setInterval(saveData, t);
});

let tr;
const onAccordionClick = (event, expanded) => {
  if (expanded) {
    if (tr) {
      clearTimeout(tr);
      tr = null;
    }
    tr = setTimeout(() => {
      window.scrollBy(0, 260);
    }, 250);
  }
};

export const theme = createTheme({
  typography: {
    fontFamily: "'Kumbh Sans', sans-serif",
    fontSize: 10,
  },
  overrides: {
    MuiAccordionSummary: {
      content: {
        alignItems: 'flex-start',
        width: '81%',
        flexGrow: 0,
      },
    },
    MuiAutocomplete: {
      paper: {
        margin: 0,
      },
      option: {
        border: `1px solid ${Colors.BGW.greyWhite}`,
        marginBottom: '-1px',
        '&:first-of-type': {
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
        },
        '&:last-of-type': {
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px',
        },
      },
      listbox: {
        borderRadius: '5px',
        padding: 0,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 14,
        width: '203px',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 16,
        color: '#454b4e',
      },
    },
    MuiSlider: {
      root: {
        color: '#00DBB1',
        height: 4,
      },
      thumb: {
        height: 13,
        width: 13,
        backgroundColor: '#00DBB1',
        border: '2px solid currentColor',
        marginTop: -5,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
          boxShadow: 'inherit',
        },
      },
      active: {},
      valueLabel: {
        left: 'calc(-50% + 4px)',
      },
      track: {
        height: 4,
        borderRadius: 4,
      },
      rail: {
        height: 4,
        borderRadius: 4,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
  },
  palette: {
    text: { primary: '#000' },
    primary: { main: '#00DBB1' },
    error: { main: '#DE7410' },
  },
  shadows: ['none'],
});

const FutureOptionsView = ({ dealData }) => {
  const elementsUnder6Months = [
    { title: 'Option Exercise Price', amount: dealData.repurchasePriceYear1 || 0 },
    {
      title: `${dealData.backendFee1Percent}% Option Fee (≤ 6 months)`,
      amount: dealData.backendFee1Value || 0,
    },
  ];

  const elementsOver6Months = [
    { title: 'Option Exercise Price', amount: dealData.repurchasePriceYear1 || 0 },
    {
      title: `${dealData.backendFee1Percent}% Option Fee (≤ 6 months)`,
      amount: dealData.backendFee1Value || 0,
    },
    {
      title: `${dealData.backendFee2Percent}% Option Fee (> 6 months)`,
      amount: dealData.backendFee2Value || 0,
    },
  ];
  return (
    <>
      {isMoveAbilityV20AndAbove(dealData) ? (
        <>
          <Grid item md={5} style={{}}>
            <MoveAbilityCalculatorCard
              data={dealData}
              cashUponSaleAmount={dealData.cashUponSaleUnder6Months}
              purchasePrice={dealData.purchasePrice}
              elements={elementsUnder6Months}
              title={'Sell home and move within 6 months'}
              copy={
                'These are the estimates of the amounts the customer will pay and receive when they direct EasyKnock to sell the house on the market within 6 months.'
              }
            />
          </Grid>
          <Grid item md={5} style={{}}>
            <MoveAbilityCalculatorCard
              data={dealData}
              cashUponSaleAmount={dealData.cashUponSaleOver6Months}
              purchasePrice={dealData.purchasePrice}
              elements={elementsOver6Months}
              title={'Sell home and move after 6 months'}
              copy={
                'These are the estimates of the amounts the customer will pay and receive when they direct  EasyKnock to sell the house on the market after 6 months but before 12.'
              }
            />
          </Grid>
        </>
      ) : (
        <>
          {isSellStayV6AndAbove(dealData) && (
            <Grid item md={5}>
              <CalculatorCard
                boldTitle
                data={repurchasePriceSectionFields(dealData)}
                formatResult={false}
              />
            </Grid>
          )}
          <Grid item md={5} style={{}}>
            <CalculatorCard
              boldTitle
              data={moveSectionFields(dealData)}
              formatResult={!isSellStayV6AndAbove(dealData) && isVRevamp(dealData) ? true : false}
            />
          </Grid>
        </>
      )}
    </>
  );
};

const EstimateView = ({ dealData }) => {
  return (
    <>
      <Grid item md={5}>
        <CalculatorCard boldTitle data={cashFundingSectionFields(dealData)} />
      </Grid>
      <GridV12AndAbove dealData={dealData} />
    </>
  );
};

export const OptionsContent = ({ alignment, dealData, userData }) => {
  const { defaultWithPrimary } = useDealContext();
  let data = dealData;
  if (userData?.role === UserRole.Underwriting && !dealData?.snapshotName) {
    data = defaultWithPrimary;
  }

  return alignment === 'estimate' ? (
    <EstimateView dealData={data} />
  ) : (
    <FutureOptionsView dealData={data} />
  );
};

const DealCalculator = ({}) => {
  const { dealId, snapshotId } = useParams();

  const [dealData, setDealData] = useState(null);
  const [parentDeal, setParent] = useState(null);

  const dealToLoad = snapshotId || dealId;

  const getDealData = async () => {
    try {
      const deal = await apiClient.deal.get(dealToLoad);
      const parent = deal.snapshots.slice(-1)[0] || {};
      setDealData(deal);
      setParent(parent);
    } catch (e) {
      console.error('error" ', e);
      window.location.replace('/404');
    }
  };
  useEffect(async () => {
    getDealData();
  }, []);

  // if not dealData & parent return nothing
  if (!dealData || !parentDeal) return <></>;

  // if dealData & parentDeal are the same, redirect to the default deal
  if (dealData.id === parentDeal.dealId) {
    return <Redirect to={`/deal-calculator-revamp/${dealData.id}`} />;
  }

  // if dealData & parentDeal are not the same, redirect to the snapshot
  if (dealData.id !== parentDeal.dealId) {
    return <Redirect to={`/deal-calculator-revamp/${parentDeal.dealId}/snapshot/${dealData.id}`} />;
  }

  // else return nothing
  return <></>;
};

const GridV12AndAbove = ({ dealData }) => {
  return (
    <Grid item md={5}>
      <CalculatorCard boldTitle data={optionValueFields(dealData)} />
      <CalculatorCard boldTitle data={monthlyNetRentFields(dealData)} />
    </Grid>
  );
};

export default DealCalculator;
