import React from 'react';
import accounting from 'accounting';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import InfoAdornment from './components/InfoAdornment';
import warnIcon from '../../../images/warningIcon.svg';

const WarningIcon = () => (
  <InputAdornment position="end">
    <img alt="info" src={warnIcon} height="18px" width="18px" />
  </InputAdornment>
);

const TextFieldWrapped = ({
  disabled = false,
  fullWidth,
  hasWarn,
  hasWarningIcon = true,
  input: { onBlur, ...inputProps },
  label,
  max,
  meta: { touched, invalid, error },
  min,
  tooltip,
  errorFormula,
  ...custom
}) => (
  <TextField
    InputProps={{
      endAdornment: tooltip
        ? InfoAdornment({ Tooltip: tooltip })
        : hasWarningIcon && hasWarn
        ? WarningIcon()
        : undefined,
      style: { ...(custom ? custom.style : {}) },
    }}
    fullWidth={fullWidth}
    size="small"
    label={label}
    placeholder={label}
    error={invalid || (errorFormula && !!errorFormula(inputProps.value))}
    onBlur={min ? () => onBlur(Math.max(min, accounting.unformat(inputProps.value))) : onBlur}
    helperText={errorFormula && errorFormula(inputProps.value)}
    variant="outlined"
    {...inputProps}
    {...custom}
    disabled={disabled}
  />
);

export default TextFieldWrapped;
