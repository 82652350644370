import React, { Fragment } from 'react';
import Divider from '@material-ui/core/Divider';
import {
  Panel,
  TextBodyCopySmall,
  TextBodyCopyLarge,
  Colors,
  Icon,
} from '@easyknockorg/ek-ui-components';
import {
  MainContainer,
  CardHeader,
  ValuesContainer,
  MainCategory,
  Category,
  HeaderTitle,
  Deductions,
} from './styles';
import { formatPrice } from '../../../../utils';
import { nameFormatter } from '../../../../utils/helpers';

const CalculatorCardWithSidebar = ({ data, sidebar, datatestid }) => (
  <Panel.Sidebar price={sidebar.amount} title={sidebar.title} width={40}>
    {data.map((sectionData, idx) =>
      sectionData.details ? (
        <div data-test-id={`${datatestid}-${nameFormatter(sectionData.title)}`}>
          <Panel.Section
            key={idx}
            price={sectionData.amount}
            priceAdornment={sectionData.icon}
            title={sectionData.title}
            titleAdornment={sectionData.titleAdornment}
          >
            {(sectionData.details || []).map((sectionDetail, key) => (
              <div data-test-id={`${datatestid}-${nameFormatter(sectionDetail.title)}`}>
                <Panel.Detail
                  key={key}
                  price={sectionDetail.amount}
                  priceAdornment={sectionDetail.icon}
                  title={sectionDetail.title}
                />
              </div>
            ))}
          </Panel.Section>
        </div>
      ) : (
        <div data-test-id={`${datatestid}-${nameFormatter(sectionData.title)}`}>
          <Panel.Section
            key={idx}
            price={sectionData.amount}
            priceAdornment={sectionData.icon}
            title={sectionData.title}
            summary={sectionData.summary}
            titleAdornment={sectionData.titleAdornment}
          />
        </div>
      ),
    )}
  </Panel.Sidebar>
);

const CalculatorCard = ({ data, datatestid }) =>
  data.map((sectionData, idx) => {
    const isLast = idx === data.length - 1;
    return sectionData.details ? (
      <Fragment key={idx}>
        <div data-test-id={`${datatestid}-${nameFormatter(sectionData.title)}`}>
          <Panel.Section
            price={sectionData.amount}
            priceAdornment={sectionData.icon}
            subtext={sectionData.message}
            title={sectionData.title}
            titleAdornment={sectionData.titleAdornment}
          >
            {(sectionData.details || []).map((sectionDetail, key) => (
              <div data-test-id={`${datatestid}-${nameFormatter(sectionDetail.title)}`}>
                <Panel.Detail
                  key={key}
                  price={sectionDetail.amount}
                  priceAdornment={sectionDetail.icon}
                  title={sectionDetail.title}
                />
              </div>
            ))}
          </Panel.Section>
        </div>
        {!isLast && <Divider />}
      </Fragment>
    ) : (
      <Fragment key={idx}>
        <div data-test-id={`${datatestid}-${nameFormatter(sectionData.title)}`}>
          <Panel.Section
            price={sectionData.amount}
            priceAdornment={sectionData.icon}
            subtext={sectionData.message}
            title={sectionData.title}
            titleAdornment={sectionData.titleAdornment}
          />
        </div>
        {!isLast && <Divider />}
      </Fragment>
    );
  });

export default ({
  boldTitle = false,
  customHead = false,
  data = [],
  formatResult = true,
  sidebar,
}) => {
  const head = data.shift();
  const tail = formatResult ? data.pop() : {};
  return (
    <Panel outline width={null} style={{ boxShadow: '0' }}>
      <div data-test-id={`${nameFormatter(head.title)}`}>
        <Panel.Section
          head={customHead}
          price={head.amount}
          subtext={head.message}
          title={head.title}
          boldTitle={boldTitle}
          titleAdornment={head.titleAdornment}
        />
      </div>
      {sidebar ? (
        <CalculatorCardWithSidebar
          data={data}
          sidebar={sidebar}
          datatestid={`${nameFormatter(head.title)}`}
        />
      ) : (
        <CalculatorCard data={data} datatestid={`${nameFormatter(head.title)}`} />
      )}
      {formatResult && (
        <div data-test-id={`${nameFormatter(tail.title)}`}>
          <Panel.Section
            price={tail.amount}
            priceAdornment={tail.icon}
            title={tail.title}
            titleAdornment={tail.titleAdornment}
            summary={tail.summary}
          />
        </div>
      )}
    </Panel>
  );
};

export const MoveAbilityCalculatorCard = ({
  data,
  elements,
  title,
  copy,
  cashUponSaleAmount,
  purchasePrice,
}) => (
  <MainContainer>
    <CardHeader>
      <HeaderTitle>{title}</HeaderTitle>
      <TextBodyCopySmall style={{ margin: 0 }}>{copy}</TextBodyCopySmall>
    </CardHeader>
    <ValuesContainer>
      <MainCategory>
        <TextBodyCopyLarge>Cash Upon Sale</TextBodyCopyLarge>
        <TextBodyCopyLarge weight="700">= {formatPrice(cashUponSaleAmount)}</TextBodyCopyLarge>
      </MainCategory>
      <Category>
        <TextBodyCopySmall>Estimated Home Sale Price</TextBodyCopySmall>
        <TextBodyCopySmall>{formatPrice(purchasePrice || 0)}</TextBodyCopySmall>
      </Category>
      {elements.map((element, idx) => (
        <Category key={idx}>
          <TextBodyCopySmall style={{ maxWidth: '240px' }}>{element.title}</TextBodyCopySmall>
          <Deductions>
            <Icon icon="minus" color={Colors.BGW.darkGrey} style={{ alignSelf: 'center' }} />
            <TextBodyCopySmall>{formatPrice(element.amount)}</TextBodyCopySmall>
          </Deductions>
        </Category>
      ))}
    </ValuesContainer>
  </MainContainer>
);
