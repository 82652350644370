import _ from 'lodash';
import diff from 'shallow-diff';
import apiClient from '../../utils/apiClient';
import {
  formSelector,
  BANNER_ACTION,
  BIND_FIELD_TO_FORMULA,
  CLONING,
  ESTIMATE_EMAIL_SENT,
  LOAD_DOCUMENT_DATA,
  LOAD_ESTIMATE_EVENT_DATA,
  LOAD_SALESFORCE_REFETCH,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
} from './constants';

export const localSessionLogout = () => ({
  type: LOGOUT,
});

export const loadDocument = (payload) => ({
  type: LOAD_DOCUMENT_DATA,
  payload,
});
export const loadEstimate = (payload) => ({
  type: LOAD_ESTIMATE_EVENT_DATA,
  payload,
});
export const loadSalesforceRefetch = (payload) => ({
  type: LOAD_SALESFORCE_REFETCH,
  payload,
});
export const bindFieldToFomula = (field) => ({
  type: BIND_FIELD_TO_FORMULA,
  payload: field,
});

export const loginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});
export const loginFailed = (payload) => ({
  type: LOGIN_FAILED,
  payload,
});

export const markEstimateEmailAsSent = (payload) => ({
  type: ESTIMATE_EMAIL_SENT,
  payload,
});

export const setCloning = (payload) => ({
  type: CLONING,
  payload,
});

let lastSavedDeal;
export const saveDealData = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const deal = formSelector(state);
    if (
      //      !state.form[DEAL_FORM_NAME].active &&
      deal &&
      !_.isEmpty(deal) &&
      deal.id &&
      !deal.snapshotName &&
      deal.cashFunding
    ) {
      lastSavedDeal = lastSavedDeal || deal;
      const dealDiff = diff(lastSavedDeal, deal);
      if (dealDiff.updated.length || dealDiff.added.length) {
        await apiClient.deal.put(deal.id, deal);
        lastSavedDeal = { ...deal };
      }
    }
  } catch (e) {
    console.log(e);
  }
};
export const bannerAction = (payload) => ({ type: BANNER_ACTION, payload });
